import { FormikActions } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import { ISponsorItem } from 'stores/sponsors'
import { IFilterConfig } from 'utils/filter'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'
import './Sponsors.css'

import EditSponsorForm from 'components/edit-sponsor-form/EditSponsorForm'

const newSponsor = {
  company: '',
  contactPerson: {
    email: '',
    phone: '',
  },
  location: '',
  logoUrl: '',
  metadata: {
    companyAbout: '',
    customCode: '',
    keywords: '',
  },
  primarySponsorTag: '',
  primarySponsorTier: '',
  sponsorTiersIds: [],
  sponsorTypesIds: [],
  tags: [],
} as Partial<FormikActions<ISponsorItem>>

interface ISponsorsProps {
  currentSponsor: ISponsorItem
  fetchAllSponsorsTypes: () => Promise<any>
  fetchSponsor: (filter: IFilterConfig) => Promise<any>
  fetchSponsorsStatus: LoadingStatus
  loading: LoadingStatus
  resetCurrentSponsor: () => any
  fetchAllContent: (id: string) => any
}

export const Sponsor = ({
  currentSponsor,
  fetchAllSponsorsTypes,
  fetchSponsor,
  fetchSponsorsStatus,
  loading,
  resetCurrentSponsor,
  fetchAllContent,
}: ISponsorsProps) => {
  const { slug } = useParams()

  const createMode = slug === 'create'

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(currentSponsor),
      ...(createMode && _.cloneDeep(newSponsor)),
    }),
    [createMode, currentSponsor]
  )

  const loadSponsor = () =>
    fetchSponsor({
      filter: {
        Id: {
          operator: 'Equals',
          title: 'Sponsor',
          value: slug as string,
        },
      },
      itemsOnPage: 1,
      page: 0,
    })

  useEffect(() => {
    if (createMode) {
      resetCurrentSponsor()
    } else {
      loadSponsor()
      fetchAllContent(slug)
    }
    // eslint-disable-next-line
  }, [])

  if (createMode || Object.keys(initialValues).length) {
    return (
      <EditSponsorForm item={initialValues} createMode={createMode} fetchSponsor={loadSponsor} />
    )
  }

  return null
}

export default observer(() => {
  const {
    fetchSponsor,
    fetchSponsorsStatus,
    fetchAllSponsorsTypes,
    currentSponsor,
    resetCurrentSponsor,
    fetchAllContent,
  } = useRootData(store => store.sponsorsStore)
  const loading = combineLoadingStatus([fetchSponsorsStatus])
  return (
    <Sponsor
      {...{
        currentSponsor,
        fetchAllSponsorsTypes,
        fetchSponsor,
        fetchSponsorsStatus,
        loading,
        resetCurrentSponsor,
        fetchAllContent,
      }}
    />
  )
})
