import SelectFileModal from 'components/select-file-modal/SelectFileModal'
import SelectFilePreview from 'components/select-file-preview/SelectFilePreview'
import { FastField, FieldProps } from 'formik'
import React, { memo, useCallback, useState } from 'react'

import './SelectFileInput.css'

interface ISelectFileInputPublicProps {
  name: string
  title?: string
  width?: number
  height?: number
  onChange?: (file: string) => any
  fluid?: boolean
}

type ISelectFileInputProps = FieldProps & ISelectFileInputPublicProps

export const SelectFileInput = ({
  field,
  form,
  title,
  onChange,
  width = 200,
  height = 200,
  fluid,
}: ISelectFileInputProps) => {
  const [showFiles, setShowFiles] = useState(false)

  const hideFilesPopup = useCallback(() => {
    field.onBlur(field.name)
    setShowFiles(false)
  }, [field])

  const onFileSelect = useCallback(
    (file: string) => {
      form.setFieldValue(field.name, file)
      if (onChange) {
        onChange(file)
      }
      hideFilesPopup()
    },
    [field, onChange, form, hideFilesPopup]
  )

  const onShowClick = useCallback(() => setShowFiles(true), [setShowFiles])
  const onHideClick = useCallback(() => setShowFiles(false), [setShowFiles])

  const onResetClick = useCallback(() => onFileSelect(''), [onFileSelect])

  return (
    <div className="field select-file-input">
      <label>{title}</label>
      <SelectFilePreview
        fluid={fluid}
        width={width}
        height={height}
        file={field.value}
        onShowClick={onShowClick}
        onResetClick={onResetClick}
      />

      <SelectFileModal
        width={width}
        height={height}
        show={showFiles}
        onHideClick={onHideClick}
        onFileSelect={onFileSelect}
      />
    </div>
  )
}

export const FormikSelectFileInput = (props: ISelectFileInputPublicProps) => (
  <FastField component={SelectFileInput} {...props} />
)

export default memo(FormikSelectFileInput)
