import React, { memo, ReactElement, useRef } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

interface IPageLoadingProps {
  loading?: boolean
  children: ReactElement
}

export const PageLoading = memo(({ children, loading = true }: IPageLoadingProps) => {
  const wrapper = useRef<HTMLDivElement>(null)
  const lastHeight = useRef<number>(100)

  const node = wrapper.current
  if (node && lastHeight.current) {
    lastHeight.current = node.clientHeight
  }

  if (loading) {
    return (
      <Dimmer style={{ position: 'fixed', zIndex: 2000 }} active inverted>
        <Loader size="large" inverted>
          Loading
        </Loader>
      </Dimmer>
    )
  }
  return <div ref={wrapper}>{children}</div>
})

export default PageLoading
