import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import { IRegistrationItem } from 'stores/registrations'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

import EditAttendeeForm from 'components/edit-attendee-form/EditAttendeeForm'

interface IAttendeeProps {
  fetchRegistration: (filter: IFilterConfig) => Promise<any>
  currentAttendee: IRegistrationItem
  fetchAttendeesStatus: LoadingStatus
  resetCurrentAttendee: () => any
}

export const Registration = ({
  fetchRegistration,
  fetchAttendeesStatus,
  currentAttendee,
}: IAttendeeProps) => {
  const { slug } = useParams()
  const filters = {
    filter: {
      Id: {
        operator: 'Equals',
        title: 'Registration',
        value: slug as string,
      },
    },
    itemsOnPage: 1,
    page: 0,
  } as IFilterConfig

  const fetchItem = () => fetchRegistration(filters)

  useEffect(() => {
    fetchItem()
    // eslint-disable-next-line
  }, [slug])

  if (currentAttendee) {
    return <EditAttendeeForm item={currentAttendee} fetchItem={fetchItem} />
  }

  return null
}

export default observer(() => {
  const {
    fetchRegistration,
    fetchAttendeesStatus,
    currentAttendee,
    resetCurrentAttendee,
  } = useRootData(store => store.registrationsStore)
  return (
    <Registration
      {...{
        currentAttendee,
        fetchAttendeesStatus,
        fetchRegistration,
        resetCurrentAttendee,
      }}
    />
  )
})
