import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { CSVReader } from 'react-papaparse'
import { Button, Header, Message, Modal, Table } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IImportSponsorItem } from 'stores/sponsors'
import { LoadingStatus } from 'utils/store'

const fileTemplate = `"Company","Link","Country","Description","Types ( delimiter ';' )","Tiers ( delimiter ';' )","Tags ( delimiter ';' )","PrimaryTag ( single )","PrimaryTag ( single )","PrimaryTier ( single )","PrimaryTier ( single )","Name","Email","Phone","Position","TicketsCount", "ChatStream"`

interface IImportProps {
  fetchPage: () => Promise<any>
  importSponsors: (payload: IImportSponsorItem[]) => Promise<any>
  importSponsorsStatus: LoadingStatus
  importErrors: any
  resetImportErrors: () => any
}

const config = {
  header: true,
  transform: (data: any) => {
    const delimiter = ';'
    return data.includes(delimiter) ? data.split(delimiter) : data
  },
  transformHeader: (keys: any) => {
    const re = /^.*(?=\(.*\))/gim
    return re.test(keys) ? keys.match(re)[0].trim() : keys
  },
}

export const ImportFromFile = ({
  fetchPage,
  importSponsors,
  importSponsorsStatus,
  importErrors,
  resetImportErrors,
}: IImportProps) => {
  const [showModal, setShowModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [parsedValues, setParsedValues] = useState<any>([])

  const withErrors = importErrors && importErrors.length > 0

  const submitDisabled =
    withErrors || !parsedValues.length || importSponsorsStatus === LoadingStatus.pending

  const handleSubmit = useCallback(() => {
    importSponsors(parsedValues)
  }, [parsedValues, importSponsors])

  useEffect(() => {
    if (withErrors !== undefined) {
      setShowErrors(true)
    } else {
      if (importSponsorsStatus === LoadingStatus.success) {
        fetchPage().then(() => closeModal())
      }
    }
  }, [withErrors, importSponsorsStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDrop = (data: any) => {
    setParsedValues(data.map((entry: any) => entry.data))
  }

  const handleRemoveFile = () => {
    setParsedValues([])
    setShowErrors(false)
  }

  const closeModal = async () => {
    handleRemoveFile()
    resetImportErrors()
    setShowModal(false)
  }

  const handleDownload = () => {
    const element = document.createElement('a')
    const file = new Blob([fileTemplate], { type: 'text/csv' })
    element.href = URL.createObjectURL(file)
    element.download = 'import-sponsors-to-backoffice.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  return (
    <Modal
      centered={false}
      dimmer="inverted"
      size="tiny"
      trigger={
        <Button basic onClick={() => setShowModal(true)}>
          Import...
        </Button>
      }
      open={showModal}
      onClose={closeModal}
      closeOnDimmerClick={true}
    >
      <Modal.Header>Import Sponsors</Modal.Header>
      <Modal.Content>
        <Message size="small">
          <Message.Content>
            <p style={{ textAlign: 'center' }}>
              <Button size="tiny" onClick={handleDownload} content="Download CSV template" />
            </p>
          </Message.Content>
        </Message>
        <CSVReader
          onDrop={handleDrop}
          addRemoveButton
          onRemoveFile={handleRemoveFile}
          config={config}
        >
          <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
        {showErrors && importErrors ? (
          <Table compact basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header as="h5">Errors found:</Header>
                </Table.Cell>
              </Table.Row>
              {importErrors.map((message: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{message.Message}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={closeModal} content="Cancel" />
        <Button
          type="submit"
          width={3}
          primary
          onClick={() => handleSubmit()}
          content="Import"
          disabled={submitDisabled}
          loading={importSponsorsStatus === LoadingStatus.pending}
        />
      </Modal.Actions>
    </Modal>
  )
}

interface IImportPropsInjected {
  fetchPage: () => Promise<any>
}

export const ImportFromFileInjected = observer((props: IImportPropsInjected) => {
  const { importSponsors, importSponsorsStatus, importErrors, resetImportErrors } = useRootData(
    store => store.sponsorsStore
  )
  return (
    <ImportFromFile
      {...props}
      {...{
        importErrors,
        importSponsors,
        importSponsorsStatus,
        resetImportErrors,
      }}
    />
  )
})

export default ImportFromFileInjected
