import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { useRootData } from 'stores'
import { IExhibitorItem } from 'stores/exhibitor'
import { LoadingStatus } from 'utils/store'
import '../sponsors/Sponsors.css'

import EditExhibitorForm from 'components/edit-exhibitor-form/EditExhibitorForm'

interface IExhibitorProps {
  currentExhibitor: IExhibitorItem
  fetchExhibitor: () => Promise<any>
  fetchExhibitorContent: () => any
  fetchExhibitorStatus: LoadingStatus
}

const Exhibitor = ({
  currentExhibitor,
  fetchExhibitor,
  fetchExhibitorStatus,
  fetchExhibitorContent,
}: IExhibitorProps) => {
  useEffect(() => {
    fetchExhibitor()
    fetchExhibitorContent()
    // eslint-disable-next-line
  }, [])

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(currentExhibitor),
    }),
    [currentExhibitor]
  )

  if (Object.keys(initialValues).length) {
    return <EditExhibitorForm item={initialValues} fetchExhibitor={fetchExhibitor} />
  }

  return null
}

export default observer(() => {
  const {
    currentExhibitor,
    fetchExhibitor,
    fetchExhibitorStatus,
    saveExhibitor,
  } = useRootData(store => store.exhibitorStore)
  const { fetchExhibitorContent } = useRootData(
    store => store.sponsorsStore
  )
  return (
    <Exhibitor
      {...{
        currentExhibitor,
        fetchExhibitor,
        fetchExhibitorContent,
        fetchExhibitorStatus,
        saveExhibitor,
      }}
    />
  )
})
