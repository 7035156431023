import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Cell, Pie, PieChart, PieLabelRenderProps, Tooltip, TooltipProps } from 'recharts'
import { Grid, Label, Message } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IReportItem } from 'stores/home'
import { LoadingStatus } from 'utils/store'

import './Home.css'

interface IHomeProps {
  loading: LoadingStatus
  report: IReportItem[]
  fetchReport: () => Promise<any>
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload) {
    const { name, value } = payload[0]
    return (
      <Label>
        {value} - {name}
      </Label>
    )
  }

  return null
}

const CustomizedLabel = ({ x, y, value, percent, fill }: PieLabelRenderProps) => {
  if (value && percent && percent > 0.02) {
    return (
      <text x={x} y={y} fill={fill}>
        {value}
      </text>
    )
  }

  return null
}

interface ICustomChartProps {
  dataArray: IReportItem[]
  field: string
  title: string
  summ: number
}
const CustomChart = ({ dataArray, field, title, summ }: ICustomChartProps) => (
  <div className="chart-wrapper">
    <span className="total-label">
      {title}: {summ}
    </span>
    <PieChart className="report-chart" width={280} height={260}>
      <Pie
        data={dataArray}
        dataKey={field}
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={70}
        outerRadius={100}
        animationDuration={300}
        labelLine={false}
        label={CustomizedLabel}
      >
        {dataArray.map(entry => (
          <Cell key={field + entry.statusName} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip content={CustomTooltip} />
    </PieChart>
  </div>
)

export const Home = ({ report, loading, fetchReport }: IHomeProps) => {
  useEffect(() => {
    fetchReport()
  }, [fetchReport])

  const summTotal = report.reduce((prev, i) => prev + i.total, 0)
  const summToday = report.reduce((prev, i) => prev + i.today, 0)
  const data = report.slice().sort((a, b) => b.total - a.total)
  const dataToday = report.slice().sort((a, b) => b.today - a.today)
  const statuses = dataToday
    .map(d => ({
      color: d.color,
      key: d.statusName,
      name: d.name,
      value: d.today / (summTotal || 1) + d.total / (summToday || 1),
    }))
    .sort((a, b) => b.value - a.value)

  return (
    <>
      <Grid divided>
        <Grid.Row columns={2} stretched>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {summTotal > 0 && (
              <CustomChart dataArray={data} field="total" title="Total" summ={summTotal} />
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {summTotal > 0 && (
              <CustomChart dataArray={data} field="today" title="Today" summ={summToday} />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column textAlign="center">
            {summToday + summTotal > 0 && (
              <Label.Group circular className="charts-legend">
                {statuses.map(s => (
                  <Label style={{ background: s.color }} key={s.key}>
                    {s.name}
                  </Label>
                ))}
              </Label.Group>
            )}
            {summToday + summTotal < 1 && (
              <Message>
                <Message.Header>Nothing to show!</Message.Header>
                <p>No registrations in DB</p>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default observer(() => {
  const { fetchReport, loading, report } = useRootData(store => store.homeStore)
  return (
    <Home
      {...{
        fetchReport,
        loading,
        report,
      }}
    />
  )
})
