import { SelectInput, TextInput, TextRow } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Divider, Form, Grid, Icon, Label, List } from 'semantic-ui-react'
import { IAttendeeType, IRegistrationItem, RegistrationStatuses } from 'stores/registrations'
import { SITE } from 'utils/config'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'

interface IEditFormValues extends IRegistrationItem {
  selectedPackageId: string | null
  messagesLimit: number
}

interface IEditPersonalProps {
  actionStatus: LoadingStatus
  item: IEditFormValues
  attendeeTypes: IAttendeeType[]
  onSubmit: (payload: any) => any
}

export const EditAttendeeAdditional = ({
  item,
  onSubmit,
  attendeeTypes,
  actionStatus,
}: IEditPersonalProps) => {
  const { isMobile } = useWindowDimensions()

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const attendeeTypesOptions = useMemo(
    () =>
      attendeeTypes.map(e => ({
        key: e.id,
        text: e.title,
        value: e.id,
      })),
    [attendeeTypes]
  )

  const handleSubmit = async (
    values: IEditFormValues,
    { resetForm }: Partial<FormikActions<IEditFormValues>>
  ) => {
    await onSubmit(values)
    if (resetForm) {
      resetForm(values)
    }
  }

  const handleReset = async (
    values: IEditFormValues,
    { resetForm }: Partial<FormikActions<IEditFormValues>>
  ) => {
    if (resetForm) {
      resetForm(values)
    }
  }

  const renderCopyLink = (link: string, title: string) => (
    <CopyToClipboard text={SITE + link}>
      <List.Item>
        <Label horizontal>
          <Icon name="copy" />
          Copy
        </Label>
        {title}
      </List.Item>
    </CopyToClipboard>
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
      render={props => {
        const { values, touched, resetForm } = props
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Status" value={values.registrationStatus} />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Unique id" value={values.id} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Creation date" value={values.registrationDate} />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="IP adress" value={values.registrationIp} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Payment type" value={values.paymentType} />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Payment provider" value={values.paymentProvider} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Payment status" value={values.paymentStatus} />
                  <TextRow
                    title="Confirmation email sent"
                    value={
                      values.registrationStatus === RegistrationStatuses.PendingConfirmation ||
                      values.registrationStatus === RegistrationStatuses.Completed
                        ? 'Yes'
                        : 'No'
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Tickets count" value={values.ticketsCount} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Messages used" value={values.messagesUsed} />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Promo code" value={_.get(values, 'package.promoCode.code')} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow
                    title="Discount"
                    value={
                      _.get(values, 'vipInvitation.discountInCurrency') ||
                      _.get(values, 'vipInvitation.discountInPercent')
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Step 1 vote" value={values.isStepOneVoted ? 'Yes' : 'No'} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextRow title="Step 2 vote" value={values.isStepTwoVoted ? 'Yes' : 'No'} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Service</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput title="Comments" name="comments" />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput type="number" min="0" title="Booth number" name="boothNumber" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <SelectInput
                    search
                    name="attendeeTypeId"
                    title="Attendee type"
                    options={attendeeTypesOptions}
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput title="CID" name="cid" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List>
                    {values.featureFormToken &&
                      renderCopyLink(
                        `/registration/featured-attendee-registration?token=${values.featureFormToken.token}`,
                        'Featured registration link'
                      )}
                    {values.vipInvitation &&
                      renderCopyLink(
                        `/registration/${values.vipInvitation.slug}/${values.vipInvitation.code}`,
                        'VIP attendee registration link'
                      )}
                    {values.confirmationToken &&
                      renderCopyLink(
                        `/registration/completed?token=${values.confirmationToken}`,
                        'Confirm registration link'
                      )}
                    {values.voucherToken &&
                      renderCopyLink(`/voucher?token=${values.voucherToken}`, 'Admission link')}
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => handleReset(initialValues, { resetForm })}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditAttendeeAdditional
