import React, { useRef, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import StoreProvider from 'stores/StoreProvider'
import App from './app'
import { RouteComponentProps, withRouter } from 'react-router'
import { observer } from 'mobx-react-lite'

// import Speakers from 'pages/speakers/Speakers'
import Blacklist from 'pages/blacklist/Blacklist'
import BlockedIps from 'pages/blocked-ips/BlockedIps'
import BoothPreview from 'pages/booth-preview/BoothPreview'
import ForbiddenEmails from 'pages/forbidden-emails/ForbiddenEmails'
import Home from 'pages/home/Home'
import Notifications from 'pages/notifications/Notifications'
import Registrations from 'pages/registrations/'
import Sponsors from 'pages/sponsors/'
import SponsorsSettings from 'pages/sponsors/Settings'

import Exhibitor from 'pages/exhibitor/Exhibitor'
import Statistics from 'pages/exhibitor/Statistics'

import { REACT_APP_ROLE } from 'utils/config'
import { useRootData } from 'stores'
import CompleteSaml from 'pages/complete-saml/CompleteSaml'

const ErrorPage = () => (
  <>
    <h1>404</h1>
    <h2>Page not found</h2>
  </>
)

export const regularRoutes = {
  home: {
    Component: Home,
    name: 'Home',
    path: '/',
    url: '/',
  },
  sponsorsList: {
    Component: Sponsors,
    name: 'List',
    path: '/sponsors/list/:slug?',
    url: '/sponsors/list/',
  },
  sponsorsSettings: {
    Component: SponsorsSettings,
    name: 'Settings',
    path: '/sponsors/settings/',
    url: '/sponsors/settings/',
  },
  booth: {
    Component: BoothPreview,
    name: null,
    path: '/booth/:guid',
    url: '/booth/',
  },
  // speakers: {
  //   Component: Speakers,
  //   name: 'Speakers',
  //   path: '/speakers/:slug?',
  //   url: '/speakers/',
  // },
  registrations: {
    Component: Registrations,
    name: 'Registrations',
    path: '/registrations/:slug?',
    url: '/registrations/',
  },
  notifications: {
    Component: Notifications,
    name: 'Notifications',
    path: '/notifications/:slug?',
    url: '/notifications/',
  },
  blacklists: {
    Component: Blacklist,
    name: 'Blacklist',
    path: '/blacklist/:slug?',
    url: '/blacklist/',
  },
  blockedips: {
    Component: BlockedIps,
    name: 'Blocked IPs',
    path: '/blocked-ips/:slug?',
    url: '/blocked-ips/',
  },
  forbiddenemails: {
    Component: ForbiddenEmails,
    name: 'Forbidden Emails',
    path: '/forbidden-emails/:slug?',
    url: '/forbidden-emails/',
  },
  error: {
    Component: ErrorPage,
    name: null,
    path: '*',
    url: null,
  },
}

export const exhibitorRoutes = {
  home: {
    Component: Exhibitor,
    name: 'Details',
    path: '/',
    url: '/',
  },
  booth: {
    Component: Exhibitor,
    name: 'Booth',
    path: '/booth',
    url: '/booth',
  },
  statistics: {
    Component: Statistics,
    name: 'Statistics',
    path: '/statistics',
    url: '/statistics',
  },
  chat: {
    name: 'Chat',
    path: '/',
    url: 'https://chat.nextv.company/accounts/login/social/saml/azuread',
  },
  leads: {
    name: 'Leads',
    path: '/',
    url: 'https://nextvdemo.pipedrive.com/sso/login',
  },
  error: {
    Component: ErrorPage,
    name: null,
    path: '*',
    url: null,
  },
}

const RouteChangeHandler = withRouter(
  observer(({ location: { pathname } }: RouteComponentProps) => {
    const { hideSideMenu, setActiveRoute } = useRootData(store => store.appStore)
    const lastRoute = useRef(pathname)

    useEffect(() => {
      setActiveRoute(pathname)
      if (lastRoute.current !== pathname) {
        hideSideMenu()
      }
      lastRoute.current = pathname
    }, [pathname, hideSideMenu, setActiveRoute])

    return null
  })
)

const Routes = () => (
  <Router>
    <RouteChangeHandler />
    <App>
      <Switch>
        {Object.values(REACT_APP_ROLE === 'regular' ? regularRoutes : exhibitorRoutes).map(
          ({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => <Component />}
            </Route>
          )
        )}
      </Switch>
    </App>
  </Router>
)

const RouterWithSaml = () => (
  <StoreProvider>
    <Router>
      <Switch>
        <Route component={CompleteSaml} path="/complete/saml" exact />
        <Route component={Routes} path="*" />
      </Switch>
    </Router>
  </StoreProvider>
)

export default RouterWithSaml
