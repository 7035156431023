export function mergeArrays<T1 extends { [key: string]: any }, T2 extends { [key: string]: any }>(
  prop: string,
  a: T1[],
  b: T2[]
) {
  type T = T1 | T2
  const merged: { [key: string]: T1 & T2 } = {}
  ;[a, b].forEach((array: T[]) => {
    array.forEach((item: T) => {
      merged[item[prop]] = { ...merged[item[prop]], ...item }
    })
  })
  return Object.values(merged)
}

