import {
  IAbstractionsContractsQueriesReportTodayRegistrationReportResponce,
  IAbstractionsContractsQueriesReportTodayRegistrationReportResponceRegistrationsReport,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'
import { registrationStatusColors, RegistrationStatuses } from './registrations'

export type IReportItemFromApi = IAbstractionsContractsQueriesReportTodayRegistrationReportResponceRegistrationsReport

export interface IReportItem extends IReportItemFromApi {
  color: string
  name: string
  statusName: RegistrationStatuses
}

export default types
  .model('HomeStore', {
    loading: LoadingStatusType,
    report: types.frozen<IReportItem[]>([]),
  })
  .actions(self => ({
    fetchReport: createAxiosAction(
      flow(function*() {
        // eslint-disable-next-line
        const [{ data }, ...rest] = (yield Promise.all([
          axios.get('/backoffice/registration/report'),
          getParent<IRootStore>(self).registrationsStore.fetchRegistrationStatusNames(),
        ])) as [{ data: IAbstractionsContractsQueriesReportTodayRegistrationReportResponce }, any]

        const registrationsStore = getParent<IRootStore>(self).registrationsStore

        self.report = data.registrationsReportList.map(
          (el: IReportItemFromApi) =>
            ({
              ...el,
              color: registrationStatusColors[el.statusName as RegistrationStatuses],
              name: registrationsStore.getRegistrationStatusName(el.statusName),
            } as IReportItem)
        )
      }),
      s => (self.loading = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch report')
    ),
  }))
