import axios, { AxiosRequestConfig } from 'axios'
import { getAccessToken, removeAccessToken, removeRefreshToken } from './auth'
import { API, DEVICE_ID, TOKEN_REFRESH_PATH } from './config'

const instance = axios.create({
  baseURL: `${API}/api`,
  headers: {
    'Accept-Language': 'en-US',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    'Content-type': 'application/json',
  },
})

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.headers.DeviceId = DEVICE_ID
  const w = window as any

  if (w.currentRefreshTokenRequest && config.url !== TOKEN_REFRESH_PATH) {
    await w.currentRefreshTokenRequest
  }
  const token = getAccessToken()

  if (token) {
    config.headers.Authorization = token
  }
  return config
})

instance.interceptors.response.use(
  r => r,
  error => {
    if (error.response && error.response.status === 401) {
      removeAccessToken()
      removeRefreshToken()
    }
    return Promise.reject(error)
  }
)

export default instance
