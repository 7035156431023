import { TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Confirm, Form, Header, Modal, Table } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ISponsorType, IUpdateSponsorTypeItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'

const initialValues = {
  fontColor: '#000',
  fontFamily: 'Arial',
  fontSize: 14,
  fontWeight: 800,
  id: '',
  imageEffect: 'None',
  isShowLinks: false,
  title: '',
} as ISponsorType

interface IEditSponsorTypes {
  fetchSponsorsTypes: () => Promise<any>
  fetchTypesStatus: LoadingStatus
  sponsorTypes: ISponsorType[]
  saveSponsorType: (payload: IUpdateSponsorTypeItem, onDone: () => any) => Promise<any>
  deleteSponsorType: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
}

export const EditSponsorTypes = ({
  fetchSponsorsTypes,
  fetchTypesStatus,
  sponsorTypes,
  saveSponsorType,
  deleteSponsorType,
}: IEditSponsorTypes) => {
  const [entryToEdit, setEntryToEdit] = useState<ISponsorType>()
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    fetchSponsorsTypes()
    // eslint-disable-next-line
  }, [])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const toggleModal = async (entry?: ISponsorType) => {
    await setEntryToEdit(entry)
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal()
  }

  const handleDelete = async (entry: any) => {
    await deleteSponsorType({ id: entry.id }, () => fetchSponsorsTypes())
  }

  const handleSubmit = async (
    values: IUpdateSponsorTypeItem,
    { resetForm }: Partial<FormikActions<IUpdateSponsorTypeItem>>
  ) => {
    await saveSponsorType(values, () => fetchSponsorsTypes())
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const { isMobile } = useWindowDimensions()

  return (
    <>
      <Table size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={15} />
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sponsorTypes.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h5">{entry.title}</Header>
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => toggleModal(entry)}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={props => {
          const {
            values,
            touched,
            setFieldValue,
            setFieldTouched,
            setSubmitting,
            resetForm,
          } = props
          return (
            <Modal
              centered={false}
              dimmer="inverted"
              size="tiny"
              trigger={
                <Button primary onClick={() => toggleModal()}>
                  Add Sponsor Type
                </Button>
              }
              open={showModal}
              onClose={() => {
                Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
              }}
              closeOnDimmerClick={true}
            >
              <Modal.Header>{`${fullValues.id.length ? 'Edit' : 'Add'}`} Sponsor Type</Modal.Header>
              <Modal.Content>
                <Form as={FormikForm} autoComplete="off">
                  <TextInput title="Title" name="title" />
                  <Checkbox
                    label="Show links"
                    name="isShowLinks"
                    defaultChecked={fullValues.isShowLinks}
                    onChange={(event, { checked }) => {
                      setFieldValue('isShowLinks', checked)
                      setFieldTouched('isShowLinks')
                    }}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  fluid={isMobile}
                  type="button"
                  onClick={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                  }}
                  content="Cancel"
                />
                <Button
                  type="submit"
                  fluid={isMobile}
                  width={3}
                  primary
                  loading={fetchTypesStatus === LoadingStatus.pending}
                  onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                  content="Save"
                />
              </Modal.Actions>
            </Modal>
          )
        }}
      />

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </>
  )
}

export const EditSponsorTypesForm = observer(() => {
  const {
    fetchSponsorsTypes,
    fetchTypesStatus,
    sponsorTypes,
    saveSponsorType,
    deleteSponsorType,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditSponsorTypes
      {...{
        deleteSponsorType,
        fetchSponsorsTypes,
        fetchTypesStatus,
        saveSponsorType,
        sponsorTypes,
      }}
    />
  )
})

export default EditSponsorTypesForm
