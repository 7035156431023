import {
  IAbstractionsContractsCommandsAgendaSessionsUpdateAgendaSessionCommand,
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsResponse,
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsResponseAgendaSession,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export type ISessionItem = IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsResponseAgendaSession
export type ISaveSessionItem = IAbstractionsContractsCommandsAgendaSessionsUpdateAgendaSessionCommand

export default types
  .model('AgendaStore', {
    actionSessionStatus: LoadingStatusType,
    fetchSessionsStatus: LoadingStatusType,
    sessions: types.frozen<ISessionItem[]>([]),
  })

  .actions(self => ({
    fetchSessions: createAxiosAction(
      flow(function*(force = true) {
        if (!force && self.sessions.length > 0) {
          return
        }

        const { data } = (yield axios.get(`/backoffice/agenda/sessions?${allQuery}`)) as {
          data: IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsResponse
        }
        self.sessions = data.agendaSessions
      }),
      s => (self.fetchSessionsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sessions')
    ),
    saveSession: createAxiosAction(
      async (payload: ISaveSessionItem) => {
        await axios.put('/backoffice/agenda/sessions', payload)
      },
      s => (self.actionSessionStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to save session')
    ),
  }))
