import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, REFRESH_TOKEN_LIFETIME } from './config'

interface ITokenItem {
  token: string
  endTime: number
}

interface ITokenProps {
  header: { [key: string]: any }
  payload: { [key: string]: any }
}

export const decodeToken = (token: string) => ({
  header: JSON.parse(window.atob(token.split('.')[0])),
  payload: JSON.parse(window.atob(token.split('.')[1])),
})

export const setAccessToken = (token: string) => {
  const endTime = Date.now() + decodeToken(token).payload.lifeTimeTicks / 10000
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify({ token, endTime }))
}

export const getAccessTokenItem: () => ITokenItem | null = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  if (!token) {
    return null
  }
  const tokenItem = JSON.parse(token)
  if (Date.now() >= tokenItem.endTime) {
    removeAccessToken()
    return null
  }
  return tokenItem
}

export const getAccessToken: () => string | null = () => {
  const tokenItem = getAccessTokenItem()
  return tokenItem ? tokenItem.token : null
}

export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)

export const setRefreshToken = (token: string) => {
  const endTime = Date.now() + REFRESH_TOKEN_LIFETIME
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify({ token, endTime }))
}

export const getRefreshTokenItem: () => ITokenItem | null = () => {
  const token = localStorage.getItem(REFRESH_TOKEN_KEY)
  if (!token) {
    return null
  }
  const tokenItem = JSON.parse(token)
  if (Date.now() >= tokenItem.endTime) {
    removeRefreshToken()
    return null
  }
  return tokenItem
}

export const getRefreshToken: () => string | null = () => {
  const tokenItem = getRefreshTokenItem()
  return tokenItem ? tokenItem.token : null
}

export const getTokenScopes = () => {
  const token = getAccessToken()
  const tokenParts = token && decodeToken(token)
  const { header = {} } = tokenParts as ITokenProps || {}
  const { scopes = {} } = header
  return tokenParts && header ? scopes : []
}

export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY)
