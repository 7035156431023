import React from 'react'
import tc from 'tinycolor2'
import { IBannerLayoutProps } from './ScenePreview'

const bannerDrawing = ({
  backColor,
  backSaturation,
  frontColor,
  frontHue,
  frontSaturation,
}: IBannerLayoutProps) => (
  <>
    <path
      id="Path 36"
      d="M751.784 530.669V249.68H773.025V232.004H226.977V249.68H248.218V530.669H226.977V548.344H773.024V530.669H751.784Z"
      fill="url(#paint0_radial)"
    />
    <g id="BACK">
      <path
        id="Path 30"
        d="M825.974 567.841C825.974 567.841 781.52 586.351 774.845 588.931C768.17 591.51 755.576 594.089 739.19 594.393C722.804 594.696 639.053 594.393 639.053 594.393H360.946C360.946 594.393 277.196 594.696 260.81 594.393C244.424 594.089 231.831 591.509 225.155 588.931C218.479 586.352 173.57 567.841 173.57 567.841H72.8643C72.8643 567.841 139.888 600.347 150.508 606.112C161.128 611.877 180.246 613.395 230.314 613.395H769.686C819.754 613.395 838.871 611.878 849.492 606.112C860.113 600.346 927.088 567.841 927.088 567.841H825.974Z"
        fill="url(#paint1_linear)"
      />
      <path
        id="Path 31"
        d="M825.974 567.841C825.974 567.841 781.52 586.351 774.845 588.931C768.17 591.51 755.576 594.089 739.19 594.393C722.804 594.696 639.053 594.393 639.053 594.393H360.946C360.946 594.393 277.196 594.696 260.81 594.393C244.424 594.089 231.831 591.509 225.155 588.931C218.479 586.352 173.57 567.841 173.57 567.841H72.8643C72.8643 567.841 139.888 600.347 150.508 606.112C161.128 611.877 180.246 613.395 230.314 613.395H769.686C819.754 613.395 838.871 611.878 849.492 606.112C860.113 600.346 927.088 567.841 927.088 567.841H825.974Z"
        fill="url(#paint2_linear)"
      />
      <path
        id="Path 32"
        d="M825.974 567.841C825.974 567.841 781.52 586.351 774.845 588.931C768.17 591.51 755.576 594.089 739.19 594.393C722.804 594.696 639.053 594.393 639.053 594.393H360.946C360.946 594.393 277.196 594.696 260.81 594.393C244.424 594.089 231.831 591.509 225.155 588.931C218.479 586.352 173.57 567.841 173.57 567.841H72.8643C72.8643 567.841 139.888 600.347 150.508 606.112C161.128 611.877 180.246 613.395 230.314 613.395H769.686C819.754 613.395 838.871 611.878 849.492 606.112C860.113 600.346 927.088 567.841 927.088 567.841H825.974Z"
        fill="url(#paint3_linear)"
      />
      <path
        id="Path 33"
        d="M72.8643 568.638C72.8643 568.638 139.888 602.282 150.508 608.048C161.128 613.813 180.246 615.33 230.314 615.33H769.686C819.754 615.33 838.871 613.813 849.492 608.048C860.113 602.282 927.088 568.458 927.088 568.458V567.843C927.088 567.843 860.111 600.349 849.492 606.114C838.873 611.879 819.754 613.395 769.686 613.395H230.314C180.246 613.395 161.129 611.878 150.508 606.112C139.887 600.346 72.8643 567.841 72.8643 567.841V568.638Z"
        fill="url(#paint4_linear)"
      />
      <path
        id="Path 34"
        d="M721.659 206.894H776.211C790.169 206.894 823.548 207.045 843.727 207.349C863.905 207.653 927.135 210.839 927.135 210.839V190.66C927.135 190.66 868.609 87.6408 858.443 71.1026C848.278 54.5644 845.698 52.7441 835.837 46.6758C825.975 40.6075 810.651 40 793.202 40H206.797C189.349 40 174.025 40.6068 164.163 46.6758C154.301 52.7448 151.722 54.565 141.556 71.1026C131.391 87.6402 72.8643 190.66 72.8643 190.66V210.839C72.8643 210.839 136.094 207.652 156.274 207.349C176.453 207.046 209.831 206.894 223.789 206.894H721.659V206.894Z"
        fill="url(#paint5_linear)"
      />
      <path
        id="Rectangle 32"
        d="M173.763 210.839H72.8643V567.841H173.763V210.839Z"
        fill="url(#paint6_linear)"
      />
      <path
        id="Rectangle 33"
        d="M927.135 210.839H826.236V567.841H927.135V210.839Z"
        fill="url(#paint7_linear)"
      />
      <path
        id="Path 35"
        d="M927.135 210.839H826.237L774.912 208.866H225.088L173.762 210.839H72.8643C72.8643 210.839 136.094 207.652 156.274 207.349C176.453 207.046 209.831 206.894 223.789 206.894H776.21C790.168 206.894 823.547 207.045 843.726 207.349C863.905 207.653 927.135 210.839 927.135 210.839Z"
        fill="url(#paint8_linear)"
      />
      <path
        id="Path 38"
        d="M245.183 231.397V549.937H250.171L251.869 548.344V232.004L250.171 231.397H245.183Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 39"
        d="M271.654 231.397V549.937H276.823L278.341 548.344V232.004L276.823 231.397H271.654Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 40"
        d="M298.125 231.397V549.937H303.475L304.812 548.344V232.004L303.475 231.397H298.125Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 41"
        d="M324.596 231.397V549.937H330.126L331.281 548.344V232.004L330.126 231.397H324.596Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 42"
        d="M351.066 231.397V549.937H356.779L357.753 548.344V232.004L356.779 231.397H351.066Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 43"
        d="M377.538 231.397V549.937H383.432L384.225 548.344V232.004L383.432 231.397H377.538Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 44"
        d="M404.006 231.397V549.937H410.082L410.697 548.344V232.004L410.082 231.397H404.006Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 45"
        d="M430.479 231.397V549.937H436.735L437.165 548.344V232.004L436.735 231.397H430.479Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 48"
        d="M749.83 231.397L748.131 232.004V548.344L749.83 549.937H754.818V231.397H749.83Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 49"
        d="M721.659 232.004V548.344L723.177 549.937H728.346V231.397H723.177L721.659 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 50"
        d="M695.189 232.004V548.344L696.526 549.937H701.877V231.397H696.526L695.189 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 51"
        d="M668.718 232.004V548.344L669.873 549.937H675.403V231.397H669.873L668.718 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 52"
        d="M642.247 232.004V548.344L643.221 549.937H648.934V231.397H643.221L642.247 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Rectangle 35"
        d="M250.171 231.397H243.438V549.938H250.171V231.397Z"
        fill="url(#paint9_radial)"
      />
      <path
        id="Rectangle 36"
        d="M276.823 231.397H270.091V549.938H276.823V231.397Z"
        fill="url(#paint10_radial)"
      />
      <path
        id="Rectangle 37"
        d="M303.475 231.397H296.742V549.938H303.475V231.397Z"
        fill="url(#paint11_radial)"
      />
      <path
        id="Rectangle 38"
        d="M330.128 231.397H323.396V549.938H330.128V231.397Z"
        fill="url(#paint12_radial)"
      />
      <path
        id="Rectangle 39"
        d="M356.779 231.397H350.047V549.938H356.779V231.397Z"
        fill="url(#paint13_radial)"
      />
      <path
        id="Rectangle 40"
        d="M383.432 231.397H376.699V549.938H383.432V231.397Z"
        fill="url(#paint14_radial)"
      />
      <path
        id="Rectangle 41"
        d="M410.084 231.397H403.352V549.938H410.084V231.397Z"
        fill="url(#paint15_radial)"
      />
      <path
        id="Rectangle 42"
        d="M436.736 231.397H430.004V549.938H436.736V231.397Z"
        fill="url(#paint16_radial)"
      />
      <path
        id="Path 46"
        d="M456.95 231.397V549.937H463.388L463.637 548.344V232.004L463.388 231.397H456.95Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 47"
        d="M483.421 231.397V549.937H490.04L490.108 548.344V232.004L490.04 231.397H483.421Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 53"
        d="M615.776 232.004V548.344L616.569 549.937H622.463V231.397H616.569L615.776 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 54"
        d="M589.304 232.004V548.344L589.918 549.937H595.995V231.397H589.918L589.304 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 55"
        d="M562.834 232.004V548.344L563.264 549.937H569.521V231.397H563.264L562.834 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 56"
        d="M536.363 232.004V548.344L536.612 549.937H543.05V231.397H536.612L536.363 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Path 57"
        d="M509.893 232.004V548.344L509.961 549.937H516.58V231.397H509.961L509.893 232.004Z"
        fill={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 33%)`}
      />
      <path
        id="Rectangle 43"
        d="M463.389 231.397H456.656V549.938H463.389V231.397Z"
        fill="url(#paint17_radial)"
      />
      <path
        id="Rectangle 44"
        d="M490.04 231.397H483.308V549.938H490.04V231.397Z"
        fill="url(#paint18_radial)"
      />
      <path
        id="Rectangle 45"
        d="M516.694 231.397H509.961V549.938H516.694V231.397Z"
        fill="url(#paint19_radial)"
      />
      <path
        id="Rectangle 46"
        d="M543.345 231.397H536.612V549.938H543.345V231.397Z"
        fill="url(#paint20_radial)"
      />
      <path
        id="Rectangle 47"
        d="M569.997 231.397H563.265V549.938H569.997V231.397Z"
        fill="url(#paint21_radial)"
      />
      <path
        id="Rectangle 48"
        d="M596.649 231.397H589.916V549.938H596.649V231.397Z"
        fill="url(#paint22_radial)"
      />
      <path
        id="Rectangle 49"
        d="M623.301 231.397H616.568V549.938H623.301V231.397Z"
        fill="url(#paint23_radial)"
      />
      <path
        id="Rectangle 50"
        d="M649.954 231.397H643.222V549.938H649.954V231.397Z"
        fill="url(#paint24_radial)"
      />
      <path
        id="Rectangle 51"
        d="M676.606 231.397H669.873V549.938H676.606V231.397Z"
        fill="url(#paint25_radial)"
      />
      <path
        id="Rectangle 52"
        d="M703.258 231.397H696.525V549.938H703.258V231.397Z"
        fill="url(#paint26_radial)"
      />
      <path
        id="Rectangle 53"
        d="M729.91 231.397H723.178V549.938H729.91V231.397Z"
        fill="url(#paint27_radial)"
      />
      <path
        id="Rectangle 54"
        d="M756.563 231.397H749.83V549.938H756.563V231.397Z"
        fill="url(#paint28_radial)"
      />
    </g>
    <g id="banner1">
      <path
        id="banner1_shadow"
        d="M220.49 227.225H13.0583C6.34152 227.225 0.896484 232.67 0.896484 239.387V541.948C0.896484 548.665 6.34152 554.11 13.0583 554.11H220.49C227.207 554.11 232.652 548.665 232.652 541.948V239.387C232.652 232.67 227.207 227.225 220.49 227.225Z"
        fill={`hsl(${frontHue},
          ${frontSaturation}, 19%)`}
      />
      <path
        id="banner1_frame"
        d="M219.593 227.225H12.1618C5.44503 227.225 0 232.67 0 239.387V541.948C0 548.665 5.44503 554.11 12.1618 554.11H219.593C226.31 554.11 231.755 548.665 231.755 541.948V239.387C231.755 232.67 226.31 227.225 219.593 227.225Z"
        fill="url(#paint29_radial)"
      />
      <path
        id="banner1_viewport"
        d="M226.977 541.948L226.977 239.387C226.977 235.309 223.671 232.004 219.594 232.004H12.1617C8.08444 232.004 4.77914 235.309 4.77914 239.387L4.77914 541.948C4.77914 546.025 8.08444 549.33 12.1617 549.33H219.594C223.671 549.33 226.977 546.025 226.977 541.948Z"
        fill="#fff"
      />
    </g>
    <g id="banner2">
      <path
        id="banner2_shadow"
        d="M779.51 554.11H986.942C993.658 554.11 999.104 548.665 999.104 541.948V239.387C999.104 232.67 993.658 227.225 986.942 227.225H779.51C772.793 227.225 767.348 232.67 767.348 239.387V541.948C767.348 548.665 772.793 554.11 779.51 554.11Z"
        fill={`hsl(${frontHue},
          ${frontSaturation}, 19%)`}
      />
      <path
        id="banner2_frame"
        d="M780.407 554.11H987.838C994.555 554.11 1000 548.665 1000 541.948V239.387C1000 232.67 994.555 227.225 987.838 227.225H780.407C773.69 227.225 768.245 232.67 768.245 239.387V541.948C768.245 548.665 773.69 554.11 780.407 554.11Z"
        fill="url(#paint30_radial)"
      />
      <path
        id="banner2_viewport"
        d="M995.221 541.948V239.387C995.221 235.309 991.915 232.004 987.838 232.004H780.406C776.329 232.004 773.023 235.309 773.023 239.387V541.948C773.023 546.025 776.329 549.33 780.406 549.33H987.838C991.915 549.33 995.221 546.025 995.221 541.948Z"
        fill="#fff"
      />
    </g>
    <path id="banner5" d="M794.265 46.6758H205.735V200.75H794.265V46.6758Z" fill="#fff" />
    <g id="banner4">
      <path
        id="banner4_frame"
        d="M589.215 402.562V301.952H410.79V402.562H589.215Z"
        fill={`hsl(${frontHue},
          ${frontSaturation}, 40%)`}
      />
      <path
        id="banner4_viewport"
        d="M587.848 401.194V303.314H412.154V401.194H587.848Z"
        fill="#fff"
      />
    </g>
    <g id="banner3">
      <path
        id="banner3_shadow"
        d="M669.5 594.393H330.5L347.918 610.475H652.083L669.5 594.393Z"
        fill="url(#paint31_linear)"
      />
      <path
        id="banner3_viewport"
        d="M655.212 488.661H344.788V601.372H655.212V488.661Z"
        fill="#fff"
      />
      <path
        id="banner3_surface"
        d="M662.153 482.725H337.847L345.588 471.498H656.349L662.153 482.725Z"
        fill="url(#paint32_linear)"
      />
      <g id="banner3_frame">
        <path d="M337.847 482.725H662.153V488.66H337.847V482.725Z" fill="url(#paint33_linear)" />
        <path d="M347.917 601.372H652.082V610.475H347.917V601.372Z" fill="url(#paint34_linear)" />
      </g>
    </g>
    <g id="LIGHTS">
      <g id="Light">
        <path
          id="Light Holder"
          d="M331.36 214.214H309.664V208.222C309.664 207.463 331.36 207.463 331.36 208.222V214.214Z"
          fill="url(#paint35_linear)"
        />
        <path
          id="BULB"
          d="M320.512 214.688C326.504 214.688 331.36 214.476 331.36 214.214C331.36 213.953 326.504 213.741 320.512 213.741C314.521 213.741 309.664 213.953 309.664 214.214C309.664 214.476 314.521 214.688 320.512 214.688Z"
          fill={`hsl(${frontHue},
            ${frontSaturation}, 93%)`}
        />
      </g>
      <g id="Light_2">
        <path
          id="Light Holder_2"
          d="M421.142 214.214H399.445V208.222C399.445 207.463 421.142 207.463 421.142 208.222V214.214Z"
          fill="url(#paint36_linear)"
        />
        <path
          id="BULB_2"
          d="M410.294 214.688C416.285 214.688 421.142 214.476 421.142 214.214C421.142 213.953 416.285 213.741 410.294 213.741C404.302 213.741 399.445 213.953 399.445 214.214C399.445 214.476 404.302 214.688 410.294 214.688Z"
          fill={`hsl(${frontHue},
            ${frontSaturation}, 93%)`}
        />
      </g>
      <g id="Light_3">
        <path
          id="Light Holder_3"
          d="M510.924 214.214H489.229V208.222C489.229 207.463 510.924 207.463 510.924 208.222V214.214Z"
          fill="url(#paint37_linear)"
        />
        <path
          id="BULB_3"
          d="M500.076 214.688C506.067 214.688 510.924 214.476 510.924 214.214C510.924 213.953 506.067 213.741 500.076 213.741C494.084 213.741 489.228 213.953 489.228 214.214C489.228 214.476 494.084 214.688 500.076 214.688Z"
          fill={`hsl(${frontHue},
            ${frontSaturation}, 93%)`}
        />
      </g>
      <g id="Light_4">
        <path
          id="Light Holder_4"
          d="M600.705 214.214H579.01V208.222C579.01 207.463 600.705 207.463 600.705 208.222V214.214Z"
          fill="url(#paint38_linear)"
        />
        <path
          id="BULB_4"
          d="M589.858 214.688C595.849 214.688 600.706 214.476 600.706 214.214C600.706 213.953 595.849 213.741 589.858 213.741C583.867 213.741 579.01 213.953 579.01 214.214C579.01 214.476 583.867 214.688 589.858 214.688Z"
          fill={`hsl(${frontHue},
            ${frontSaturation}, 93%)`}
        />
      </g>
      <g id="Light_5">
        <path
          id="Light Holder_5"
          d="M690.486 214.214H668.79V208.222C668.79 207.463 690.486 207.463 690.486 208.222V214.214Z"
          fill="url(#paint39_linear)"
        />
        <path
          id="BULB_5"
          d="M679.639 214.688C685.631 214.688 690.487 214.476 690.487 214.214C690.487 213.953 685.631 213.741 679.639 213.741C673.648 213.741 668.791 213.953 668.791 214.214C668.791 214.476 673.648 214.688 679.639 214.688Z"
          fill={`hsl(${frontHue},
            ${frontSaturation}, 93%)`}
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(98.6877 368.663) scale(615.883 616.231)"
      >
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="72.8643"
        y1="590.618"
        x2="927.088"
        y2="590.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.325"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.734"
          stopColor={`hsl(${tc(backColor).spin(22).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.753"
          stopColor={`hsl(${tc(backColor).spin(18).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.782"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.87"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="504.247"
        y1="527.48"
        x2="504.24"
        y2="551.078"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.268"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h},
          ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="499.976"
        y1="566.565"
        x2="499.976"
        y2="587.976"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.103"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h},
          ${backSaturation}, 38%)`}
        />
        <stop
          offset="0.456"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h},
          ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="72.8643"
        y1="591.585"
        x2="927.088"
        y2="591.585"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.428"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.582"
          stopColor={`hsl(${tc(backColor).spin(3).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.692"
          stopColor={`hsl(${tc(backColor).spin(6).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.78"
          stopColor={`hsl(${tc(backColor).spin(10).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.855"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.862"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.941"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 29%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 38%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="-575.528"
        y1="125.419"
        x2="278.744"
        y2="125.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 38%)`}
        />
        <stop
          offset="0.099"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 29%)`}
        />
        <stop
          offset="0.146"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.15"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.156"
          stopColor={`hsl(${tc(backColor).spin(11).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.172"
          stopColor={`hsl(${tc(backColor).spin(10).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.844"
          stopColor={`hsl(${tc(backColor).spin(4).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.85"
          stopColor={`hsl(${tc(backColor).spin(4).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.853"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.854"
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.862"
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.906"
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.908"
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.916"
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h},
          ${backSaturation}, 50%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="72.8643"
        y1="389.34"
        x2="173.763"
        y2="389.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.972"
          stopColor={`hsl(${tc(backColor).spin(7).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="826.236"
        y1="389.34"
        x2="927.135"
        y2="389.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h},
          ${backSaturation}, 48%)`}
        />
        <stop
          offset="0.972"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h},
          ${backSaturation}, 46%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="927.135"
        y1="208.866"
        x2="72.8643"
        y2="208.866"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.223"
          stopColor={`hsl(${tc(backColor).spin(22).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(23).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <radialGradient
        id="paint9_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3655.43 383.023) scale(459.652 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint10_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.886 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint11_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.883 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint12_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.889 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint13_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.886 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint14_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.884 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint15_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.888 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint16_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.886 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint17_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.891 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint18_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.888 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint19_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.887 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint20_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.884 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint21_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.889 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint22_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-12390.5 383.023) scale(459.642 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint23_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.891 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint24_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.89 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint25_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.887 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint26_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.892 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint27_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.89 383.023) scale(9.71513 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint28_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-19796.4 383.023) scale(459.642 459.655)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h},
          ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h},
          ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(29).toHsl().h},
          ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint29_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-19.0929 270.374) scale(864.319 864.612)"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 85%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <radialGradient
        id="paint30_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1925.16 766.109) scale(828.047 828.328)"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 85%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <linearGradient
        id="paint31_linear"
        x1="500"
        y1="610.475"
        x2="500"
        y2="594.393"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint32_linear"
        x1="337.847"
        y1="485.689"
        x2="662.153"
        y2="485.689"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 19%)`}
        />
        <stop
          offset="0.503"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 19%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint33_linear"
        x1="347.917"
        y1="605.923"
        x2="652.082"
        y2="605.923"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 69%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint34_linear"
        x1="347.917"
        y1="605.923"
        x2="652.082"
        y2="605.923"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 69%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint35_linear"
        x1="309.664"
        y1="210.934"
        x2="331.36"
        y2="210.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint36_linear"
        x1="399.445"
        y1="210.934"
        x2="421.142"
        y2="210.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint37_linear"
        x1="489.229"
        y1="210.934"
        x2="510.924"
        y2="210.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint38_linear"
        x1="579.01"
        y1="210.934"
        x2="600.705"
        y2="210.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id="paint39_linear"
        x1="668.79"
        y1="210.934"
        x2="690.486"
        y2="210.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue},
          ${frontSaturation}, 51%)`}
        />
      </linearGradient>
    </defs>
  </>
)

export default bannerDrawing
