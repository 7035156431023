import { AxiosError } from 'axios'
import { types } from 'mobx-state-tree'
import { flow } from 'mobx-state-tree'
import { IAnyStateTreeNode } from 'mobx-state-tree'

export enum LoadingStatus {
  error = 'error',
  pending = 'pending',
  not_loaded = 'not_loaded',
  success = 'success',
}

// we need this interface for type compatibility inside flow actions
export interface IRootStore extends IAnyStateTreeNode {
  showError: (message: string, delay?: number) => any
}

export const createLoadingStatusType = (defaultStatus: LoadingStatus) => {
  return types.optional(
    types.enumeration('LoadingStatus', Object.values(LoadingStatus)),
    defaultStatus
  )
}

export const LoadingStatusType = createLoadingStatusType(LoadingStatus.not_loaded)

export const combineLoadingStatus = (statuses: LoadingStatus[]): LoadingStatus => {
  // tslint:disable-next-line: forin
  for (const status in LoadingStatus) {
    const found = statuses.find(s => s === status)
    if (found) {
      return status as LoadingStatus
    }
  }
  return LoadingStatus.not_loaded
}

export const createAxiosAction = <T extends any[]>(
  fn: (...args: T) => Promise<any>,
  onStatusChange: (status: LoadingStatus) => any,
  onError: (err: AxiosError) => any
): ((...args: T) => Promise<any>) => {
  return flow(function*(...args: any[]) {
    onStatusChange(LoadingStatus.pending)
    try {
      yield fn(...(args as T))
      onStatusChange(LoadingStatus.success)
    } catch (error) {
      console.error(error)
      onStatusChange(LoadingStatus.error)
      onError(error)
    }
  })
}

export const createAxiosActionWithCallback = <T extends any[]>(
  fn: (...args: T) => Promise<any>,
  onStatusChange: (status: LoadingStatus) => any,
  onError: (err: AxiosError) => any
): ((...args: T) => Promise<any>) => {
  return createAxiosAction(
    flow(function*(...args: T) {
      let rest = args
      let onDone = null
      const last = args.length - 1
      // unsafe, but good for this purpose
      if (typeof args[last] === 'function') {
        rest = args.slice(0, args.length - 1) as T
        onDone = args[last]
      }
      yield fn(...(rest as T))
      if (onDone) {
        yield onDone()
      }
    }),
    onStatusChange,
    onError
  )
}
