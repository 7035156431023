import React, { memo, useMemo, useState } from 'react'
import { Header, Icon, Table } from 'semantic-ui-react'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'
import { IAttendeeForStatistics } from '../../stores/registrations'

export interface IStatItem {
  name: string
  statistics: any // { [key: string]: Array<{ [key: string]: number }> }
  type: string
  users: number
  views: number
}

interface IUserViewsProps {
  views: { [key: string]: number }
}

const UserViews = memo(({ views }: IUserViewsProps) => {
  const byDays: { [key: string]: number } = {}
  Object.keys(views).forEach(key => {
    const dayKey = key.replace(/T.*/, '')
    byDays[dayKey] = byDays[dayKey] ? byDays[dayKey]! + views[key]! : views[key]!
  })
  // return <pre>{JSON.stringify(views, null, 2)}</pre>
  return (
    <Table style={{ maxWidth: '300px' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={8}>Date</Table.HeaderCell>
          <Table.HeaderCell width={8}>Views</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <>
          {Object.keys(byDays)
            .sort()
            .map(key => (
              <Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>{byDays[key]}</Table.Cell>
              </Table.Row>
            ))}
        </>
      </Table.Body>
    </Table>
  )
})

interface IItemRowProps {
  item: IStatItem
  attendies: { [key: string]: IAttendeeForStatistics }
}

export const ItemRow = memo(({ attendies, item }: IItemRowProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const headers = useMemo(
    () => [
      { name: 'firstName', width: 3, title: 'First Name' },
      { name: 'lastName', width: 3, title: 'Last Name' },
      { name: 'email', width: 4, title: 'Email' },
      { name: 'phoneNumber', width: 3, title: 'Phone' },
      { name: 'company', width: 3, title: 'Company Name' },
    ],
    []
  )

  return (
    <>
      <Table.Row key={item.name}>
        <Table.Cell
          className="expandCell"
          style={{ visibility: item.statistics ? 'visible' : 'hidden' }}
        >
          <Icon
            name={expanded ? 'chevron up' : 'chevron down'}
            size="small"
            onClick={() => setExpanded(!expanded)}
          />
        </Table.Cell>
        <Table.Cell width={6}>
          <Header as="h4">{item.name}</Header>
        </Table.Cell>
        <Table.Cell width={6}>{item.type}</Table.Cell>
        <Table.Cell width={2}>{item.users}</Table.Cell>
        <Table.Cell width={2}>{item.views}</Table.Cell>
      </Table.Row>
      {expanded && item.statistics && (
        <Table.Row>
          <Table.Cell className="expandCell" />
          <Table.Cell colSpan="4" style={{ borderTop: 'none', padding: '10px 0' }}>
            <Table>
              <Table.Header>
                <Table.Row>
                  {headers.map(header => (
                    <Table.HeaderCell width={header.width as SemanticWIDTHS} key={header.title}>
                      {header.title}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(item.statistics).map(id => (
                  <>
                    <Table.Row key={id}>
                      {headers.map(header => (
                        <Table.Cell key={header.name}>
                          {(attendies[id] as any)![header.name]}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                    <Table.Row key={'2_' + id}>
                      <Table.Cell colSpan="5" style={{ borderTop: 'none' }}>
                        <UserViews views={item.statistics[id]} />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ))}
              </Table.Body>
            </Table>
            {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(attendies[Object.keys(item.statistics)[0]], null, 2)}</pre>*/}
          </Table.Cell>
        </Table.Row>
      )}
    </>
  )
})
