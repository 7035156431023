import React from 'react'
import { Cell, Pie, PieChart, PieLabelRenderProps, Tooltip, TooltipProps } from 'recharts'
import { IStatItem } from './StatisticsItemRow'
import { Label } from 'semantic-ui-react'

interface ICustomChartProps {
  dataArray: IStatItem[]
  field: string
  title: string
  summ: number
}

interface IChartColors {
  [key: string]: string
}

export const chartColors = {
  Document: '#8dc77b',
  Email: '#cddc39',
  External: '#00bcd4',
  Facebook: '#ffeb3b',
  Instagram: '#2196f3',
  Text: '#ff9800',
  Twitter: '#f97b72',
  Video: '#8884d8',
} as IChartColors

const CustomizedLabel = ({ x, y, value, percent, fill }: PieLabelRenderProps) => {
  if (value && percent && percent > 0.02) {
    return (
      <text x={x} y={y} fill={fill}>
        {value}
      </text>
    )
  }

  return null
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload) {
    const { name, value } = payload[0]
    return (
      <Label>
        {value} - {name}
      </Label>
    )
  }

  return null
}

export const CustomChart = ({ dataArray, field, title, summ }: ICustomChartProps) => (
  <div className="chart-wrapper">
    <span className="total-label">
      {title}: {summ}
    </span>
    <PieChart className="report-chart" width={280} height={260}>
      <Pie
        data={dataArray}
        dataKey={field}
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={70}
        outerRadius={100}
        animationDuration={300}
        labelLine={false}
        label={CustomizedLabel}
      >
        {dataArray.map(entry => (
          <Cell key={field + entry.name} fill={chartColors[entry.type]} />
        ))}
      </Pie>
      <Tooltip content={CustomTooltip} />
    </PieChart>
  </div>
)
