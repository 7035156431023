import { layouts, ScenePreview } from 'components/scene-preview/ScenePreview'
import React, { useEffect, useMemo, useState } from 'react'
import { IFullBanner, IPreviewSponsorItem, ISponsorAvatarItem } from 'stores/sponsors'
import { API } from 'utils/config'

const code = window.location.pathname.split('/').pop()
const url = `${API}/api/public/media-partners/wizard/${code}`

const findLayout = (name: string) => layouts.find(item => item.name === name) || layouts[0]

export const BoothPreview = () => {
  const [sponsor, setSponsor] = useState<IPreviewSponsorItem>()

  const getAvatar = (sponsor: IPreviewSponsorItem) => {
    if (sponsor && Array.isArray(sponsor.virtualAvatars)) {
      return sponsor.virtualAvatars[0] as ISponsorAvatarItem
    }
    return null
  }

  const sponsorItem = useMemo(
    () => ({
      avatar: sponsor ? getAvatar(sponsor) : null,
      banners: sponsor?.virtualBanners as IFullBanner[],
      layout: sponsor?.boothLayout ? findLayout(sponsor.boothLayout) : layouts[0],
    }),
    [sponsor]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
        })
        const json = await response.json()
        setSponsor(json)
      } catch (event) {
        console.error(event)
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  if (!sponsor) {
    return null
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <ScenePreview
          avatar={sponsorItem.avatar ? sponsorItem.avatar.imageUrl : ''}
          currentLayout={sponsorItem.layout}
          form={sponsorItem.banners}
          title={sponsor.company}
          layers={sponsor.layersStyles}
          width="100%"
        />
      </div>
    </div>
  )
}

export default BoothPreview
