import {
  IAbstractionsContractsQueriesCategoriesCategoriesResponse,
  IAbstractionsContractsQueriesCategoriesCategoriesResponseCategory,
} from 'api-types'
import _ from 'lodash'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export type ICompanyCategoryItem = IAbstractionsContractsQueriesCategoriesCategoriesResponseCategory

export default types
  .model('CompaniesStore', {
    companyCategories: types.frozen<ICompanyCategoryItem[]>([]),
    fetchCategoriesStatus: LoadingStatusType,
  })

  .views(self => ({
    enabledCategories: () =>
      self.companyCategories.filter(c => c.status === 'Enabled' && c.categoryType === 'None'),
    getCategoryById: (id: string) =>
      self.companyCategories.find((c) => c.id === id) || null,
  }))

  .actions(self => ({
    fetchCompanyCategories: _.once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get(`/backoffice/master/categories?${allQuery}`)) as {
            data: IAbstractionsContractsQueriesCategoriesCategoriesResponse
          }
          self.companyCategories = data.categories
        }),
        s => (self.fetchCategoriesStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch company categories')
      )
    ),
  }))
