import ImportFromFile from 'components/import-from-file/ImportFromFile'
import SponsorCard, { statusOptions } from 'components/sponsor-card/SponsorCard'
import { observer } from 'mobx-react-lite'
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Loader,
  Menu,
  Pagination,
  Select,
  Sticky,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import {
  IInvitaionItem,
  IInvitationStatusItem,
  ISponsorItem,
  ISponsorTag,
  ISponsorTier,
  ISponsorType,
} from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'
import useDebounce from 'utils/use-debounce'

interface ISponsorsProps {
  fetchSponsors: (filter: IFilterConfig) => Promise<any>
  fetchSponsorsStatus: LoadingStatus
  sponsorsByPage: ISponsorItem[]
  fetchInvitations: () => Promise<any>
  fetchInvitationsStatus: LoadingStatus
  createInvitation: (payload: IInvitaionItem, onDone: () => any) => Promise<any>
  invitationStatus: IInvitationStatusItem[]
  loading: LoadingStatus
  totalPages: number
  resetSponsorsList: () => any
  deleteSponsor: (id: string) => Promise<any>
  draftSponsor: (id: string) => Promise<any>
  publishSponsor: (id: string) => Promise<any>
  sponsorTypes: ISponsorType[]
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
  resetCurrentSponsor: () => any
}

type SortType = 'ascending' | 'descending' | undefined

const perPageOptions = [5, 20, 50, 100, 9999].map(item => ({
  key: item,
  text: item === 9999 ? 'All' : item,
  value: item,
}))

const EmptyResult = (loader: boolean) => (
  <Table.Row>
    <Table.Cell colSpan={6} textAlign="center">
      {loader ? <Loader /> : 'Nothing found'}
    </Table.Cell>
  </Table.Row>
)

export const SponsorsList = ({
  sponsorsByPage,
  fetchSponsors,
  fetchSponsorsStatus,
  fetchInvitations,
  createInvitation,
  deleteSponsor,
  draftSponsor,
  publishSponsor,
  resetCurrentSponsor,
  sponsorTypes,
  sponsorTiers,
  sponsorTags,
  totalPages,
}: ISponsorsProps) => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const [searchValue, setSearchValue] = useState(location.search.split('=')[1] || '')
  const [statusValue, setStatusValue] = useState('')
  const [tierValue, setTierValue] = useState('')
  const [tagValue, setTagValue] = useState('')
  const [bulkSelected, setBulkSelected] = useState<string[]>([])
  const [showManage, setShowManage] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const [itemsOnPage, setItemsOnPage] = useState(20)
  const [sortBy, setSortBy] = useState('Company')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')

  const debouncedSearch = useDebounce(searchValue, 500)
  const { isMobile } = useWindowDimensions()

  const filters = useMemo(
    () =>
      ({
        filter: {
          Company: {
            operator: 'Like',
            value: debouncedSearch,
          },
          SponsorTiers: {
            operator: 'Equals',
            value: tierValue,
          },
          Status: {
            operator: 'Equals',
            value: statusValue,
          },
          Tags: {
            operator: 'Equals',
            value: tagValue,
          },
        },
        itemsOnPage,
        page: page - 1,
        sort: {
          desc: sortDirection === 'descending',
          field: sortBy,
        },
      } as IFilterConfig),
    [debouncedSearch, itemsOnPage, page, sortBy, sortDirection, statusValue, tagValue, tierValue]
  )

  const fetchPage = () => fetchSponsors(filters)

  useEffect(() => {
    fetchPage()
    // eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    resetCurrentSponsor()
    // eslint-disable-next-line
  }, [])

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    switch (data.name) {
      case 'status':
        setStatusValue(data.value as string)
        break
      case 'tier':
        setTierValue(data.value as string)
        break
      case 'tag':
        setTagValue(data.value as string)
        break
    }
  }

  const handleInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setSearchValue(value)
    history.push(`${location.pathname}${value.length ? `?search=${value}` : ''}`)
    // eslint-disable-next-line
  }, [])

  const handleFilters = useCallback(() => {
    if (showFilters) {
      setShowFilters(false)
      setSearchValue('')
      setStatusValue('')
      setTierValue('')
      setTagValue('')
      history.push(location.pathname)
    } else {
      setShowFilters(true)
    }
  }, [showFilters, history, location])

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const handleClear = useCallback(() => {
    setSearchValue('')
    history.push(location.pathname)
    // eslint-disable-next-line
  }, [])

  const sponsorTiersOptions = useMemo(
    () =>
      sponsorTiers.map(tier => ({
        key: tier.id,
        text: tier.title,
        value: tier.id,
      })),
    [sponsorTiers]
  )

  const sponsorTagsOptions = useMemo(
    () =>
      sponsorTags.map(tag => ({
        key: tag.id,
        text: tag.displayName,
        value: tag.id,
      })),
    [sponsorTags]
  )

  const loadedSponsors = fetchSponsorsStatus === LoadingStatus.success

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedSponsors
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedSponsors, setPage]
  )

  const onPublish = async (id: string) => {
    await publishSponsor(id)
    fetchPage()
  }

  const onDraft = async (id: string) => {
    await draftSponsor(id)
    fetchPage()
  }

  const onDelete = async (id: string) => {
    await deleteSponsor(id)
    fetchPage()
  }

  const handleSort = (newSort: string) => {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
    setSortBy(newSort)
  }

  const handleBulkSelect = () => {
    setShowManage(false)
    if (bulkSelected.length === sponsorsByPage.length) {
      setBulkSelected([])
    } else {
      const allIds = sponsorsByPage.map(item => item.id)
      setBulkSelected(allIds)
    }
  }

  const handleBulkToggle = (action: string, value: string) => {
    switch (action) {
      case 'add':
        setBulkSelected([...bulkSelected, value])
        break
      case 'remove':
        const newSelected = bulkSelected.filter(entry => entry !== value)
        setBulkSelected(newSelected)
        break
    }
  }

  const handleInvitation = async () => {
    const payload = {
      sponsorIds: bulkSelected,
    }
    await createInvitation(payload, () => fetchInvitations())
    setBulkSelected([])
  }

  const renderItem = (item: ISponsorItem) => (
    <SponsorCard
      key={item.id}
      manage={showManage}
      item={item}
      page={page}
      onPublish={onPublish}
      onDraft={onDraft}
      onDelete={onDelete}
      bulkSelected={bulkSelected}
      onToggle={handleBulkToggle}
      sponsorTypes={sponsorTypes}
      sponsorTiers={sponsorTiers}
      sponsorTags={sponsorTags}
    />
  )

  if (fetchSponsorsStatus === LoadingStatus.not_loaded) {
    return null
  }

  const tabs = () => (
    <Sticky context={ref} offset={isMobile ? 0 : 20}>
      <Menu tabular style={{ backgroundColor: '#fff', paddingBottom: 20 }} borderless>
        <Button as={Link} to={`/sponsors/list/create`} primary size="tiny">
          Create
        </Button>
        <ImportFromFile fetchPage={fetchPage} />
        <Button basic size="tiny" disabled={!bulkSelected.length} onClick={handleInvitation}>
          Batch Invitation
        </Button>
        <Menu.Item position="right" style={{ padding: '0 13px 0 0' }}>
          <Dropdown
            compact
            inline
            trigger={`${itemsOnPage === 9999 ? 'All rows' : `${itemsOnPage} rows per page`}`}
            pointing="top left"
            options={perPageOptions}
            defaultValue={itemsOnPage}
            onChange={(event, { value }) => setItemsOnPage(value as number)}
            style={{ paddingRight: 20 }}
          />
          <Checkbox toggle checked={showFilters} onChange={handleFilters} label="Filters" />
        </Menu.Item>
      </Menu>
    </Sticky>
  )

  return (
    <div className="sponsors" ref={ref}>
      {tabs()}

      <Table size="small" sortable basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              {sponsorsByPage.length > 0 && (
                <Checkbox
                  checked={bulkSelected.length === sponsorsByPage.length}
                  onChange={handleBulkSelect}
                />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Company' ? sortDirection : undefined}
              onClick={() => handleSort('Company')}
              width={3}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'LogoUrl' ? sortDirection : undefined}
              onClick={() => handleSort('LogoUrl')}
              width={4}
            >
              Logo
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Status' ? sortDirection : undefined}
              onClick={() => handleSort('Status')}
              width={3}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'SponsorTiers' ? sortDirection : undefined}
              onClick={() => handleSort('SponsorTiers')}
              width={3}
            >
              Sponsorships
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Tags' ? sortDirection : undefined}
              onClick={() => handleSort('Tags')}
              width={3}
            >
              Tags
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
          {showFilters ? (
            <Table.Row>
              <Table.Cell />
              <Table.Cell colSpan={2}>
                <Input
                  fluid
                  icon={
                    searchValue.length ? (
                      <Icon
                        name="close"
                        bordered
                        circular
                        link
                        onClick={handleClear}
                        style={{ boxShadow: 'none' }}
                      />
                    ) : null
                  }
                  value={searchValue}
                  placeholder="Name"
                  onChange={handleInput}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  clearable
                  name="status"
                  placeholder="Status"
                  options={statusOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  clearable
                  name="tier"
                  placeholder="Sponsorships"
                  options={sponsorTiersOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  clearable
                  name="tag"
                  placeholder="Tags"
                  options={sponsorTagsOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          ) : null}
        </Table.Header>
        <Table.Body>
          {sponsorsByPage.length > 0
            ? sponsorsByPage.map(item => renderItem(item))
            : EmptyResult(fetchSponsorsStatus === LoadingStatus.pending)}
        </Table.Body>
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={6} textAlign="center">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={false}
                  lastItem={false}
                  prevItem={false}
                  nextItem={false}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  )
}

export default observer(() => {
  const {
    deleteSponsor,
    draftSponsor,
    fetchSponsors,
    fetchSponsorsStatus,
    fetchInvitations,
    fetchInvitationsStatus,
    createInvitation,
    invitationStatus,
    publishSponsor,
    resetSponsorsList,
    saveSponsor,
    sponsorsByPage,
    sponsorTypes,
    sponsorTiers,
    sponsorTags,
    totalPages,
    resetCurrentSponsor,
  } = useRootData(store => store.sponsorsStore)
  const loading = combineLoadingStatus([fetchSponsorsStatus])
  return (
    <SponsorsList
      {...{
        createInvitation,
        deleteSponsor,
        draftSponsor,
        fetchInvitations,
        fetchInvitationsStatus,
        fetchSponsors,
        fetchSponsorsStatus,
        invitationStatus,
        loading,
        publishSponsor,
        resetCurrentSponsor,
        resetSponsorsList,
        saveSponsor,
        sponsorTags,
        sponsorTiers,
        sponsorTypes,
        sponsorsByPage,
        totalPages,
      }}
    />
  )
})
