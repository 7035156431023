import { SelectInput, TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions, FormikProps } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Header,
  Modal,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ILinkItem, ISponsorItem, IUpdateLinkItem } from 'stores/sponsors'
import { LoadingStatus } from 'utils/store'

const initialValues = {
  attachStatus: 'Free',
  id: '',
  linkType: 'External',
  openLinkType: 'NewTab',
  sponsorId: '',
  status: 'Draft',
  title: '',
  url: '',
} as IUpdateLinkItem

const typeOptions = [
  {
    key: 'facebook',
    text: 'Facebook',
    value: 'Facebook',
  },
  {
    key: 'Twitter',
    text: 'Twitter',
    value: 'Twitter',
  },
  {
    key: 'instagram',
    text: 'Instagram',
    value: 'Instagram',
  },
  {
    key: 'email',
    text: 'Email',
    value: 'Email',
  },
  {
    key: 'external',
    text: 'External',
    value: 'External',
  },
]

const statusOptions = [
  {
    key: 'draft',
    text: 'Draft',
    value: 'Draft',
  },
  {
    key: 'published',
    text: 'Published',
    value: 'Published',
  },
]

interface ILinksFormProps extends FormikProps<IUpdateLinkItem> {
  item: ISponsorItem
}

type SortType = 'ascending' | 'descending' | undefined

const LinksForm = ({ item, ...props }: ILinksFormProps) => {
  return (
    <Form as={FormikForm} autoComplete="off">
      <TextInput title="Title" name="title" />
      <SelectInput name="linkType" title="Type" options={typeOptions} />
      <SelectInput name="status" title="Status" options={statusOptions} />
      {/* <RadioGroup name="openLinkType" title="Open" inline={false} options={openLinkOptions} /> */}
      <TextInput title="URL" name="url" />
    </Form>
  )
}

interface IEditLinksProps extends IEditLinksFormInjectedProps {
  actionStatus: LoadingStatus
  deleteLink: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
  fetchLinks: (id: string) => Promise<any>
  fetchLinksStatus: string
  links: ILinkItem[]
  saveLink: (payload: IUpdateLinkItem, onDone: () => any) => Promise<any>
  updateLink: (payload: IUpdateLinkItem, onDone: () => any) => Promise<any>
}

interface IEditLinksFormInjectedProps {
  item: ISponsorItem
}

export const EditLinks = ({
  actionStatus,
  deleteLink,
  fetchLinks,
  fetchLinksStatus,
  item,
  links,
  saveLink,
  updateLink,
}: IEditLinksProps) => {
  const history = useHistory()
  const location = useLocation()
  const { slug } = useParams()

  const queryParams = new URLSearchParams(location.search.slice(1))
  const idFromUrl = queryParams.get('id')?.toString()
  const entryFromUrl = links.find((entry: ILinkItem) => entry.id === idFromUrl) || {}

  const [showModal, setShowModal] = useState(false)
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [entryToEdit, setEntryToEdit] = useState(entryFromUrl)
  const [sortBy, setSortBy] = useState('title')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')
  const [attachedFilter, setAttachedFilter] = useState(false)

  const toggleManage = useCallback(() => setShowManage(!showManage), [showManage, setShowManage])

  const toggleModal = async (entry?: ILinkItem) => {
    await setEntryToEdit(entry ? entry : {})
    setShowModal(!showModal)
    history.push(`${location.pathname}?tab=Links${entry ? `&id=${entry.id}` : ''}`)
  }

  useEffect(() => {
    if (slug !== 'create') {
      fetchLinks(item.id)
    }
  }, [fetchLinks, item, slug])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const handleStatus = async (entry: any) => {
    const payload = { ...entry, sponsorId: item.id }
    await updateLink(payload, () => fetchLinks(item.id))
  }

  const handleDelete = async (entry: any) => {
    await deleteLink({ sponsorId: item.id, id: entry.id }, () => fetchLinks(item.id))
  }

  const handleSort = (newSort: string) => {
    setSortBy(newSort)
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const handleSubmit = async (
    values: IUpdateLinkItem,
    { resetForm }: Partial<FormikActions<IUpdateLinkItem>>
  ) => {
    values.sponsorId = item.id
    await saveLink(values, () => fetchLinks(item.id))
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal()
  }

  const displayContent = (entries: any) => {
    const result = _.orderBy(entries, sortBy, sortDirection === 'ascending' ? 'asc' : 'desc')
    if (attachedFilter) {
      return result.filter(entry => entry.attachStatus === 'Attached')
    }
    return result
  }

  return (
    <>
      <Grid relaxed>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Checkbox
              toggle
              label="Show attahed only"
              onChange={() => setAttachedFilter(!attachedFilter)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table size="small" singleLine sortable columns={5} basic="very">
        <Table.Header>
          <Table.Row>
            {/* {showManage && <Table.HeaderCell collapsing> */}
            {/*   <Checkbox /> */}
            {/* </Table.HeaderCell>} */}
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'title' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('title')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'linkType' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('linkType')}
            >
              Type
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'status' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('status')}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              width={3}
              sorted={sortBy === 'attachStatus' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('attachStatus')}
            >
              Booth
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={toggleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {displayContent(links).map((entry, index) => (
            <Table.Row key={index}>
              {/* {showManage && <Table.Cell collapsing> */}
              {/*   <Checkbox /> */}
              {/* </Table.Cell>} */}
              <Table.Cell>
                <Header as="h4">{entry.title}</Header>
              </Table.Cell>
              <Table.Cell>{entry.linkType}</Table.Cell>
              <Table.Cell
                positive={entry.status === 'Published'}
                warning={entry.status === 'Draft'}
              >
                <Dropdown
                  inline
                  pointing="top left"
                  options={statusOptions}
                  trigger={entry.status}
                  defaultValue={entry.status}
                  disabled={showManage}
                  onChange={(event, { value }) => handleStatus({ ...entry, status: value })}
                />
              </Table.Cell>
              <Table.Cell
                positive={entry.attachStatus === 'Attached'}
                warning={entry.attachStatus === 'Free'}
              >
                {entry.attachStatus}
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => toggleModal(entry)}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={props => {
          const { values, touched, setSubmitting, resetForm } = props
          return (
            <Modal
              centered={false}
              dimmer="inverted"
              size="tiny"
              trigger={(
                <Button primary basic onClick={() => toggleModal()}>
                  Add Link
                </Button>
              )}
              open={showModal}
              onClose={() => {
                Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
              }}
              closeOnDimmerClick={true}
            >
              <Modal.Header>{`${fullValues.id.length ? 'Edit' : 'Add'}`} Link</Modal.Header>
              <Modal.Content>
                <LinksForm item={item} {...props} />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="submit"
                  onClick={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                  }}
                  content="Cancel"
                />
                <Button
                  type="submit"
                  width={3}
                  primary
                  onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                  loading={actionStatus === LoadingStatus.pending}
                  content="Save"
                />
              </Modal.Actions>
            </Modal>
          )
        }}
      />

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </>
  )
}

export const EditLinksFormInjected = observer((props: IEditLinksFormInjectedProps) => {
  const {
    actionStatus,
    deleteLink,
    fetchLinks,
    fetchLinksStatus,
    links,
    saveLink,
    updateLink,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditLinks
      {...props}
      {...{
        actionStatus,
        deleteLink,
        fetchLinks,
        fetchLinksStatus,
        links,
        saveLink,
        updateLink,
      }}
    />
  )
})

export default EditLinksFormInjected
