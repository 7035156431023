import PageLoading from 'components/page-loading/PageLoading'
import { Field, FieldProps, Form as FormikForm, Formik, FormikActions, FormikProps } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, Divider, Form, Message, Transition } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { REACT_APP_ROLE } from 'utils/config'
import { getAccessTokenItem } from 'utils/auth'
import { LoadingStatus } from 'utils/store'
import * as Yup from 'yup'
import './Auth.css'

import { WITH_SAML } from 'utils/config'
import AuthSaml from './AuthSaml'

interface ILoginFormValues {
  password: string
  username: string
}

const resetText = {
  after: 'We send you link for restore password, plese check your email',
  before: 'We will send you a link to update your password',
}

const LoginForm = ({ errors, submitForm, values, touched }: FormikProps<ILoginFormValues>) => (
  <Form as={FormikForm} size="large">
    <Field
      name="username"
      render={({ field }: FieldProps<ILoginFormValues>) => (
        <Form.Input
          {...field}
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Username or email"
          type="text"
          error={
            errors.username && {
              content: errors.username as string,
              pointing: 'above',
            }
          }
        />
      )}
    />
    <Field
      name="password"
      render={({ field }: FieldProps<ILoginFormValues>) => (
        <Form.Input
          {...field}
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          error={
            errors.password && {
              content: errors.password as string,
              pointing: 'above',
            }
          }
        />
      )}
    />
    <Button
      primary
      fluid
      size="large"
      onClick={submitForm}
      disabled={!Object.keys(values).length || !Object.keys(touched).length}
      content="Login"
    />
  </Form>
)

interface IResetFormValues {
  email: string
}

const ResetForm = ({ errors, submitForm, values, touched }: FormikProps<IResetFormValues>) => (
  <Form as={FormikForm} size="large">
    <Field
      name="email"
      render={({ field }: FieldProps<IResetFormValues>) => (
        <Form.Input
          {...field}
          fluid
          icon="mail"
          iconPosition="left"
          placeholder="Email"
          type="text"
          error={
            errors.email && {
              content: errors.email as string,
              pointing: 'above',
            }
          }
        />
      )}
    />
    <Button
      primary
      fluid
      size="large"
      onClick={submitForm}
      content="Send"
      disabled={!Object.keys(values).length || !Object.keys(touched).length}
    />
  </Form>
)

interface IAuthHandlerProps {
  accessToken: string
  children: ReactElement
  exhibitorLogin: (username: string, password: string) => Promise<any>
  exhibitorReset: (email: string) => Promise<any>
  getNewToken: () => Promise<any>
  login: (username: string, password: string) => Promise<any>
  loginError: string
  loginState: LoadingStatus
  logout: () => void
  refreshToken: string
  reset: (email: string) => Promise<any>
  resetError: string
  resetSend: boolean
  resetState: LoadingStatus
  updateTokens: () => any
}

export const Auth = ({
  accessToken,
  children,
  exhibitorLogin,
  exhibitorReset,
  getNewToken,
  login,
  loginError,
  loginState,
  logout,
  refreshToken,
  reset,
  resetError,
  resetSend,
  resetState,
  updateTokens,
}: IAuthHandlerProps) => {
  const refreshTimeout = useRef<any>(0)
  const updateInterval = useRef<any>(0)
  const [silentRefresh, setSilent] = useState(true)
  const [resetMode, setResetMode] = useState(false)

  // We need this only on init
  useEffect(() => {
    if (refreshToken) {
      getNewToken()
      startRefresh()
      startUpdate()
    }
    setSilent(true)
    // eslint-disable-next-line
  }, [])

  const startUpdate = () => {
    stopUpdate()
    updateInterval.current = setInterval(() => updateTokens(), 2000)
  }

  const stopUpdate = () => {
    clearInterval(updateInterval.current)
  }

  const startRefresh = () => {
    stopRefresh()
    refreshTimeout.current = setTimeout(async () => {
      const tokenItem = getAccessTokenItem()
      if (!tokenItem) {
        stopRefresh()
        logout()
        return
      }

      try {
        await getNewToken()
      } finally {
        startRefresh()
      }
    }, 300000)
  }

  const stopRefresh = () => {
    clearTimeout(refreshTimeout.current)
  }

  const handleLoginSubmit = async (
    { username, password }: ILoginFormValues,
    { setSubmitting }: FormikActions<ILoginFormValues>
  ) => {
    const method = REACT_APP_ROLE === 'regular' ? login : exhibitorLogin
    await method(username, password)
    startRefresh()
    setSubmitting(false)
  }

  const handleResetSubmit = async (
    { email }: IResetFormValues,
    { setSubmitting }: FormikActions<IResetFormValues>
  ) => {
    const method = REACT_APP_ROLE === 'regular' ? reset : exhibitorReset
    await method(email)
    setSubmitting(false)
  }

  if ((loginState === 'success' || silentRefresh) && accessToken) {
    return <>{children}</>
  }

  const requiredMessage = 'Please fill this field'

  const renderItem = () => {
    if (resetMode) {
      return (
        <>
          <Message
            size="tiny"
            color={resetSend ? 'blue' : resetError ? 'red' : 'blue'}
            content={resetSend ? resetText.after : resetError ? resetError : resetText.before}
          />
          {!resetSend && (
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={handleResetSubmit}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required(requiredMessage),
              })}
              render={props => <ResetForm {...props} />}
            />
          )}
          <Divider />
          <Button basic size="mini" onClick={() => setResetMode(false)} color="blue">
            Return to login
          </Button>
        </>
      )
    }
    return (
      <>
        <Divider />
        <Formik
          initialValues={{
            password: '',
            username: '',
          }}
          onSubmit={handleLoginSubmit}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(requiredMessage),
            username: Yup.string().required(requiredMessage),
          })}
          render={props => <LoginForm {...props} />}
        />
        <Divider />
        <Button basic size="mini" color="blue" onClick={() => setResetMode(true)}>
          Reset password
        </Button>
      </>
    )
  }

  return (
    <PageLoading loading={loginState === LoadingStatus.pending}>
      <div className="login-page">
        <div className="login-form">
          <Transition
            visible={loginState === LoadingStatus.error}
            animation="fly down"
            duration={500}
          >
            <Message negative>
              <Message.Header>Login error</Message.Header>
              <p>{loginError || 'Login error'}</p>
            </Message>
          </Transition>
          {!accessToken && renderItem()}
        </div>
      </div>
    </PageLoading>
  )
}
export default observer(({ children }: { children: ReactElement }) => {
  const {
    accessToken,
    exhibitorLogin,
    exhibitorReset,
    getNewToken,
    login,
    loginError,
    loginState,
    logout,
    refreshToken,
    reset,
    resetError,
    resetSend,
    resetState,
    updateTokens,
  } = useRootData(store => store.appStore)

  if (WITH_SAML && !accessToken) {
    return <AuthSaml />
  }

  return (
    <Auth
      {...{
        accessToken,
        exhibitorLogin,
        exhibitorReset,
        getNewToken,
        login,
        loginError,
        loginState,
        logout,
        refreshToken,
        reset,
        resetError,
        resetSend,
        resetState,
        updateTokens,
      }}
    >
      {children}
    </Auth>
  )
})
