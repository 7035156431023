import {
  IAbstractionsContractsQueriesNotificationDataNotificationDataResponse,
  IAbstractionsContractsQueriesNotificationDataNotificationDataResponseNotificationData,
  IApiControllersBackofficeNotificationsModelsCreateNotificationDataModel,
  IApiControllersBackofficeNotificationsModelsUpdateNotificationDataModel,
} from 'api-types'
import { AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { buildQuery, IFilterConfig } from 'utils/filter'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type INotificationsItem = IAbstractionsContractsQueriesNotificationDataNotificationDataResponseNotificationData
export type INotificationsCreate = IApiControllersBackofficeNotificationsModelsCreateNotificationDataModel
export type INotificationsUpdate = IApiControllersBackofficeNotificationsModelsUpdateNotificationDataModel
export type INotificationFull = INotificationsCreate & INotificationsUpdate

export default types
  .model('NotificationsStore', {
    deleteStatus: LoadingStatusType,
    fetchNotificationsStatus: LoadingStatusType,
    notifications: types.frozen<INotificationsItem[]>([]),
    totalPages: types.optional(types.number, 0),
    updateStatus: LoadingStatusType,
  })

  .actions(self => ({
    fetchNotifications: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/public/notifications?${buildQuery(filter)}`
        )) as AxiosResponse<IAbstractionsContractsQueriesNotificationDataNotificationDataResponse>
        self.notifications = data.notificationsData
        self.totalPages = data.totalPages
      }),
      s => (self.fetchNotificationsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch notifications')
    ),
  }))

  .actions(self => {
    return {
      updateNotification: createAxiosActionWithCallback(
        (payload: INotificationsUpdate) => {
          const method = payload.id.length ? axios.put : axios.post
          return method('/backoffice/notifications', payload)
        },
        (s: LoadingStatus) => (self.updateStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to update notification')
      ),

      deleteNotification: createAxiosActionWithCallback(
        (id: string) => axios.delete(`/backoffice/notifications?id=${id}`),
        (s: LoadingStatus) => (self.deleteStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to delete notification')
      ),
    }
  })
