import {
  IAbstractionsContractsQueriesPackagesInvitationPackageByIdResponse,
  IAbstractionsContractsQueriesPackagesPackagesPageResponse,
  IAbstractionsContractsQueriesPackagesPackagesPageResponsePackage,
} from 'api-types'
import _ from 'lodash'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export enum PackageType {
  Paid = 'Paid',
  Free = 'Free',
}

export type IPackageItem = IAbstractionsContractsQueriesPackagesPackagesPageResponsePackage

export type IAttendeePackage = IAbstractionsContractsQueriesPackagesInvitationPackageByIdResponse

export default types
  .model('PackagesStore', {
    fetchPackagesStatus: LoadingStatusType,
    packages: types.frozen<IPackageItem[]>([]),
  })

  .actions(self => ({
    fetchPackages: _.once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get(`/backoffice/registration/package?${allQuery}`)) as {
            data: IAbstractionsContractsQueriesPackagesPackagesPageResponse
          }
          self.packages = data.packages
        }),
        s => (self.fetchPackagesStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch packages')
      )
    ),
  }))
