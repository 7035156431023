/* tslint:disable */
import React, { useState, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Menu, Dropdown, Divider, Header, Button, Sticky } from 'semantic-ui-react'
import { useRootData } from 'stores'
import useWindowDimensions from 'utils/dimensions'
import {
  ISponsorItem,
  ISponsorType,
  ISponsorTier,
  ISponsorTag,
  ISponsorFloor,
  ISaveSponsorItem,
  IUpdateSponsorFloors,
} from 'stores/sponsors'
import { LoadingStatus } from 'utils/store'
import EditSponsorLayout from 'components/edit-sponsor-layout/EditSponsorLayout'
import EditSponsorBasic from 'components/edit-sponsor-basic/EditSponsorBasic'
import EditSponsorNew from 'components/edit-sponsor-new/EditSponsorNew'
import EditSponsorVirtual from 'components/edit-sponsor-virtual/EditSponsorVirtual'
import EditSponsorDocuments from 'components/edit-sponsor-documents/EditSponsorDocuments'
import EditSponsorLinks from 'components/edit-sponsor-links/EditSponsorLinks'
import EditSponsorLogos from 'components/edit-sponsor-logos/EditSponsorLogos'
import EditSponsorRepresentatives from 'components/edit-sponsor-representatives/EditSponsorRepresentatives'
import './EditSponsorForm.css'

const tabKeys = ['Basic', 'Virtual', 'Logos', 'Documents', 'Links', 'Wizard', 'Representatives']

interface ISponsorFormProps {
  actionStatus: LoadingStatus,
  createSponsor: (payload: ISaveSponsorItem) => Promise<any>,
  createMode: boolean,
  item: ISponsorItem,
  sponsorTypes: ISponsorType[],
  sponsorTiers: ISponsorTier[],
  sponsorTags: ISponsorTag[],
  sponsorFloors: ISponsorFloor[],
  fetchSponsor: () => Promise<any>,
  saveSponsor: (payload: ISaveSponsorItem, onDone: () => any) => Promise<any>,
  updateSponsorFloor: (payload: IUpdateSponsorFloors) => any,
}

const SponsorForm = ({
  actionStatus,
  createSponsor,
  createMode,
  item,
  sponsorTypes,
  sponsorTiers,
  sponsorTags,
  sponsorFloors,
  fetchSponsor,
  saveSponsor,
  updateSponsorFloor,
}: ISponsorFormProps) => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search.slice(1))
  const defaultTab = queryParams.get('tab')?.toString()
  const [activeTab, setActiveTab] = useState(defaultTab || 'Basic')
  const handleMenu = (value: string) => {
    setActiveTab(value)
    history.push(`${location.pathname}?${value}`)
  }
  const { isMobile } = useWindowDimensions()

  const tabs = () => (
    <Sticky context={ref} offset={20}>
      <Menu tabular style={{ backgroundColor: '#fff' }}>
        <Menu.Item
          as={Link}
          className="back-button"
          to="/sponsors/list/"
          icon="left chevron"
        />
        {createMode ? (
          <Menu.Item active to="/sponsors/list/">
            Create
          </Menu.Item>
        ): (<Menu.Item className="menu-header">
            <Header as="h3">{createMode ? 'create' : item.company}</Header>
          </Menu.Item>
        )}
        {!createMode &&
          tabKeys.map((tab, index) => (
            <Menu.Item
              as={Link}
              to={`${location.pathname}?tab=${tab}`}
              name={tab}
              key={index}
              onClick={(event) => handleMenu(tab)}
              active={activeTab === tab}
            >
              {tab}
            </Menu.Item>
          ))}
      </Menu>
    </Sticky>
  )

  const menu = () => (
    <Menu tabular borderless style={{ backgroundColor: '#fff' }}>
      <Menu.Item>
        <Button
          as={Link}
          className="back-button"
          to="/sponsors/list/"
          icon="left chevron"
          basic
        />
      </Menu.Item>
      <Menu.Item className="menu-header">
        <Header as="h3">{createMode ? 'create' : item.company}</Header>
      </Menu.Item>
      {!createMode && (
        <Menu.Menu position="right">
          <Dropdown item trigger={activeTab} simple size="big">
            <Dropdown.Menu>
              {tabKeys.map((tab, index) => (
                <Dropdown.Item
                  name={tab}
                  key={index}
                  onClick={() => handleMenu(tab)}
                  active={activeTab === tab}
                >
                  {tab}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      )}
    </Menu>
  )

  const updateSponsor = (payload: ISaveSponsorItem) => {
    saveSponsor(payload, fetchSponsor)
  }

  const renderItem = (activeTab?: string) => {
    switch (activeTab) {
      case 'Create':
        return (
          <EditSponsorNew
            createSponsor={createSponsor}
            item={item}
            sponsorTypes={sponsorTypes}
            sponsorTiers={sponsorTiers}
            sponsorTags={sponsorTags}
            // sponsorFloors={sponsorFloors}
            actionStatus={actionStatus}
          />
        )
      case 'Basic':
        return (
          <EditSponsorBasic
            item={item}
            sponsorTypes={sponsorTypes}
            sponsorTiers={sponsorTiers}
            sponsorTags={sponsorTags}
            saveSponsor={updateSponsor}
            actionStatus={actionStatus}
          />
        )
      case 'Virtual':
        return (
          <EditSponsorVirtual
            item={item}
            sponsorFloors={sponsorFloors}
            saveSponsor={updateSponsor}
            actionStatus={actionStatus}
            updateSponsorFloor={updateSponsorFloor}
          />
        )
      case 'Logos':
        return (
          <EditSponsorLogos
            item={item}
            saveSponsor={updateSponsor}
            actionStatus={actionStatus}
          />
        )
      case 'Documents':
        return <EditSponsorDocuments item={item} />
      case 'Links':
        return <EditSponsorLinks item={item} />
      case 'Wizard':
        return <EditSponsorLayout
          item={item}
          saveSponsor={updateSponsor}
          fetchSponsor={fetchSponsor}
        />
      case 'Representatives':
        return <EditSponsorRepresentatives item={item} />
    }
  }

  return (
    <div className="sponsor" ref={ref}>
      {isMobile ? menu() : tabs()}
      <Divider hidden />
      <>{createMode ? renderItem('Create') : renderItem(activeTab)}</>
    </div>
  )
}

interface IEditSponsorFormProps extends IEditSponsorFormInjectedProps {
  actionStatus: LoadingStatus
  createSponsor: (payload: ISaveSponsorItem) => Promise<any>
  sponsorTypes: ISponsorType[]
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
  sponsorFloors: ISponsorFloor[]
  saveSponsor: (payload: ISaveSponsorItem, onDone: () => any) => Promise<any>
  fetchSponsor: () => Promise<any>
  updateSponsorFloor: (payload: IUpdateSponsorFloors) => any,
}

interface IEditSpeakerValues extends ISponsorItem {
  agendaSelectedSessions: string[]
}

export const EditSponsorForm = ({
  actionStatus,
  createMode,
  createSponsor,
  item,
  sponsorTypes,
  sponsorTiers,
  sponsorTags,
  sponsorFloors,
  saveSponsor,
  fetchSponsor,
  updateSponsorFloor,
}: IEditSponsorFormProps) => {
  return (
    <SponsorForm
      actionStatus={actionStatus}
      createSponsor={createSponsor}
      createMode={createMode}
      item={item}
      sponsorTypes={sponsorTypes}
      sponsorTiers={sponsorTiers}
      sponsorTags={sponsorTags}
      sponsorFloors={sponsorFloors}
      saveSponsor={saveSponsor}
      fetchSponsor={fetchSponsor}
      updateSponsorFloor={updateSponsorFloor}
    />
  )
}

interface IEditSponsorFormInjectedProps {
  createMode: boolean
  item: ISponsorItem
  fetchSponsor: () => Promise<any>
}

export const EditSponsorFormInjected = observer((props: IEditSponsorFormInjectedProps) => {
  const {
    actionStatus,
    createSponsor,
    sponsorTypes,
    sponsorTiers,
    sponsorTags,
    sponsorFloors,
    saveSponsor,
    updateSponsorFloor,
    fetchSponsorsFloors,
  } = useRootData((store) => store.sponsorsStore)
  return (
    <EditSponsorForm
      {...props}
      {...{
        actionStatus,
        createSponsor,
        sponsorTypes,
        sponsorTiers,
        sponsorTags,
        sponsorFloors,
        saveSponsor,
        updateSponsorFloor: async (payload) => {
          await updateSponsorFloor(payload)
          await fetchSponsorsFloors()
        },
      }}
    />
  )
})

export default EditSponsorFormInjected
