import EditSponsorDisplay from 'components/edit-sponsor-display/EditSponsorDisplay'
import EditSponsorFloors from 'components/edit-sponsor-floors/EditSponsorFloors'
import EditSponsorTags from 'components/edit-sponsor-tags/EditSponsorTags'
import EditSponsorTiers from 'components/edit-sponsor-tiers/EditSponsorTiers'
import EditSponsorTypes from 'components/edit-sponsor-types/EditSponsorTypes'
import React, { useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Divider, Dropdown, Menu, Sticky } from 'semantic-ui-react'
import useWindowDimensions from 'utils/dimensions'
import './Sponsors.css'

const tabKeys = ['Sponsorship', 'Tags', 'Tiers', 'Display', 'Floors']

export const SponsorsSettings = () => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search.slice(1))
  const defaultTab = queryParams.get('tab')?.toString()
  const [activeTab, setActiveTab] = useState(defaultTab || 'Sponsorship')
  const { isMobile } = useWindowDimensions()

  const handleMenu = (value: string) => {
    setActiveTab(value)
    history.push(`${location.pathname}?${value}`)
  }

  const tabs = () => (
    <Sticky context={ref} offset={20}>
      <Menu tabular style={{ backgroundColor: '#fff' }}>
        <Menu.Item as={Link} className="back-button" to="/sponsors/list/" icon="left chevron" />
        {tabKeys.map((tab, index) => (
          <Menu.Item
            as={Link}
            to={`${location.pathname}?tab=${tab}`}
            name={tab}
            key={index}
            onClick={event => handleMenu(tab)}
            active={activeTab === tab}
          >
            {tab}
          </Menu.Item>
        ))}
      </Menu>
    </Sticky>
  )

  const menu = () => (
    <Menu tabular borderless style={{ backgroundColor: '#fff' }}>
      <Menu.Item>
        <Button as={Link} className="back-button" to="/sponsors/list/" icon="left chevron" basic />
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown item trigger={activeTab} simple size="big">
          <Dropdown.Menu>
            {tabKeys.map((tab, index) => (
              <Dropdown.Item
                name={tab}
                key={index}
                onClick={() => handleMenu(tab)}
                active={activeTab === tab}
              >
                {tab}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  )

  const renderItem = () => {
    switch (activeTab) {
      case 'Sponsorship':
        return <EditSponsorTypes />
      case 'Tags':
        return <EditSponsorTags />
      case 'Tiers':
        return <EditSponsorTiers />
      case 'Display':
        return <EditSponsorDisplay />
      case 'Floors':
        return <EditSponsorFloors />
    }
  }

  return (
    <div className="sponsors" ref={ref}>
      {isMobile ? menu() : tabs()}
      <Divider hidden />
      {renderItem()}
    </div>
  )
}

export default SponsorsSettings
