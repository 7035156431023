import AttendeeCard from 'components/attendee-card/AttendeeCard'
import { observer } from 'mobx-react-lite'
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Loader,
  Menu,
  Pagination,
  Select,
  Sticky,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IPackageItem } from 'stores/packages'
import { IRegistrationItem, registrationStatusOptions } from 'stores/registrations'
import useWindowDimensions from 'utils/dimensions'
import { encodeKey, IFilterConfig } from 'utils/filter'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'
import useDebounce from 'utils/use-debounce'
import './Registrations.css'

interface IRegistrationsProps {
  attendeesByPage: IRegistrationItem[]
  fetchAttendeesStatus: LoadingStatus
  fetchRegistrations: (filter: IFilterConfig) => Promise<any>
  fetchPackages: () => Promise<any>
  fetchBlackList: () => Promise<any>
  status: LoadingStatus
  packages: IPackageItem[]
  totalPages: number
  resetAttendeesList: () => any
  fetchCompanyCategories: () => Promise<any>
}

type SortType = 'ascending' | 'descending' | undefined

const perPageOptions = [5, 20, 50, 100, 9999].map(item => ({
  key: item,
  text: item === 9999 ? 'All' : item,
  value: item,
}))

const EmptyResult = (loader: boolean) => (
  <Table.Row>
    <Table.Cell colSpan={6} textAlign="center">
      {loader ? <Loader /> : 'Nothing found'}
    </Table.Cell>
  </Table.Row>
)

export const Registrations = ({
  attendeesByPage,
  fetchAttendeesStatus,
  status,
  packages,
  totalPages,
  resetAttendeesList,
  fetchBlackList,
  fetchPackages,
  fetchRegistrations,
  fetchCompanyCategories,
}: IRegistrationsProps) => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()

  const [visibleActionsFor, setVisibleActionsFor] = useState('')

  const [searchValue, setSearchValue] = useState(location.search.split('=')[1] || '')
  const [emailValue, setEmailValue] = useState('')
  const [registrationValue, setRegistrationValue] = useState('')
  const [packageValue, setPackageValue] = useState('')

  const [showManage, setShowManage] = useState(false)
  const [showFilters, setShowFilters] = useState(searchValue.length > 0)
  const [page, setPage] = useState(1)
  const [itemsOnPage, setItemsOnPage] = useState(20)
  const [sortBy, setSortBy] = useState('FirstName')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')

  const debouncedSearch = useDebounce(searchValue, 500)
  const debouncedEmail = useDebounce(emailValue, 500)

  const handleInput = useCallback((event: FormEvent<HTMLInputElement>, type: string) => {
    const { value } = event.currentTarget
    if (type === 'search') {
      setSearchValue(value)
      history.push(`${location.pathname}${value.length ? `?search=${value}` : ''}`)
    }
    if (type === 'email') {
      setEmailValue(value)
    }
    // eslint-disable-next-line
  }, [])

  const handleClear = useCallback((type: string) => {
    if (type === 'search') {
      setSearchValue('')
      history.push(location.pathname)
    }
    if (type === 'email') {
      setEmailValue('')
    }
    // eslint-disable-next-line
  }, [])

  const { isMobile } = useWindowDimensions()

  const filters = useMemo(
    () =>
      ({
        filter: {
          [encodeKey('Package.Id')]: {
            operator: 'Equals',
            options: [],
            title: 'Package',
            value: packageValue,
          },
          multipleValue: {
            operator: 'QueryParam',
            title: 'Mixed search',
            value: debouncedSearch,
          },
          Email: {
            operator: 'Like',
            title: 'Email',
            value: debouncedEmail,
          },
          RegistrationStatus: {
            operator: 'Equals',
            options: [],
            title: 'Registration status',
            value: registrationValue,
          },
        },
        itemsOnPage,
        page: page - 1,
        sort: {
          desc: sortDirection === 'descending',
          field: sortBy,
        },
      } as IFilterConfig),
    [
      itemsOnPage,
      page,
      sortBy,
      sortDirection,
      debouncedSearch,
      debouncedEmail,
      registrationValue,
      packageValue,
    ]
  )

  const fetchPage = () => fetchRegistrations(filters)

  useEffect(() => {
    fetchPage()
    // eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    fetchCompanyCategories()
    fetchPackages()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    resetAttendeesList()
    fetchBlackList()
  }, [resetAttendeesList, fetchBlackList])

  useEffect(() => {
    if (visibleActionsFor) {
      setTimeout(() => {
        const active = document.querySelector('.active-card')
        if (active) {
          active.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }, 300)
    }
  }, [visibleActionsFor])

  const onShowActions = useCallback((id: string) => setVisibleActionsFor(id), [])
  const onHideActions = useCallback(() => setVisibleActionsFor(''), [])

  const packagesOptions = useMemo(
    () =>
      packages.map(e => ({
        key: e.id,
        text: e.name,
        value: e.id,
      })),
    [packages]
  )

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const loadedAttendees = fetchAttendeesStatus === LoadingStatus.success

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedAttendees
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedAttendees, setPage]
  )

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    switch (data.name) {
      case 'status':
        setRegistrationValue(data.value as string)
        break
      case 'package':
        setPackageValue(data.value as string)
        break
    }
  }

  const handleSort = (newSort: string) => {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
    setSortBy(newSort)
  }

  const tabs = () => (
    <Sticky context={ref} offset={isMobile ? 0 : 20}>
      <Menu tabular style={{ backgroundColor: '#fff', paddingBottom: 20 }} borderless>
        <Menu.Item position="right" style={{ padding: '0 13px 0 0' }}>
          <Dropdown
            compact
            inline
            trigger={`${itemsOnPage === 9999 ? 'All rows' : `${itemsOnPage} rows per page`}`}
            pointing="top left"
            options={perPageOptions}
            defaultValue={itemsOnPage}
            onChange={(event, { value }) => setItemsOnPage(value as number)}
            style={{ paddingRight: 20 }}
          />
          <Checkbox
            toggle
            checked={showFilters}
            onChange={() => setShowFilters(!showFilters)}
            label="Filters"
          />
        </Menu.Item>
      </Menu>
    </Sticky>
  )

  const renderItem = (item: IRegistrationItem) => (
    <AttendeeCard
      key={item.id}
      visibleActions={visibleActionsFor === item.id}
      manage={showManage}
      item={item}
      onShowActions={onShowActions}
      onHideActions={onHideActions}
      reloadPage={fetchPage}
    />
  )

  if (fetchAttendeesStatus === LoadingStatus.not_loaded) {
    return null
  }

  return (
    <div className="registrations" ref={ref}>
      {tabs()}

      <Table size="small" sortable basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortBy === 'FirstName' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('FirstName')}
              width={3}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Email' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('Email')}
              width={4}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'RegistrationStatus' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('RegistrationStatus')}
              width={3}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Package.Id' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('Package.Id')}
              width={4}
            >
              Package
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2} width={2} textAlign="right">
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
          {showFilters ? (
            <Table.Row>
              <Table.Cell>
                <Input
                  fluid
                  icon={
                    searchValue.length ? (
                      <Icon
                        name="close"
                        bordered
                        circular
                        link
                        onClick={() => handleClear('search')}
                        style={{ boxShadow: 'none' }}
                      />
                    ) : null
                  }
                  value={searchValue}
                  placeholder="Name"
                  onChange={(event: any) => handleInput(event, 'search')}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  fluid
                  icon={
                    emailValue.length ? (
                      <Icon
                        name="close"
                        bordered
                        circular
                        link
                        onClick={() => handleClear('email')}
                        style={{ boxShadow: 'none' }}
                      />
                    ) : null
                  }
                  value={emailValue}
                  placeholder="Name"
                  onChange={(event: any) => handleInput(event, 'email')}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  clearable
                  name="status"
                  placeholder="Status"
                  options={registrationStatusOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  clearable
                  name="package"
                  placeholder="Tags"
                  options={packagesOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell colSpan={2} />
            </Table.Row>
          ) : null}
        </Table.Header>
        <Table.Body>
          {attendeesByPage.length > 0
            ? attendeesByPage.map(item => renderItem(item))
            : EmptyResult(fetchAttendeesStatus === LoadingStatus.pending)}
        </Table.Body>
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={6} textAlign="center">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={false}
                  lastItem={false}
                  prevItem={false}
                  nextItem={false}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  )
}

export default observer(() => {
  const {
    actionStatus,
    attendeeTypes,
    attendeesByPage,
    fetchAttendeesStatus,
    fetchRegistrations,
    registrationStatuses,
    totalPages,
    resetAttendeesList,
  } = useRootData(store => store.registrationsStore)

  const { fetchBlackList, fetchBlackListStatus } = useRootData(store => store.blacklistStore)
  const { fetchCompanyCategories } = useRootData(store => store.companiesStore)
  const { fetchPackages, packages } = useRootData(store => store.packagesStore)
  const status = combineLoadingStatus([actionStatus, fetchBlackListStatus])

  return (
    <Registrations
      {...{
        attendeesByPage,
        attendeeTypes,
        fetchAttendeesStatus,
        fetchBlackList,
        fetchCompanyCategories,
        fetchPackages,
        fetchRegistrations,
        packages,
        registrationStatuses,
        resetAttendeesList,
        status,
        totalPages,
      }}
    />
  )
})
