import { RadioGroup, SelectInput, TextInput, TextRow } from 'components/inputs'
import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { Form as FormikForm, Formik, FormikActions, FormikProps, getIn } from 'formik'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Button, Divider, Form, Grid } from 'semantic-ui-react'
import { ICompanyCategoryItem } from 'stores/companies'
import { IForbiddenEmailsItem } from 'stores/forbiddenEmails'
import { IRegistrationItem } from 'stores/registrations'
import { countries } from 'utils/countries'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import * as Yup from 'yup'

interface IError {
  [key: string]: string
}

const countriesOptions = countries.map(c => ({
  key: c.code,
  text: c.name,
  value: c.code,
}))

interface IEditFormValues extends IRegistrationItem {
  selectedPackageId: string | null
  messagesLimit: number
}

interface IEditAttendeeBasicProps {
  actionStatus: LoadingStatus
  item: IEditFormValues
  companyCategories: ICompanyCategoryItem[]
  forbiddenEmails: IForbiddenEmailsItem[]
  onSubmit: (payload: any) => any
}

export const EditAttendeeBasic = ({
  item,
  companyCategories,
  onSubmit,
  actionStatus,
  forbiddenEmails,
}: IEditAttendeeBasicProps) => {
  const { isMobile } = useWindowDimensions()

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const forbiddenDomains = forbiddenEmails.map(email => email.domain)

  const companyCategoriesOptions = useMemo(
    () =>
      companyCategories.map(e => ({
        key: e.id,
        text: e.name,
        value: e.id,
      })),
    [companyCategories]
  )

  const handleSubmit = useCallback(
    async (
      values: IEditFormValues,
      { resetForm }: Partial<FormikActions<IEditFormValues>>,
      { isValid }: Partial<FormikProps<IEditFormValues>>
    ) => {
      if (isValid) {
        await onSubmit(values)
        if (resetForm) {
          resetForm(values)
        }
      }
    },
    [onSubmit]
  )

  const handleReset = async (
    values: IEditFormValues,
    { resetForm }: Partial<FormikActions<IEditFormValues>>
  ) => {
    if (resetForm) {
      resetForm(values)
    }
  }

  const requiredMessage = 'Please fill this field'

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {}}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required(requiredMessage)
          .test('match', 'Domain forbidden, please use only allowed', email => {
            const domain = email ? email.split('@').pop() : null
            return !forbiddenDomains.includes(domain as string)
          }),
        phoneNumber: Yup.string()
          .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/, 'Phone number is not valid')
          .required(requiredMessage),
      })}
      render={props => {
        const {
          errors,
          values,
          touched,
          setFieldValue,
          setFieldTouched,
          resetForm
        } = props
        const isValid = !Object.keys(errors).length && Object.keys(touched).length > 0
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Personal</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={4} tablet={4} computer={4} verticalAlign="middle">
                  <SelectImageInput
                    width={200}
                    height={200}
                    title="Avatar"
                    name="imageUrl"
                    onChange={event => {
                      setFieldValue('imageUrl', event)
                      setFieldTouched('imageUrl')
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={12} tablet={12} computer={12} verticalAlign="middle">
                  <Grid stretched stackable>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="First name"
                          name="firstName"
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Last name"
                          name="lastName"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Email"
                          name="email"
                          error={
                            errors.email && {
                              content: errors.email as any,
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput type="text" title="Birth year" name="birthYear" />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput title="VAT ID" name="vatId" />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <RadioGroup
                          name="gender"
                          title="Gender"
                          options={[{ value: 'Mr.' }, { value: 'Ms.' }]}
                          value={values.gender}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Contact Information</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectInput
                    search
                    name="countryCode"
                    title="Country"
                    options={countriesOptions}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput
                    title="Phone"
                    name="phoneNumber"
                    error={
                      getIn(errors, 'phoneNumber') && {
                        content: getIn(errors, 'phoneNumber'),
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="Address"
                    name="address"
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="City"
                    name="city"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Company Information</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="Company"
                    name="company"
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="Job Title"
                    name="jobTitle"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <TextRow
                    title="Badge category"
                    value={(values.category || {}).badgeDisplayName}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="Corporate adress"
                    name="corporateAddress"
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <TextInput
                    title="Corporate website"
                    name="corporateWebSite"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <SelectInput
                    search
                    title="Category"
                    name="categoryId"
                    options={companyCategoriesOptions}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <TextRow
                    title="Category type"
                    value={(values.category || {}).type}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm }, { isValid })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!isValid}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => handleReset(initialValues, { resetForm })}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditAttendeeBasic
