import { SelectInput, TextInput, TextRow } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { Button, Form, Grid } from 'semantic-ui-react'
import { IPackageItem } from 'stores/packages'
import { IRegistrationItem } from 'stores/registrations'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'

interface IEditFormValues extends IRegistrationItem {
  selectedPackageId: string | null
  messagesLimit: number
}

interface IEditAttendeePackageProps {
  actionStatus: LoadingStatus
  item: IEditFormValues
  packages: IPackageItem[]
  onSubmit: (payload: any) => any
}

const getCurrentPackage = (v: IEditFormValues, packages: IPackageItem[]) =>
  packages.find(p => p.id === v.selectedPackageId) || v.package || v.invitationPackage

export const EditAttendeePackage = ({
  item,
  packages,
  onSubmit,
  actionStatus,
}: IEditAttendeePackageProps) => {
  const { isMobile } = useWindowDimensions()

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const packagesOptions = useMemo(
    () =>
      packages.map(e => ({
        key: e.id,
        text: e.name,
        value: e.id,
      })),
    [packages]
  )

  const handleSubmit = async (
    values: IEditFormValues,
    { resetForm }: Partial<FormikActions<IEditFormValues>>
  ) => {
    await onSubmit(values)
    if (resetForm) {
      resetForm(values)
    }
  }

  const handleReset = async (
    values: IEditFormValues,
    { resetForm }: Partial<FormikActions<IEditFormValues>>
  ) => {
    if (resetForm) {
      resetForm(values)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
      render={props => {
        const { values, touched, setFieldTouched, setFieldValue, resetForm } = props
        const currentPackage = getCurrentPackage(values, packages)
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectInput
                    search
                    clearable
                    title="Select new"
                    name="selectedPackageId"
                    options={packagesOptions}
                    onChange={v => {
                      const pack = packages.find(p => p.id === v)
                      if (pack) {
                        setFieldValue('messagesLimit', pack.messagesLimit)
                        setFieldTouched('messagesLimit')
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              {currentPackage && (
                <>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <TextRow title="Name" value={currentPackage.name} />
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <TextRow title="Status" value={currentPackage.status} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8} verticalAlign="middle">
                      <TextRow title="Creation date" value={currentPackage.creationDate} />
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <TextRow title="Price" value={currentPackage.price} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TextRow title="Type" value={currentPackage.type} />
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      {currentPackage.id && (
                        <TextInput title="Message limit" name="messagesLimit" />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => handleReset(initialValues, { resetForm })}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditAttendeePackage
