import { FastField, FieldProps } from 'formik'
import React, { memo } from 'react'
import { Form, StrictFormCheckboxProps } from 'semantic-ui-react'

export interface ICheckboxGroupPublicProps {
  title?: string
  name: string
  options: Array<{
    title?: string
    value: string
  }>
  [key: string]: any // change this to Form.Group props
}

const CheckboxButton = ({ field, form, ...props }: FieldProps & StrictFormCheckboxProps) => {
  if (!field.value || !Array.isArray(field.value)) {
    console.warn('[INPUT WARN] Default value should be array for CheckboxGroup:', field.name)
  }

  return (
    <Form.Checkbox
      {...field}
      {...props}
      onChange={() => {
        const currentValue = form.values[field.name] as any[]
        const newValue =
          currentValue.indexOf(props.value) > -1
            ? currentValue.filter(e => e !== props.value)
            : [...currentValue, props.value]
        form.setFieldValue(field.name, newValue)
      }}
      checked={field.value && field.value.length > 0 && field.value.indexOf(props.value) > -1}
    />
  )
}

export const CheckboxGroup = ({ title, name, options, ...props }: ICheckboxGroupPublicProps) => (
  <Form.Group {...props} grouped>
    {title && <label>{title}</label>}
    {options.map(({ title: optionTitle, value }, i) => (
      <FastField
        key={i}
        component={CheckboxButton}
        name={name}
        value={value}
        label={optionTitle || value}
      />
    ))}
  </Form.Group>
)

export default memo(CheckboxGroup)
