import React, { useCallback, useEffect, useMemo, useState, memo } from 'react'
import { SelectInput, TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions, FormikProps } from 'formik'
import { useToasts } from 'react-toast-notifications'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Button, Confirm, Form, Header, Message, Modal, Table } from 'semantic-ui-react'
import { useRootData } from 'stores'
import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { IInvitaionItem, ISponsorAttendee, ISponsorItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import { countries } from 'utils/countries'
import { languages } from 'utils/languages'

import * as Yup from 'yup'

const initialValues = {
  chatRole: 'administrator',
  country: '',
  countryCode: '',
  email: '',
  firstName: '',
  imageUrl: '',
  lastName: '',
  localizations: [],
  phoneNumber: '',
  position: '',
  vipInvitationId: '',
} as ISponsorAttendee

interface IDocumentFormProps extends FormikProps<ISponsorAttendee> {
  item: ISponsorItem
}

type SortType = 'ascending' | 'descending' | undefined

const countriesOptions = countries.map(c => ({
  key: c.code,
  text: c.name,
  value: c.name,
}))

const languagesOptions = languages.map(c => ({
  key: c.code,
  text: c.name,
  value: c.code,
}))

const chatOptions = [
  {
    key: 'supportManager',
    text: 'Support Manager',
    value: 'member',
  },
  {
    key: 'channel_administrator',
    text: 'Channel Administrator',
    value: 'administrator',
  },
]

const CreateForm = memo(({ item, ...props }: IDocumentFormProps) => {
  const { errors, values, setFieldValue, setFieldTouched } = props

  return (
    <Form as={FormikForm} autoComplete="off">
      <TextInput
        title="First name"
        name="firstName"
        error={
          errors.firstName && {
            content: errors.firstName as string,
            pointing: 'below',
          }
        }
      />
      <TextInput
        title="Last name"
        name="lastName"
        error={
          errors.lastName && {
            content: errors.lastName as string,
            pointing: 'below',
          }
        }
      />
      <TextInput
        title="Email"
        name="email"
        error={
          errors.email && {
            content: errors.email as string,
            pointing: 'below',
          }
        }
      />
      <TextInput title="Position" name="position" />
      <TextInput
        title="Phone number"
        name="phoneNumber"
        error={
          errors.phoneNumber && {
            content: errors.phoneNumber as string,
            pointing: 'below',
          }
        }
      />
      <SelectInput
        name="country"
        title="Country"
        options={countriesOptions}
        search
        selection
        error={
          errors.country && {
            content: errors.country as string,
            pointing: 'below',
          }
        }
      />
      <SelectInput
        multiple
        name="localizations"
        title="Languages"
        options={languagesOptions}
        search
        selection
        value={values.localizations || []}
        error={
          errors.localizations && {
            content: errors.localizations as string[],
            pointing: 'below',
          }
        }
      />
      <SelectInput name="chatRole" title="Chat Role" options={chatOptions} />
      <SelectImageInput
        width={200}
        height={100}
        title="Image"
        name="imageUrl"
        onChange={event => {
          setFieldValue('imageUrl', event)
          setFieldTouched('imageUrl')
        }}
      />
    </Form>
  )
})

interface IDocumentEditFormProps extends FormikProps<ISponsorAttendee> {
  item: ISponsorItem
}

const EditForm = memo(({ item, ...props }: IDocumentEditFormProps) => {
  const { errors, values, setFieldValue, setFieldTouched } = props
  return (
    <Form as={FormikForm} autoComplete="off">
      <TextInput
        title="First name"
        name="firstName"
        error={
          errors.firstName && {
            content: errors.firstName as string,
            pointing: 'below',
          }
        }
      />
      <TextInput
        title="Last name"
        name="lastName"
        error={
          errors.lastName && {
            content: errors.lastName as string,
            pointing: 'below',
          }
        }
      />
      <TextInput
        title="Email"
        name="email"
        error={
          errors.email && {
            content: errors.email as string,
            pointing: 'below',
          }
        }
      />
      <TextInput title="Position" name="position" />
      <TextInput
        title="Phone number"
        name="phoneNumber"
        error={
          errors.phoneNumber && {
            content: errors.phoneNumber as string,
            pointing: 'below',
          }
        }
      />
      <SelectInput
        name="country"
        title="Country"
        options={countriesOptions}
        search
        selection
        error={
          errors.country && {
            content: errors.country as string,
            pointing: 'below',
          }
        }
      />
      <SelectInput
        multiple
        name="localizations"
        title="Languages"
        options={languagesOptions}
        search
        selection
        value={values.localizations || []}
        error={
          errors.localizations && {
            content: errors.localizations as string[],
            pointing: 'below',
          }
        }
      />
      <SelectInput name="chatRole" title="Chat Role" options={chatOptions} />
      <SelectImageInput
        width={200}
        height={100}
        title="Image"
        name="imageUrl"
        onChange={event => {
          setFieldValue('imageUrl', event)
          setFieldTouched('imageUrl')
        }}
      />
    </Form>
  )
})

interface IEditRepresentativesProps extends IEditRepresentativesFormInjectedProps {
  actionStatus: LoadingStatus
  attendees: ISponsorAttendee[]
  checkEmailExists: (email: string) => Promise<any>
  deleteAttendee: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
  fetchAttendees: (id: string) => Promise<any>
  fetchContentStatus: string
  saveAttendee: (payload: ISponsorAttendee, onDone: () => any) => Promise<any>
  updateAttendee: (payload: ISponsorAttendee, onDone: () => any) => Promise<any>
  resendPasswordLink: (email: string) => Promise<any>
  createInvitation: (payload: IInvitaionItem, onDone: () => any) => Promise<any>
}

export const EditRepresentatives = ({
  actionStatus,
  attendees,
  checkEmailExists,
  deleteAttendee,
  fetchAttendees,
  fetchContentStatus,
  item,
  saveAttendee,
  updateAttendee,
  resendPasswordLink,
  createInvitation,
}: IEditRepresentativesProps) => {
  const { slug } = useParams()

  const [showModal, setShowModal] = useState(false)
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [entryToEdit, setEntryToEdit] = useState<ISponsorAttendee>()
  const [sortBy, setSortBy] = useState('lastName')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')
  const [resetEmail, setResetEmail] = useState('')
  const { addToast } = useToasts()

  const toggleManage = useCallback(() => setShowManage(!showManage), [showManage, setShowManage])

  const editMode = entryToEdit !== undefined

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (slug !== 'create' && item.vipInvitationId) {
      fetchAttendees(item.vipInvitationId)
    }
  }, [fetchAttendees, item, slug])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const handleDelete = async (entry: any) => {
    await deleteAttendee({ id: entry.id }, () => fetchAttendees(item.vipInvitationId))
    addToast('Representative deleted', {
      appearance: 'error',
      autoDismiss: true,
    })
  }

  const handleSort = (newSort: string) => {
    setSortBy(newSort)
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const handleSubmit = useCallback(
    async (
      values: ISponsorAttendee,
      { resetForm }: Partial<FormikActions<ISponsorAttendee>>,
      { isValid }: Partial<FormikProps<ISponsorAttendee>>
    ) => {
      const payload = { ...values, vipInvitationId: item.vipInvitationId }
      if (editMode) {
        await updateAttendee(payload, () => fetchAttendees(item.vipInvitationId))
      } else {
        await saveAttendee(payload, () => fetchAttendees(item.vipInvitationId))
      }
      setShowModal(false)
      setEntryToEdit(undefined)
      addToast(`Representative successfully ${editMode ? 'updated' : 'created'}`, {
        appearance: 'info',
        autoDismiss: true,
      })
      if (resetForm) {
        resetForm()
      }
    },
    [saveAttendee, updateAttendee, editMode, fetchAttendees, item, addToast]
  )

  const handleResend = async (email: string) => {
    setResetEmail(email)
    await resendPasswordLink(email)
    setResetEmail('')
    addToast(`Resend link was sent to ${email}`, {
      appearance: 'info',
      autoDismiss: true,
    })
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    setEntryToEdit(undefined)
    toggleModal()
  }

  const displayContent = (values: any) => {
    const result = _.orderBy(values, sortBy, sortDirection === 'ascending' ? 'asc' : 'desc')
    return result
  }

  const handleInvitation = async () => {
    const payload = {
      sponsorIds: [item.id],
    }
    await createInvitation(payload, () => fetchAttendees(item.id))
  }

  const { isMobile } = useWindowDimensions()

  const requiredMessage = 'Please fill this field'

  if (!item.vipInvitationId) {
    return (
      <Message style={{ textAlign: 'center' }}>
        <p>Representatives will become available after sending an invitation</p>
        <p>
          <Button
            primary
            onClick={handleInvitation}
            content="Create with default settings"
            loading={actionStatus === LoadingStatus.pending}
          />
        </p>
      </Message>
    )
  }

  return (
    <>
      <Table size="small" singleLine sortable columns={5} basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'firstName' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('firstName')}
            >
              First Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'lastName' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('lastName')}
            >
              Last Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={3}
              sorted={sortBy === 'chatRole' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('chatRole')}
            >
              Chat Role
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={toggleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {displayContent(attendees).map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h4">{entry.firstName}</Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">{entry.lastName}</Header>
              </Table.Cell>
              <Table.Cell>{entry.chatRole}</Table.Cell>
              <Table.Cell>
                <Button
                  basic
                  floated="left"
                  fluid={isMobile}
                  loading={resetEmail === entry.email}
                  onClick={() => handleResend(entry.email)}
                  type="button"
                  disabled={resetEmail === entry.email}
                  content="Resend reset password link"
                />
              </Table.Cell>
              <Table.Cell textAlign="right" width={1}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => {
                      setEntryToEdit(entry)
                      toggleModal()
                    }}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          country: Yup.string().required(requiredMessage),
          email: Yup.string()
            .email()
            .test('match', 'Email already used', async email => {
              if (email === fullValues.email) {
                return true
              }
              const result = await checkEmailExists(email as string)
              if (result !== undefined) {
                return result
              }
              return true
            })
            .max(50)
            .required(requiredMessage),
          firstName: Yup.string()
            .max(30)
            .trim()
            .required(requiredMessage),
          lastName: Yup.string()
            .max(30)
            .trim()
            .required(requiredMessage),
          localizations: Yup.array()
            .of(Yup.string().min(1))
            .required(requiredMessage),
          phoneNumber: Yup.string()
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/, 'Phone number is not valid')
            .max(30)
            .required(requiredMessage),
        })}
        onSubmit={() => {}}
        render={props => {
          const { errors, values, touched, setSubmitting, resetForm } = props
          const isValid = !Object.keys(errors).length && Object.keys(touched).length > 0
          return (
            <>
              <Modal
                centered={false}
                dimmer="inverted"
                size="tiny"
                trigger={
                  <Button
                    primary
                    basic
                    onClick={() => {
                      toggleModal()
                      setEntryToEdit(undefined)
                    }}
                  >
                    Add Representative
                  </Button>
                }
                open={showModal}
                onClose={() => {
                  if (Object.keys(touched).length) {
                    setShowConfirm(true)
                  } else {
                    toggleModal()
                    setEntryToEdit(undefined)
                  }
                }}
                closeOnDimmerClick={true}
              >
                <Modal.Header>{`${editMode ? 'Edit' : 'Add'}`} Representative</Modal.Header>
                <Modal.Content>
                  {editMode ? (
                    <EditForm item={item} {...props} />
                  ) : (
                    <CreateForm item={item} {...props} />
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    secondary
                    fluid={isMobile}
                    type="button"
                    onClick={() => {
                      Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                    }}
                    content="Cancel"
                  />
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    loading={actionStatus === LoadingStatus.pending}
                    onClick={() => handleSubmit(values, { resetForm, setSubmitting }, { isValid })}
                    content="Save"
                    disabled={!isValid}
                  />
                </Modal.Actions>
              </Modal>
              <Confirm
                size="mini"
                open={showConfirm}
                onCancel={event => setShowConfirm(false)}
                onConfirm={() => {
                  handleConfirm()
                  resetForm()
                }}
                content="Discard changes?"
              />
            </>
          )
        }}
      />
    </>
  )
}

interface IEditRepresentativesFormInjectedProps {
  item: ISponsorItem
}

export const EditRepresentativesFormInjected = observer(
  (props: IEditRepresentativesFormInjectedProps) => {
    const {
      actionStatus,
      attendees,
      checkEmailExists,
      createInvitation,
      deleteAttendee,
      fetchAttendees,
      fetchContentStatus,
      resendPasswordLink,
      saveAttendee,
      updateAttendee,
    } = useRootData(store => store.sponsorsStore)
    const { fetchSettings, settings } = useRootData(store => store.settingsStore)
    return (
      <EditRepresentatives
        {...props}
        {...{
          actionStatus,
          attendees,
          checkEmailExists,
          createInvitation,
          deleteAttendee,
          fetchAttendees,
          fetchContentStatus,
          fetchSettings,
          resendPasswordLink,
          saveAttendee,
          settings,
          updateAttendee,
        }}
      />
    )
  }
)

export default EditRepresentativesFormInjected
