import { FastField, FieldProps } from 'formik'
import React, { memo } from 'react'
import { TextArea } from 'semantic-ui-react'

export interface ITextAreaPropsPublicProps {
  title: string
  name: string
  [key: string]: any
}

export type ITextAreaProps = FieldProps & ITextAreaPropsPublicProps

export const OwnTextArea = ({ field, title, ...props }: ITextAreaProps) => (
  <div className="field">
    <label>{title}</label>
    <TextArea
      {...field}
      {...props}
      style={{ height: props.height }}
      value={field.value || ''}
      placeholder={title}
    />
  </div>
)

export default memo(({ ...props }: ITextAreaPropsPublicProps) => (
  <FastField component={OwnTextArea} {...props} />
))
