import _ from 'lodash'
import React from 'react'
import { Button, Grid, Icon } from 'semantic-ui-react'

interface IPaginationProps {
  currentPage: number
  pagesCount: number
  onPageSelect: (page: number) => any
}

export const Pagination = ({ currentPage, pagesCount, onPageSelect }: IPaginationProps) => (
  <Grid textAlign="center" padded>
    <Grid.Column verticalAlign="middle">
      <Button.Group basic>
        {currentPage > 1 && (
          <Button icon type="button" onClick={() => onPageSelect(currentPage - 1)}>
            <Icon name="chevron left" />
          </Button>
        )}
        {currentPage > 1 && (
          <Button type="button" onClick={() => onPageSelect(1)}>
            1
          </Button>
        )}
        <Button type="button" active>
          {currentPage}
        </Button>
        {currentPage < pagesCount &&
          _.range(currentPage + 1, Math.min(pagesCount, currentPage + 3)).map(p => (
            <Button type="button" key={p} onClick={() => onPageSelect(p)}>
              {p}
            </Button>
          ))}
        {currentPage < pagesCount && (
          <Button type="button" onClick={() => onPageSelect(pagesCount)}>
            {pagesCount}
          </Button>
        )}
        {currentPage < pagesCount && (
          <Button icon type="button" onClick={() => onPageSelect(currentPage + 1)}>
            <Icon name="chevron right" />
          </Button>
        )}
      </Button.Group>
    </Grid.Column>
  </Grid>
)

export default Pagination
