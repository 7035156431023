import {
  IAbstractionsContractsQueriesEmailTemplatesEmailTemplatesResponse,
  IAbstractionsContractsQueriesEmailTemplatesEmailTemplatesResponseEmailTemplate,
} from 'api-types'
import _ from 'lodash'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export type IEmailTemplateItem = IAbstractionsContractsQueriesEmailTemplatesEmailTemplatesResponseEmailTemplate

export const emailTemplatesToOptions = (items: IEmailTemplateItem[]) => {
  return items.map(item => ({
    key: item.id,
    text: item.templateKey,
    value: item.templateKey,
  }))
}

export default types
  .model('EmailTemplatesStore', {
    items: types.frozen<IEmailTemplateItem[]>([]),
    status: LoadingStatusType,
  })
  .views(self => ({
    getEmailTemplatesByCategory(category: string) {
      return self.items.filter(item => item.category === category)
    },
  }))
  .actions(self => ({
    fetchEmailTemplates: _.once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get(
            `/backoffice/general-settings/email-templates?${allQuery}`
          )) as { data: IAbstractionsContractsQueriesEmailTemplatesEmailTemplatesResponse }
          self.items = data.emailTemplates
        }),
        s => (self.status = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch email templates')
      )
    ),
  }))
