import { IFormValues } from 'components/edit-sponsor-layout/EditSponsorLayout'
import { FormikProps } from 'formik'
import React, { useMemo, useState } from 'react'
import { ChromePicker } from 'react-color'
import OutsideClickHandler from 'react-outside-click-handler'
import { Grid, Input } from 'semantic-ui-react'
import tc from 'tinycolor2'

interface IColorItemProps extends FormikProps<IFormValues> {
  initialValues: IFormValues
}

interface IColorProp {
  h: number
  s: number
  l: number
}

export const EditSponsorColor = ({
  values,
  touched,
  resetForm,
  initialValues,
  setFieldValue,
  setFieldTouched,
  setSubmitting,
}: IColorItemProps) => {
  const [picker, showPicker] = useState(-1)
  const colorValues = useMemo(
    () =>
      values.layers.map((layer, index) => {
        let rgba = layer.color
          ? layer.color
          : index > 0
          ? (tc('#313130').toRgb() as any)
          : (tc('#00f8fe').toRgb() as any)
        rgba = tc(rgba).setAlpha(layer.opacity || 1)
        layer.color = rgba.toHex8()
        return layer
      }),
    [values]
  )
  // console.log('colorValues', colorValues)

  return (
    <>
      <Grid stackable centered columns="equal" className="layout-pickers">
        <Grid.Row>
          {colorValues.map((layer, index) => {
            return (
              <Grid.Column key={index} width={3}>
                <Input
                  label="#"
                  title="Color"
                  name={`layers[${index}].color`}
                  width={4}
                  fluid
                  readOnly
                  value={tc(layer.color).toHex()}
                  action={
                    <div
                      className="layout-color"
                      style={{ backgroundColor: tc(layer.color).toRgbString() }}
                      onClick={(event) => showPicker(index)}
                    />
                  }
                />
                {picker === index ? (
                  <OutsideClickHandler onOutsideClick={(event) => showPicker(-1)}>
                    <ChromePicker
                      color={layer.color}
                      disableAlpha={true}
                      onChangeComplete={(event: any) => {
                        setFieldValue(`layers[${index}].color`, tc(event.rgb).toHexString())
                        setFieldTouched(`layers[${index}].color`)
                      }}
                    />
                  </OutsideClickHandler>
                ) : null}
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
    </>
  )
}
