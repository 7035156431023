import { ISelectOption, SelectInput, TextInput } from 'components/inputs'
import _ from 'lodash'
import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { Field, Form as FormikForm, Formik, FormikActions, FormikProps, getIn } from 'formik'
import React, { useCallback, useMemo } from 'react'
import ReactQuill from 'react-quill'
import { Button, Divider, Form, Grid } from 'semantic-ui-react'
import { ISponsorItem, ISponsorTag } from 'stores/sponsors'
import { countriesOptions } from 'utils/countries'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import * as Yup from 'yup'

interface IEditFormProps {
  actionStatus: LoadingStatus
  item: ISponsorItem
  sponsorTags: ISponsorTag[]
  saveExhibitor: (payload: any) => any
}

export const EditBasic = ({ actionStatus, item, sponsorTags, saveExhibitor }: IEditFormProps) => {
  const { isMobile } = useWindowDimensions()

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const sponsorTagsOptions = useMemo(
    () =>
      sponsorTags.map(tag => ({
        key: tag.id,
        text: tag.displayName,
        value: tag.id,
      })),
    [sponsorTags]
  )

  const filteredOptions = (options: ISelectOption[], selectedOptions: any) => {
    if (selectedOptions && selectedOptions.length) {
      return options.filter((option: ISelectOption) => selectedOptions.includes(option.value))
    }
    return options
  }

  const handleSubmit = useCallback(
    async (
      values: ISponsorItem,
      { resetForm }: Partial<FormikActions<ISponsorItem>>,
      { isValid }: Partial<FormikProps<ISponsorItem>>
    ) => {
      if (isValid) {
        const { tags, ...rest } = values
        const payload = {
          tagsIds: tags,
          ...rest,
        }
        saveExhibitor(payload)
        if (resetForm) {
          resetForm(values)
        }
      }
    },
    [saveExhibitor]
  )

  const handleReset = async (
    values: ISponsorItem,
    { resetForm }: Partial<FormikActions<ISponsorItem>>
  ) => {
    if (resetForm) {
      resetForm(values)
    }
  }

  const requiredMessage = 'Please fill this field'

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        saveExhibitor(values)
      }}
      validationSchema={Yup.object().shape({
        contactPerson: Yup.object().shape({
          email: Yup.string()
            .email()
            .required(requiredMessage),
          phone: Yup.string()
            .test('len', 'Must be exactly 10 digits', (val: any) => val.toString().length >= 10)
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/, 'Phone number is not valid')
            .required(requiredMessage),
        }),
        ticketsCount: Yup.number(),
      })}
      render={props => {
        const {
          errors,
          isSubmitting,
          resetForm,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
          isValid,
        } = props
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>{item.company}</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput
                    title="Name"
                    name="company"
                    disabled={isSubmitting}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput
                    title="Web Site"
                    name="link"
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <div className="field">
                    <label>Description</label>
                  </div>
                  <Field
                    component={ReactQuill}
                    theme="snow"
                    scrollingContainer={false}
                    value={values.description}
                    onChange={(content: any) => {
                      setFieldValue('description', content)
                      setFieldTouched('description')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    search
                    name="location"
                    title="Country"
                    value={values.location}
                    options={countriesOptions}
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    multiple
                    name="tags"
                    value={values.tags || []}
                    selection
                    title="Tags"
                    options={sponsorTagsOptions}
                    disabled={isSubmitting}
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    name="primarySponsorTag"
                    selection
                    title="Primary tag"
                    options={filteredOptions(sponsorTagsOptions, values.tags)}
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Contact Person</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={4} tablet={4} computer={4} verticalAlign="middle">
                  <SelectImageInput
                    width={200}
                    height={200}
                    title="Picture"
                    name="contactPerson.personPicture"
                    showGallery={false}
                    onChange={event => {
                      setFieldValue('contactPerson.personPicture', event)
                      setFieldTouched('contactPerson.personPicture')
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={12} tablet={12} computer={12} verticalAlign="middle">
                  <Grid stretched stackable>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Name"
                          name="contactPerson.name"
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Email"
                          name="contactPerson.email"
                          error={
                            getIn(errors, 'contactPerson.email') && {
                              content: getIn(errors, 'contactPerson.email'),
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Phone"
                          name="contactPerson.phone"
                          error={
                            getIn(errors, 'contactPerson.phone') && {
                              content: getIn(errors, 'contactPerson.phone'),
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Position"
                          name="contactPerson.position"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm }, { isValid })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => handleReset(initialValues, { resetForm })}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditBasic
