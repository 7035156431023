import { Field, FieldProps } from 'formik'
import React, { memo, SyntheticEvent } from 'react'
import { Form } from 'semantic-ui-react'

export interface ITextInputPublicProps {
  title?: string
  pladeholder?: string
  name: string
  type?: string
  trim?: boolean
  fluid?: boolean
  onInput?: (value: any) => any
  required?: boolean
  [key: string]: any
}

export type ITextInputProps = FieldProps & ITextInputPublicProps

export const TextInput = ({
  field,
  form,
  type = 'text',
  trim = true,
  fluid = true,
  min,
  title,
  required,
  placeholder,
  error,
  ...props
}: ITextInputProps) => (
  <Form.Input
    {...field}
    {...props}
    value={field.value || ''}
    type={type}
    fluid={fluid}
    label={title}
    required={required}
    error={error}
    onChange={(event: SyntheticEvent) => {
      const element = event.target as HTMLInputElement
      const { value } = element
      if (type === 'number' && min !== null) {
        const newValue = parseInt(value, 10) <= min ? min.toString() : value
        form.setFieldValue(field.name, newValue)
      } else {
        form.setFieldValue(field.name, value)
      }
      form.setFieldTouched(field.name)
    }}
    placeholder={placeholder}
    onBlur={(event: SyntheticEvent) => {
      if (trim && type === 'text') {
        const element = event.target as HTMLInputElement
        const { value } = element
        form.setFieldValue(field.name, value.trim())
      }
    }}
  >
    {props.children && props.children}
  </Form.Input>
)

export default memo(({ ...props }: ITextInputPublicProps) => (
  <Field component={TextInput} {...props} />
))
