import { IFormValues } from 'components/edit-sponsor-layout/EditSponsorLayout'
import { FormikProps } from 'formik'
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Divider, Grid, Icon, Segment } from 'semantic-ui-react'
import { IBoothAvatars, IAttachAvatar } from 'stores/sponsors'
import { LoadingStatus } from 'utils/store'

interface IAvatarsListProps extends FormikProps<IFormValues> {
  actionStatus: LoadingStatus
  avatarImages: string[]
  currentAvatar: string
  avatars: IBoothAvatars[]
  attachAvatar: (values: IAttachAvatar[]) => any
  sponsorId: string
}

interface IAvatarsItemProps {
  actionStatus: LoadingStatus
  avatarImages: string[]
  avatar: IBoothAvatars
  attachAvatar: (values: IAttachAvatar[]) => any
  sponsorId: string
  setFieldValue: (key: string, value: any) => any
  setFieldTouched: (key: string) => any
}

const AvatarItem = ({
  actionStatus,
  attachAvatar,
  avatar,
  avatarImages,
  sponsorId,
  setFieldValue,
  setFieldTouched,
}: IAvatarsItemProps) => {
  const carouselContext = useContext(CarouselContext)
  const [currentAvatar, setCurrentAvatar] = useState(
    avatarImages[carouselContext.state.currentSlide]
  )

  useEffect(() => {
    function onSlideChange() {
      setFieldValue('avatar', avatarImages[carouselContext.state.currentSlide])
      setCurrentAvatar(avatarImages[carouselContext.state.currentSlide])
    }
    carouselContext.subscribe(onSlideChange)
    return () => carouselContext.unsubscribe(onSlideChange)
    // eslint-disable-next-line
  }, [carouselContext])

  const handleAttach = () => {
    attachAvatar([
      {
        avatarId: 1,
        imageUrl: currentAvatar,
        sponsorId,
        title: '',
      },
    ])
  }

  return (
    <Segment placeholder>
      <Slider>
        {avatarImages.map((src, index) => (
          <Slide key={index} index={index}>
            <img height={135} src={src} alt="" />
          </Slide>
        ))}
      </Slider>
      <ButtonBack className="ui circular icon basic button button-prev">
        <Icon name="chevron left" />
      </ButtonBack>
      <ButtonNext className="ui circular icon basic button button-next">
        <Icon name="chevron right" />
      </ButtonNext>
      <Button
        primary
        content="Attach"
        onClick={handleAttach}
        disabled={!sponsorId}
        loading={actionStatus === LoadingStatus.pending}
      />
    </Segment>
  )
}

export const AvatarsList = ({
  actionStatus,
  avatars,
  avatarImages,
  attachAvatar,
  sponsorId,
  setFieldValue,
  setFieldTouched,
}: IAvatarsListProps) => {
  return (
    <Grid stackable centered>
      <Grid.Row textAlign="center">
        <Grid.Column width="6" className="layout">
          <Divider horizontal>Avatar{avatars.length > 1 && 's'}</Divider>
          <Divider hidden />
          {avatars.map((avatar, index) => (
            <AvatarItem
              key={index}
              avatar={avatar}
              avatarImages={avatarImages}
              attachAvatar={attachAvatar}
              sponsorId={sponsorId}
              actionStatus={actionStatus}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const AvatarsPreview = (props: IAvatarsListProps) => {
  const currentSlide = props.avatarImages.findIndex(avatar => avatar === props.currentAvatar)
  return (
    <CarouselProvider
      naturalSlideWidth={135}
      naturalSlideHeight={135}
      isIntrinsicHeight={true}
      visibleSlides={1}
      currentSlide={currentSlide}
      totalSlides={props.avatarImages.length}
    >
      <AvatarsList {...props} />
    </CarouselProvider>
  )
}

export default AvatarsPreview
