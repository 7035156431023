import {
  IAbstractionsContractsQueriesAttendeesBaseAllAttendeesResponse,
  IAbstractionsContractsQueriesAttendeesBaseAttendeesShortInfoByIdsResponse,
  IAbstractionsContractsQueriesAttendeesBaseAttendeesShortInfoByIdsResponseAtrendee,
  IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponse,
  IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponseAttendeeType,
  IAbstractionsContractsQueriesStatusDisplayNamesStatusDisplayNamesResponse,
  IAbstractionsContractsQueriesStatusDisplayNamesStatusDisplayNamesResponseStatusDisplayName,
  IAbstractionsContractsQueriesVipAttendeesVipAttendeeWithActivitiesLinksResponseFeatureFormToken,
  IAbstractionsContractsQueriesVipInvitationsVipInvitationResponse,
  IApiControllersBackofficeRegistrationAttendeesRegularModelsSetAttendeePackageModel,
  IApiControllersBackofficeRegistrationAttendeesRegularModelsUpdateAttendeeModel,
  IApiControllersBackofficeRegistrationAttendeesRegularModelsUpdateAttendeePackageModel,
  IApiControllersBackofficeRegistrationAttendeesVipModelsAttendeeUpdateVipAttendeeModel,
  IApiControllersBackofficeRegistrationAttendeesVipModelsAttendeeUpdateVipAttendeePackageModel,
} from 'api-types'
import { ISelectOption } from 'components/inputs'
import _ from 'lodash'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery, buildQuery, IFilterConfig } from 'utils/filter'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  createLoadingStatusType,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'
import { ICompanyCategoryItem } from './companies'
import { IAttendeePackage } from './packages'

export enum RegistrationStatuses {
  Completed = 'Completed',
  PendingApprove = 'PendingApprove',
  PendingConfirmation = 'PendingConfirmation',
  PendingPackageSelection = 'PendingPackageSelection',
  PendingPayment = 'PendingPayment',
  Refund = 'Refund',
  Rejected = 'Rejected',
  RequestForPayment = 'RequestForPayment',
  Trash = 'Trash',
  SubmitStepOne = 'SubmitStepOne',
}

export enum PackageType {
  Paid = 'Paid',
  Free = 'Free',
  Contract = 'Contract',
}

export const registrationStatusColors = {
  [RegistrationStatuses.Completed]: '#8dc77b',
  [RegistrationStatuses.PendingApprove]: '#00bcd4',
  [RegistrationStatuses.PendingConfirmation]: '#cddc39',
  [RegistrationStatuses.PendingPackageSelection]: '#ffeb3b',
  [RegistrationStatuses.PendingPayment]: '#2196f3',
  [RegistrationStatuses.Refund]: '#ff9800',
  [RegistrationStatuses.Rejected]: '#f97b72',
  [RegistrationStatuses.RequestForPayment]: '#8884d8',
  [RegistrationStatuses.Trash]: '#777',
  [RegistrationStatuses.SubmitStepOne]: '#ffc107',
}

export const registrationStatusOptions = [] as ISelectOption[]
// tslint:disable-next-line:forin
for (const key in RegistrationStatuses) {
  registrationStatusOptions.push({
    key,
    text: key,
    value: key,
  })
}

export type IAttendeeType = IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponseAttendeeType

export type IRegistrationStatusItem = IAbstractionsContractsQueriesStatusDisplayNamesStatusDisplayNamesResponseStatusDisplayName

export type IPutPackageForAttendee = IApiControllersBackofficeRegistrationAttendeesRegularModelsUpdateAttendeePackageModel

export type IPutPackageForVipAttendee = IApiControllersBackofficeRegistrationAttendeesVipModelsAttendeeUpdateVipAttendeePackageModel

export type IPatchPackageForAttendee = IApiControllersBackofficeRegistrationAttendeesRegularModelsSetAttendeePackageModel

export type ISaveRegistrationItem = IApiControllersBackofficeRegistrationAttendeesRegularModelsUpdateAttendeeModel

export type ISaveVipRegistrationItem = IApiControllersBackofficeRegistrationAttendeesVipModelsAttendeeUpdateVipAttendeeModel

export type IAttendeeForStatistics = IAbstractionsContractsQueriesAttendeesBaseAttendeesShortInfoByIdsResponseAtrendee

export interface IAllAttendeesResponse
  extends IAbstractionsContractsQueriesAttendeesBaseAllAttendeesResponse {
  attendees: IRegistrationItemFromApi[]
}

// this type marked as any in api, so we need to build this type
export interface IRegistrationItemFromApi
  extends Omit<ISaveRegistrationItem, 'messageLimit' | 'imageUrl'> {
  blacklisted: boolean
  isVip: boolean
  isVirtualVision: boolean
  isInTop: boolean
  isBlocked: boolean
  isSuperUser: boolean
  registrationDate: string
  registrationIp: string
  paymentType?: string
  paymentProvider?: string
  paymentStatus?: string
  messagesUsed: number
  isStepOneVoted: boolean
  isStepTwoVoted: boolean
  featureFormToken?: IAbstractionsContractsQueriesVipAttendeesVipAttendeeWithActivitiesLinksResponseFeatureFormToken
  vipInvitation?: IAbstractionsContractsQueriesVipInvitationsVipInvitationResponse
  confirmationToken?: string
  voucherToken?: string
  attendeeType?: IAttendeeType
  readonly image: any // object here, but not used in app
  category?: ICompanyCategoryItem // Sometimes category is empty
  package?: IAttendeePackage
  invitationPackage?: IAttendeePackage
  registrationStatus: RegistrationStatuses
}

export interface IRegistrationItem extends IRegistrationItemFromApi {
  imageUrl: string
  registrationStatusName: string // generated for convinience
}

export default types
  .model('RegistrationsStore', {
    actionStatus: createLoadingStatusType(LoadingStatus.success),
    attendeeTypes: types.frozen<IAttendeeType[]>([]),
    attendeesByPage: types.frozen<IRegistrationItem[]>([]),
    attendiesForStatisticsById: types.map(types.frozen<IAttendeeForStatistics>()),
    currentAttendee: types.frozen(),
    fetchAttendeesStatus: LoadingStatusType,
    fetchNamesStatus: LoadingStatusType,
    fetchTypesStatus: LoadingStatusType,
    registrationStatuses: types.frozen<IRegistrationStatusItem[]>([]),
    totalPages: types.optional(types.number, 0),
  })

  .views(self => ({
    getRegistrationStatusName(status: RegistrationStatuses) {
      if (self.registrationStatuses.length < 1) {
        console.warn('[getRegistrationStatusName] You should fetch statuses from server!')
      }
      const found = self.registrationStatuses.find(i => i.registrationStatus === status)
      return found ? found.displayName : status
    },
  }))

  .actions(self => ({
    resetAttendeesList() {
      self.attendeesByPage = []
      self.fetchAttendeesStatus = LoadingStatus.not_loaded
    },

    resetCurrentAttendee() {
      self.currentAttendee = {}
    },

    fetchAttendeeTypes: _.once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get(`/backoffice/attendee-type?${allQuery}`)) as {
            data: IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponse
          }
          self.attendeeTypes = data.attendeeTypes
        }),
        s => (self.fetchTypesStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch attendee types')
      )
    ),

    fetchRegistrationStatusNames: _.once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get('/backoffice/registration/status/display-name')) as {
            data: IAbstractionsContractsQueriesStatusDisplayNamesStatusDisplayNamesResponse
          }
          self.registrationStatuses = data.statusDisplayNames
        }),
        s => (self.fetchNamesStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch registration statuses')
      )
    ),
  }))

  .actions(self => ({
    fetchRegistrations: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        // eslint-disable-next-line
        const [{ data }] = (yield Promise.all([
          axios.get(`/backoffice/registration/attendee/generic?${buildQuery(filter)}`),
          self.fetchRegistrationStatusNames(),
        ])) as [{ data: IAllAttendeesResponse }, any]

        self.attendeesByPage = data.attendees.map((item: IRegistrationItemFromApi) => ({
          ...item,
          // imageUrl for compatibility with UpdateModel
          imageUrl: item.image ? item.image.imageUrl || '' : '',
          registrationStatusName: self.getRegistrationStatusName(item.registrationStatus),
        }))
        self.totalPages = data.totalPages
      }),
      s => (self.fetchAttendeesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch registrations')
    ),

    fetchRegistration: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        // eslint-disable-next-line
        const [{ data }] = (yield Promise.all([
          axios.get(`/backoffice/registration/attendee/generic?${buildQuery(filter)}`),
          self.fetchRegistrationStatusNames(),
        ])) as [{ data: IAllAttendeesResponse }, any]

        self.currentAttendee = data.attendees.map((item: IRegistrationItemFromApi) => ({
          ...item,
          // imageUrl for compatibility with UpdateModel
          imageUrl: item.image ? item.image.imageUrl || '' : '',
          registrationStatusName: self.getRegistrationStatusName(item.registrationStatus),
        }))[0]
      }),
      s => (self.fetchAttendeesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch registration')
    ),
  }))

  .actions(self => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      saveAttendee: createAction(
        (item: ISaveRegistrationItem) =>
          axios.put('/backoffice/registration/attendee/regular', item),
        'Failed to save attendee'
      ),

      saveVipAttendee: createAction(
        (item: ISaveVipRegistrationItem) =>
          axios.put('/backoffice/registration/attendee/vip', item),
        'Failed to save VIP attendee'
      ),

      deleteAttendee: createAction(
        (id: string) =>
          axios.delete(`/backoffice/registration/attendee/generic/force?command.ids=${id}`),
        'Failed to delete attendee'
      ),

      changeStatus: createAction(
        (id: string, status: RegistrationStatuses) =>
          axios.patch('/backoffice/registration/attendee/generic/mass-actions/set-status', {
            ids: [id],
            status,
          }),
        'Failed to change status of attendee'
      ),

      assignPackageForAttendee: createAction(
        (patch: IPatchPackageForAttendee) =>
          axios.patch('/backoffice/registration/attendee/regular/package', patch),
        'Failed to assign package for attendee'
      ),

      submitPackageForVipAttendee: createAction(
        (pack: IPutPackageForVipAttendee) =>
          axios.put('/backoffice/registration/attendee/vip/package', pack),
        'Failed to submit package for VIP attendee'
      ),

      submitPackageForAttendee: createAction(
        (pack: IPutPackageForAttendee) =>
          axios.put('/backoffice/registration/attendee/regular/package', pack),
        'Failed to submit package for attendee'
      ),

      approveAttendee: createAction(
        (id: string) => axios.put(`/backoffice/registration/attendee/regular/approve?id=${id}`),
        'Failed to approve attendee'
      ),

      rejectAttendee: createAction(
        (id: string) =>
          axios.post(`/backoffice/registration/attendee/generic/reject?attendeeId=${id}`),
        'Failed to reject attendee'
      ),

      sendFeaturedAttendeeInvitation: createAction(
        (id: string, template: string) =>
          axios.post('/backoffice/registration/attendee/generic/request-feature-form', {
            attendeeId: id,
            emailTemplateKey: template,
          }),
        'Failed to send featured invitation'
      ),

      sendAttendeeReminder: createAction(
        (id: string) =>
          axios.post(`/backoffice/registration/attendee-reminders/send?attendeeId=${id}`),
        'Failed to send attendee reminder'
      ),

      requestForPayment: createAction(
        (id: string, template: string) =>
          axios.put('/backoffice/registration/attendee/regular/request-for-payment', {
            emailTemplateKey: template,
            id,
          }),
        'Failed to send request for payment'
      ),

      requestForPhoto: createAction(
        (id: string, template?: string) =>
          axios.put('/backoffice/registration/attendee/regular/request-for-submit-image', {
            emailTemplateKey: template,
            id,
          }),
        'Failed to send request for photo'
      ),

      unblockAttendee: createAction(
        (id: string) =>
          axios.post(`/backoffice/registration/attendee/generic/unblock?attendeeId=${id}`),
        'Failed to unblock attendee'
      ),

      updateIsInTop: createAction(
        (ids: string[], isInTop: boolean) =>
          axios.put('/backoffice/registration/attendee/generic/top', { ids, isInTop }),
        'Failed to update isInTopStatus'
      ),

      addToBlackList: createAction(
        (ids: string[]) =>
          axios.patch('/backoffice/registration/attendee/generic/mass-actions/set-blacklisted', {
            ids,
          }),
        'Failed to add attendee to blacklist'
      ),

      tryfetchAttendiesForStatistics: createAxiosAction(
        flow(function*(ids: string[]) {
          const query = ids
            .filter(id => !self.attendiesForStatisticsById.has(id))
            .map(id => `command.ids=${id}`)
            .join('&')
          const { data } = (yield axios.get(
            `/vip-admin/media-partners/statistic/attendees?${query}`
          )) as {
            data: IAbstractionsContractsQueriesAttendeesBaseAttendeesShortInfoByIdsResponse
          }
          data.attendees.forEach(attendee =>
            self.attendiesForStatisticsById.set(attendee.id, attendee)
          )
        }),
        () => void 0,
        () => getParent<IRootStore>(self).showError('Failed to fetch attendies for statistics')
      ),
    }
  })
