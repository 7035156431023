import {
  IAbstractionsContractsQueriesBlacklistBlacklistResponce,
  IAbstractionsContractsQueriesBlacklistBlacklistResponceBlacklistTag,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { AxiosResponse } from 'axios'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'
import { IRegistrationItem } from './registrations'

export type IBlackListItem = IAbstractionsContractsQueriesBlacklistBlacklistResponceBlacklistTag

export default types
  .model('BlacklistStore', {
    actionStatus: LoadingStatusType,
    fetchBlackListStatus: LoadingStatusType,
    blackList: types.frozen<IBlackListItem[]>([]),
    totalPages: types.optional(types.number, 0),
  })

  .views(self => ({
    expressions: () => self.blackList.map(t => new RegExp(t.tag, 'i')),
  }))

  .views(self => ({
    inBlacklist: (item: IRegistrationItem) => {
      const name = `${item.firstName} ${item.lastName}`
      return !!self.expressions().find(r => name.match(r) || item.email.match(r))
    },
  }))

  .actions(self => ({
    fetchBlackList: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get('/backoffice/blacklist')) as AxiosResponse<IAbstractionsContractsQueriesBlacklistBlacklistResponce>
        self.blackList = data.blacklistTags
      }),
      s => (self.fetchBlackListStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch blacklist')
    ),
  }))

  .actions((self) => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      addTag: createAction((tag: string) => {
        return axios.post(`/backoffice/blacklist?tag=${encodeURIComponent(tag)}`)
      }, 'Failed to add tag to blacklist'),

      deleteTag: createAction((id: string) => {
        return axios.delete(`/backoffice/blacklist/${id}`)
      }, 'Failed to remove tag from blacklist'),
    }
  })
