import { Route, useRouteMatch } from 'react-router-dom'
import Auth from 'components/auth/Auth'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import BoothPreview from 'pages/booth-preview/BoothPreview'
import SideMenu from 'components/side-menu/SideMenu'
import TopMenu from 'components/top-menu/TopMenu'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { useRootData } from 'stores'
import useWindowDimensions from 'utils/dimensions'
import 'semantic-ui-css/semantic.min.css'
import './index.css'

interface IToastsProviderProps {
  errorMessage: string
}

const PageScroller = ({ children }: { children: ReactElement }) => {
  const { isMobile } = useWindowDimensions()
  return <div className={`page-scroller ${isMobile ? '' : 'not-mobile'}`}>{children}</div>
}

const Toasts = ({ errorMessage }: IToastsProviderProps) => {
  const { addToast } = useToasts()

  useEffect(() => {
    if (errorMessage.length > 1) {
      const parts: ReactNode[] = []
      errorMessage.split(/\r?\n/).forEach(line => parts.push(line, <br />))
      parts.pop()
      addToast(parts, {
        appearance: 'warning',
      })
    }
  }, [addToast, errorMessage])

  return null
}

const App = observer(({ children }: { children: ReactElement }) => {
  const { errorMessage } = useRootData(store => store.appStore)

  if (useRouteMatch('/booth/:guid')) {
    return <Route path="/booth/:guid" component={BoothPreview} />
  }

  return (
    <ToastProvider autoDismissTimeout={2000}>
      <Auth>
        <>
          <Toasts errorMessage={errorMessage} />
          <TopMenu />
          <SideMenu />
          <PageScroller>{children}</PageScroller>
        </>
      </Auth>
    </ToastProvider>
  )
})

export default App
