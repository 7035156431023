import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Button, Form, Grid } from 'semantic-ui-react'
import { ISponsorItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import { REACT_APP_ROLE } from 'utils/config'

interface IEditFormProps {
  actionStatus: LoadingStatus
  item: ISponsorItem
  saveSponsor: (payload: any) => any
}

export const EditCMS = ({ actionStatus, item, saveSponsor }: IEditFormProps) => {
  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const { isMobile } = useWindowDimensions()

  const handleSubmit = useCallback(
    (values: ISponsorItem, { resetForm }: Partial<FormikActions<ISponsorItem>>) => {
      saveSponsor(values)
      if (resetForm) {
        resetForm(values)
      }
    },
    [saveSponsor]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        saveSponsor(values)
      }}
      render={props => {
        const { values, touched, setFieldTouched, setFieldValue, resetForm } = props
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row stretched>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectImageInput
                    title="Logo"
                    fluid
                    height={200}
                    name="logoUrl"
                    showGallery={REACT_APP_ROLE === 'regular'}
                    onChange={event => {
                      setFieldValue('logoUrl', event)
                      setFieldTouched('logoUrl')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectImageInput
                    title="White Logo"
                    fluid
                    height={200}
                    name="logoWhiteUrl"
                    showGallery={REACT_APP_ROLE === 'regular'}
                    onChange={event => {
                      setFieldValue('logoWhiteUrl', event)
                      setFieldTouched('logoWhiteUrl')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectImageInput
                    title="3D Booth Logo"
                    fluid
                    height={200}
                    name="logo3D"
                    showGallery={REACT_APP_ROLE === 'regular'}
                    onChange={event => {
                      setFieldValue('logo3D', event)
                      setFieldTouched('logo3D')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => resetForm()}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditCMS
