import {
  IAbstractionsContractsQueriesGalleriesGalleriesResponse,
  IAbstractionsContractsQueriesGalleriesGalleriesResponseGallery,
  IAbstractionsServicesFilesContractsFilesResponse,
  IAbstractionsServicesFilesContractsFilesResponseFile,
  IApiControllersBackofficeGeneralSettingsFilesModelsUploadFilesResponseModel,
  IApiControllersBackofficeGeneralSettingsGalleryModelsUploadImageResponseModel,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { buildQuery, IFilterConfig } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'
import { REACT_APP_ROLE } from 'utils/config'

export type IImageUploadResult = IApiControllersBackofficeGeneralSettingsGalleryModelsUploadImageResponseModel
export type IFileUploadResult = IApiControllersBackofficeGeneralSettingsFilesModelsUploadFilesResponseModel
export type IImageItem = IAbstractionsContractsQueriesGalleriesGalleriesResponseGallery
export type IFileItem = IAbstractionsServicesFilesContractsFilesResponseFile

const API_PATH = REACT_APP_ROLE === 'regular' ? 'backoffice/general-settings' : 'vip-admin'

const isRegularUser = REACT_APP_ROLE === 'regular'

export async function uploadImage(file: File): Promise<string | null> {
  try {
    const fd = new FormData()
    fd.append('Configuration', JSON.stringify({ Transforms: [] }))
    fd.append('file', file)
    const { data } = (await axios({
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/${API_PATH}/images-storage/upload`,
    })) as { data: IImageUploadResult }

    const urls = data.storedImageResponses[0]
    if (urls) {
      if (isRegularUser) {
        await axios.post('/backoffice/general-settings/gallery', {
          galleries: [{ imagesUrls: urls, title: '', alt: '', categories: [] }],
        })
      }
      return (urls as any).originalImageUrl || null
    } else {
      return null
    }
  } catch (err) {
    console.error('Failed to upload image', err)
    return null
  }
}

export async function uploadFile(file: File): Promise<string | null> {
  try {
    const fd = new FormData()
    fd.append('file', file)
    const { data } = (await axios({
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/${API_PATH}/files-storage/upload`,
    })) as { data: IFileUploadResult }

    const urls = data.storedFileResponses[0]
    if (urls) {
      if (isRegularUser) {
        await axios.get('/backoffice/general-settings/files-storage')
      }
      return (urls as any).originalFileUrl || null
    } else {
      return null
    }
  } catch (err) {
    console.error('Failed to upload file', err)
    return null
  }
}

export default types
  .model('ImagesStore', {
    fetchFilesStatus: LoadingStatusType,
    fetchImagesStatus: LoadingStatusType,
    files: types.frozen<IFileItem[]>([]),
    filesCount: types.optional(types.string, '1'),
    images: types.frozen<IImageItem[]>([]),
    pagesCount: types.optional(types.number, 1),
  })

  .actions(self => ({
    fetchImages: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/general-settings/gallery?${buildQuery(filter)}`
        )) as {
          data: IAbstractionsContractsQueriesGalleriesGalleriesResponse
        }
        self.images = data.galleries
        self.pagesCount = data.totalPages
      }),
      s => (self.fetchImagesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch images')
    ),
  }))

  .actions(self => ({
    fetchFiles: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/general-settings/files-storage?${buildQuery(filter)}`
        )) as {
          data: IAbstractionsServicesFilesContractsFilesResponse
        }
        const pagesCount = parseInt(data.nextPageCursor, 10) || 0
        self.files = data.files
        self.pagesCount = pagesCount
      }),
      s => (self.fetchFilesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch files')
    ),
  }))
