import { TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Confirm, Form, Header, Modal, Pagination, Table } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IUpdateSponsorTagItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

const initialValues = {
  displayName: '',
  firstDescriptionCollection: [],
  galleryId: '',
  id: '',
  secondDescriptionCollection: [],
  thirdDescriptionCollection: [],
} as IUpdateSponsorTagItem

interface IEditSponsorTags {
  fetchSponsorsTagsByPage: (filter: IFilterConfig) => Promise<any>
  fetchTagsStatus: LoadingStatus
  sponsorTagsByPage: IUpdateSponsorTagItem[]
  sponsorTagsPages: number
  saveSponsorTag: (payload: IUpdateSponsorTagItem, onDone: () => any) => Promise<any>
  deleteSponsorTag: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
}

export const EditSponsorTags = ({
  fetchSponsorsTagsByPage,
  fetchTagsStatus,
  sponsorTagsPages,
  sponsorTagsByPage,
  saveSponsorTag,
  deleteSponsorTag,
}: IEditSponsorTags) => {
  const [entryToEdit, setEntryToEdit] = useState<IUpdateSponsorTagItem>()
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)

  const filters = useMemo(
    () => ({
      itemsOnPage: 20,
      page: page - 1,
      sort: {
        desc: true,
        field: 'Title',
      },
    }),
    [page]
  )

  const fetchTags = () => fetchSponsorsTagsByPage(filters)

  useEffect(() => {
    fetchTags()
    // eslint-disable-next-line
  }, [page])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const toggleModal = async (entry?: IUpdateSponsorTagItem) => {
    await setEntryToEdit(entry)
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal()
  }

  const handleDelete = async (entry: any) => {
    await deleteSponsorTag({ id: entry.id }, () => fetchTags())
  }

  const handleSubmit = async (
    values: IUpdateSponsorTagItem,
    { resetForm }: Partial<FormikActions<IUpdateSponsorTagItem>>
  ) => {
    await saveSponsorTag(values, () => fetchTags())
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const { isMobile } = useWindowDimensions()

  const loadedTypes = fetchTagsStatus === LoadingStatus.success

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedTypes
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedTypes, setPage]
  )

  return (
    <>
      <Table size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={15} />
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sponsorTagsByPage.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h5">{entry.displayName}</Header>
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => toggleModal(entry)}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {sponsorTagsPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2} textAlign="center">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={handlePaginationChange}
                  totalPages={sponsorTagsPages}
                  firstItem={false}
                  lastItem={false}
                  prevItem={false}
                  nextItem={false}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={props => {
          const { values, touched, setSubmitting, resetForm } = props
          return (
            <Modal
              centered={false}
              dimmer="inverted"
              size="tiny"
              trigger={
                <Button primary onClick={() => toggleModal()}>
                  Add Sponsor Tag
                </Button>
              }
              open={showModal}
              onClose={() => {
                Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
              }}
              closeOnDimmerClick={true}
            >
              <Modal.Header>{`${fullValues.id.length ? 'Edit' : 'Add'}`} Sponsor Tag</Modal.Header>
              <Modal.Content>
                <Form as={FormikForm} autoComplete="off">
                  <TextInput title="Title" name="displayName" />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  fluid={isMobile}
                  type="button"
                  onClick={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                  }}
                  content="Cancel"
                />
                <Button
                  type="submit"
                  fluid={isMobile}
                  width={3}
                  primary
                  loading={fetchTagsStatus === LoadingStatus.pending}
                  onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                  content="Save"
                />
              </Modal.Actions>
            </Modal>
          )
        }}
      />

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </>
  )
}

export const EditSponsorsTiersForm = observer(() => {
  const {
    fetchSponsorsTagsByPage,
    fetchTagsStatus,
    sponsorTagsByPage,
    sponsorTagsPages,
    saveSponsorTag,
    deleteSponsorTag,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditSponsorTags
      {...{
        deleteSponsorTag,
        fetchSponsorsTagsByPage,
        fetchTagsStatus,
        saveSponsorTag,
        sponsorTagsByPage,
        sponsorTagsPages,
      }}
    />
  )
})

export default EditSponsorsTiersForm
