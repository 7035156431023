import { SelectInput, TextArea, TextInput } from 'components/inputs'
import SelectFileInput from 'components/select-file-input/SelectFileInput'
// import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { Form as FormikForm, Formik, FormikActions, FormikProps } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Header,
  Modal,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IContentItem, ISponsorItem, IUpdateContentItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'

const initialValues = {
  attachStatus: 'Free',
  documentType: 'Document',
  file: '',
  id: '',
  keywordsTags: '',
  sponsorId: '',
  status: 'Draft',
  thumbnail: '',
  title: '',
  url: '',
} as IUpdateContentItem

const typeOptions = [
  {
    key: 'document',
    text: 'Document',
    value: 'Document',
  },
  {
    key: 'video',
    text: 'Video',
    value: 'Video',
  },
]

const statusOptions = [
  {
    key: 'draft',
    text: 'Draft',
    value: 'Draft',
  },
  {
    key: 'published',
    text: 'Published',
    value: 'Published',
  },
]

interface IDocumentFormProps extends FormikProps<IUpdateContentItem> {
  item: ISponsorItem
}

type SortType = 'ascending' | 'descending' | undefined

const DocumentForm = ({ item, ...props }: IDocumentFormProps) => {
  return (
    <Form as={FormikForm} autoComplete="off">
      <TextInput title="Title" name="title" />
      <SelectInput name="documentType" title="Type" options={typeOptions} />
      {props.values.documentType === 'Video' ? (
        <TextInput title="URL" name="url" />
      ) : (
        <SelectFileInput width={200} height={100} title="File" name="url" />
      )}
      <SelectInput name="status" title="Status" options={statusOptions} />
      <TextArea title="Keywords, tags" name="keywordsTags" />
    </Form>
  )
}

interface IEditDocumentsProps extends IEditDocumentsFormInjectedProps {
  actionStatus: LoadingStatus
  content: IContentItem[]
  deleteContent: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
  fetchContent: (id: string) => Promise<any>
  fetchContentStatus: string
  saveContent: (payload: IUpdateContentItem, onDone: () => any) => Promise<any>
  updateContent: (payload: IUpdateContentItem, onDone: () => any) => Promise<any>
}

export const EditDocuments = ({
  actionStatus,
  content,
  deleteContent,
  fetchContent,
  fetchContentStatus,
  item,
  saveContent,
  updateContent,
}: IEditDocumentsProps) => {
  const history = useHistory()
  const location = useLocation()
  const { slug } = useParams()

  const queryParams = new URLSearchParams(location.search.slice(1))
  const idFromUrl = queryParams.get('id')?.toString()
  const entryFromUrl = content.find((entry: IContentItem) => entry.id === idFromUrl) || {}

  const [showModal, setShowModal] = useState(false)
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [entryToEdit, setEntryToEdit] = useState(entryFromUrl)
  const [sortBy, setSortBy] = useState('title')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')
  const [attachedFilter, setAttachedFilter] = useState(false)

  const toggleManage = useCallback(() => setShowManage(!showManage), [showManage, setShowManage])

  const toggleModal = async (entry?: IContentItem) => {
    await setEntryToEdit(entry ? entry : {})
    setShowModal(!showModal)
    history.push(`${location.pathname}?tab=Documents${entry ? `&id=${entry.id}` : ''}`)
  }

  useEffect(() => {
    if (slug !== 'create') {
      fetchContent(item.id)
    }
  }, [fetchContent, item, slug])

  // WTF
  // useEffect(() => {
  //   if (fetchContentStatus === 'success') {
  //     setShowModal(true)
  //   }
  // }, [fetchContentStatus])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const handleStatus = async (entry: any) => {
    const payload = { ...entry, sponsorId: item.id }
    await updateContent(payload, () => fetchContent(item.id))
  }

  const handleDelete = async (entry: any) => {
    await deleteContent({ sponsorId: item.id, id: entry.id }, () => fetchContent(item.id))
  }

  const handleSort = (newSort: string) => {
    setSortBy(newSort)
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const handleSubmit = async (
    values: IUpdateContentItem,
    { resetForm }: Partial<FormikActions<IUpdateContentItem>>
  ) => {
    values.sponsorId = item.id
    await saveContent(values, () => fetchContent(item.id))
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal()
  }

  const displayContent = (values: any) => {
    const result = _.orderBy(values, sortBy, sortDirection === 'ascending' ? 'asc' : 'desc')
    if (attachedFilter) {
      return result.filter(entry => entry.attachStatus === 'Attached')
    }
    return result
  }

  const { isMobile } = useWindowDimensions()

  return (
    <>
      <Grid relaxed>
        <Grid.Row>
          <Grid.Column width={8} />
          <Grid.Column textAlign="right" width={8}>
            <Checkbox
              toggle
              label="Show attahed only"
              onChange={() => setAttachedFilter(!attachedFilter)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table size="small" singleLine sortable columns={5} basic="very">
        <Table.Header>
          <Table.Row>
            {/* {showManage && <Table.HeaderCell collapsing> */}
            {/*   <Checkbox /> */}
            {/* </Table.HeaderCell>} */}
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'title' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('title')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'documentType' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('documentType')}
            >
              Type
            </Table.HeaderCell>
            <Table.HeaderCell
              width={4}
              sorted={sortBy === 'status' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('status')}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              width={3}
              sorted={sortBy === 'attachStatus' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('attachStatus')}
            >
              Booth
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={toggleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {displayContent(content).map((entry, index) => (
            <Table.Row key={index}>
              {/* {showManage && <Table.Cell collapsing> */}
              {/*   <Checkbox /> */}
              {/* </Table.Cell>} */}
              <Table.Cell>
                <Header as="h4">{entry.title}</Header>
              </Table.Cell>
              <Table.Cell>{entry.documentType}</Table.Cell>
              <Table.Cell
                positive={entry.status === 'Published'}
                warning={entry.status === 'Draft'}
              >
                <Dropdown
                  inline
                  pointing="top left"
                  options={statusOptions}
                  trigger={entry.status}
                  defaultValue={entry.status}
                  disabled={showManage}
                  onChange={(event, { value }) => handleStatus({ ...entry, status: value })}
                />
              </Table.Cell>
              <Table.Cell
                positive={entry.attachStatus === 'Attached'}
                warning={entry.attachStatus === 'Free'}
              >
                {entry.attachStatus}
              </Table.Cell>
              <Table.Cell textAlign="right" width={1}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => toggleModal(entry)}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={props => {
          const { values, touched, setSubmitting, resetForm } = props
          return (
            <Modal
              centered={false}
              dimmer="inverted"
              size="tiny"
              trigger={(
                <Button primary basic onClick={() => toggleModal()}>
                  Add Document
                </Button>
              )}
              open={showModal}
              onClose={() => {
                Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
              }}
              closeOnDimmerClick={true}
            >
              <Modal.Header>{`${fullValues.id.length ? 'Edit' : 'Add'}`} Document</Modal.Header>
              <Modal.Content>
                <DocumentForm item={item} {...props} />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  secondary
                  fluid={isMobile}
                  type="button"
                  onClick={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                  }}
                  content="Cancel"
                />
                <Button
                  type="submit"
                  fluid={isMobile}
                  width={3}
                  primary
                  loading={actionStatus === LoadingStatus.pending}
                  onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                  content="Save"
                />
              </Modal.Actions>
            </Modal>
          )
        }}
      />

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </>
  )
}

interface IEditDocumentsFormInjectedProps {
  item: ISponsorItem
}

export const EditDocumentsFormInjected = observer((props: IEditDocumentsFormInjectedProps) => {
  const {
    actionStatus,
    content,
    deleteContent,
    fetchContent,
    fetchContentStatus,
    saveContent,
    updateContent,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditDocuments
      {...props}
      {...{
        actionStatus,
        content,
        deleteContent,
        fetchContent,
        fetchContentStatus,
        saveContent,
        updateContent,
      }}
    />
  )
})

export default EditDocumentsFormInjected
