import { generateDeviceId } from './device-id'

export type AuthType = 'regular' | 'saml' | 'saml_no_request'
export type AppRole = 'regular' | 'exhibitor'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const REFRESH_TOKEN_LIFETIME = 59 * 60 * 1000
export const DEVICE_ID = generateDeviceId()
export const API = process.env.REACT_APP_API
export const SITE = process.env.REACT_APP_SITE
export const REACT_APP_ROLE = (process.env.REACT_APP_ROLE || 'regular') as AppRole
export const AUTH_TYPE = (process.env.REACT_APP_AUTH_TYPE || 'regular') as AuthType
export const WITH_SAML = AUTH_TYPE === 'saml' || AUTH_TYPE === 'saml_no_request'
export const SAML_URL = process.env.REACT_APP_SAML_URL!
export const TOKEN_REFRESH_PATH =
  REACT_APP_ROLE === 'regular' ? '/backoffice/refresh-token' : '/vip-admin/refresh-token'
