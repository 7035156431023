import { SelectInput, TextArea, TextInput } from 'components/inputs'
import {
  addMinutes,
  differenceInMinutes,
  endOfDay,
  formatISO,
  parseISO,
  startOfDay,
} from 'date-fns'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  DropdownProps,
  Form,
  Header,
  Icon,
  Input,
  Loader,
  Menu,
  Modal,
  Pagination,
  Select,
  Sticky,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { INotificationsItem, INotificationsUpdate } from 'stores/notifications'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'
import useDebounce from 'utils/use-debounce'

type SortType = 'ascending' | 'descending' | undefined

const EmptyResult = (loader: boolean) => (
  <Table.Row>
    <Table.Cell colSpan={6} textAlign="center">
      {loader ? <Loader /> : 'Nothing found'}
    </Table.Cell>
  </Table.Row>
)

const today = formatISO(new Date())
const dateISO = (date: any) => formatISO(new Date(date.toString()))

const statusOptions = [
  {
    key: 'Draft',
    text: 'Draft',
    value: 'Draft',
  },
  {
    key: 'Active',
    text: 'Active',
    value: 'Active',
  },
]

const typeOptions = [
  {
    key: 'Text',
    text: 'Text',
    value: 'Text',
  },
  {
    key: 'Link',
    text: 'Link',
    value: 'Link',
  },
]

const initialValues = {
  acknowledge: false,
  duration: 0,
  endDateTime: '',
  id: '',
  message: '',
  startDateTime: today,
  status: 'Draft',
  type: 'Text',
  url: '',
  subject: '',
  redirectType: 'NewTab',
  redirectUrl: '',
} as INotificationsUpdate

interface INotificationsProps {
  fetchNotifications: (filter: IFilterConfig) => Promise<any>
  fetchNotificationsStatus: LoadingStatus
  updateNotification: (payload: INotificationsUpdate, onDone: () => any) => Promise<any>
  deleteNotification: (id: string, onDone: () => any) => Promise<any>
  notifications: INotificationsItem[]
  totalPages: number
}

export const Notifications = ({
  fetchNotifications,
  fetchNotificationsStatus,
  updateNotification,
  deleteNotification,
  notifications,
  totalPages,
}: INotificationsProps) => {
  const ref = useRef(null)
  const [subjectValue, setSubjectValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [statusValue, setStatusValue] = useState('')
  const [showManage, setShowManage] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState('Subject')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')
  const [entryToEdit, setEntryToEdit] = useState<INotificationsItem>()
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const debouncedSubject = useDebounce(subjectValue, 500)
  const debouncedMessage = useDebounce(messageValue, 500)
  const { isMobile } = useWindowDimensions()

  const filters = useMemo(
    () =>
      ({
        filter: {
          Message: {
            operator: 'Like',
            value: debouncedMessage,
          },
          Status: {
            operator: 'Equals',
            value: statusValue,
          },
          Subject: {
            operator: 'Like',
            value: debouncedSubject,
          },
        },
        itemsOnPage: 20,
        page: page - 1,
        sort: {
          desc: sortDirection === 'descending',
          field: sortBy,
        },
      } as IFilterConfig),
    [debouncedSubject, debouncedMessage, statusValue, page, sortDirection, sortBy]
  )

  const fetchPage = () => fetchNotifications(filters)

  useEffect(() => {
    fetchPage()
    // eslint-disable-next-line
  }, [filters])

  const fullValues: INotificationsUpdate = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const toggleModal = async (entry?: INotificationsItem) => {
    await setEntryToEdit(entry)
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal(undefined)
  }

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    switch (data.name) {
      case 'status':
        setStatusValue(data.value as string)
        break
    }
    fetchPage()
  }

  const handleInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    switch (name) {
      case 'subject':
        setSubjectValue(value)
        break
      case 'message':
        setMessageValue(value)
        break
    }
    // eslint-disable-next-line
  }, [])

  const handleClear = useCallback((event: string) => {
    switch (event) {
      case 'subject':
        setSubjectValue('')
        break
      case 'message':
        setMessageValue('')
        break
    }
    // eslint-disable-next-line
  }, [])

  const handleFilters = useCallback(() => {
    if (showFilters) {
      setShowFilters(false)
      setMessageValue('')
      setSubjectValue('')
      setStatusValue('')
    } else {
      setShowFilters(true)
    }
  }, [showFilters])

  const handleSort = (newSort: string) => {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
    setSortBy(newSort)
  }

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const loadedNotifications = fetchNotificationsStatus === LoadingStatus.success

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedNotifications
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedNotifications, setPage]
  )

  const handleStatus = async (item: INotificationsItem, status: any) => {
    const payload = {
      ...item,
      status,
    } as INotificationsUpdate
    await updateNotification(payload, () => fetchPage())
  }

  const handleDelete = async (id: any) => {
    await deleteNotification(id, () => fetchPage())
  }

  const handleSubmit = async (
    values: INotificationsUpdate,
    { resetForm }: Partial<FormikActions<INotificationsUpdate>>
  ) => {
    values.id = entryToEdit ? entryToEdit.id : ''
    await updateNotification(values, () => fetchPage())
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const renderItem = (item: INotificationsItem) => (
    <Table.Row key={item.id}>
      <Table.Cell>
        <Header as="h4">{item.subject ? item.subject : '—'}</Header>
      </Table.Cell>
      <Table.Cell>{item.message}</Table.Cell>
      <Table.Cell positive={item.status === 'Active'} warning={item.status === 'Draft'}>
        <Dropdown
          inline
          pointing="top left"
          options={statusOptions}
          trigger={item.status}
          defaultValue={item.status}
          disabled={showManage}
          onChange={(event, { value }) => handleStatus(item, value)}
        />
      </Table.Cell>
      <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
        {showManage ? (
          <Button basic icon="delete" color="red" onClick={() => handleDelete(item.id)} />
        ) : (
          <Button
            basic
            icon="pencil alternate"
            style={{ boxShadow: 'none' }}
            onClick={() => toggleModal(item)}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )

  const tabs = () => (
    <Sticky context={ref} offset={isMobile ? 0 : 20}>
      <Menu tabular style={{ backgroundColor: '#fff' }} borderless>
        <Formik
          initialValues={fullValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          render={props => {
            const {
              values,
              touched,
              setFieldValue,
              setFieldTouched,
              setSubmitting,
              resetForm,
            } = props
            const duration = differenceInMinutes(
              parseISO(values.endDateTime),
              parseISO(values.startDateTime)
            )
            return (
              <>
                <Modal
                  centered={false}
                  dimmer="inverted"
                  size="tiny"
                  trigger={
                    <Button primary size="tiny" onClick={() => toggleModal()}>
                      Create
                    </Button>
                  }
                  open={showModal}
                  onClose={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal(undefined)
                  }}
                  closeOnDimmerClick={true}
                >
                  <Modal.Header>{`${entryToEdit ? 'Edit' : 'Add'}`} Notification</Modal.Header>
                  <Modal.Content>
                    <Form as={FormikForm} autoComplete="off">
                      <TextInput title="Subject" name="subject" />
                      <TextArea title="Message" name="message" />
                      <SelectInput search name="status" title="Status" options={statusOptions} />
                      <div className="field">
                        <Checkbox
                          label="User must acknowledge"
                          name="acknowledge"
                          defaultChecked={fullValues.acknowledge}
                          onChange={(event, { checked }) => {
                            setFieldValue('acknowledge', checked)
                            setFieldTouched('acknowledge')
                          }}
                        />
                      </div>
                      <div className="field">
                        <label>Start time</label>
                        <DatePicker
                          selected={parseISO(values.startDateTime)}
                          minDate={parseISO(today)}
                          maxDate={values.endDateTime ? parseISO(values.endDateTime) : null}
                          showTimeSelect
                          minTime={
                            values.startDateTime === today
                              ? parseISO(today)
                              : startOfDay(parseISO(values.startDateTime))
                          }
                          maxTime={
                            values.endDateTime
                              ? addMinutes(parseISO(values.endDateTime), -1)
                              : endOfDay(parseISO(values.startDateTime))
                          }
                          dateFormat="MMMM d, yyyy h:mm aa"
                          timeIntervals={10}
                          customInput={<Input fluid value={values.startDateTime} />}
                          onChange={date => {
                            if (date) {
                              const newDate = dateISO(date)
                              setFieldValue('startDateTime', newDate)
                              setFieldTouched('startDateTime')
                              if (values.endDateTime) {
                                setFieldValue('duration', duration)
                                setFieldTouched('duration')
                              }
                            }
                          }}
                        />
                      </div>
                      <SelectInput search name="type" title="Type" options={typeOptions} />
                      <TextInput title="URL" name="url" />
                      <div className="field">
                        <label>End time</label>
                        <DatePicker
                          selected={values.endDateTime ? parseISO(values.endDateTime) : null}
                          minDate={parseISO(values.startDateTime)}
                          showTimeSelect
                          minTime={addMinutes(parseISO(values.startDateTime), 10)}
                          maxTime={endOfDay(parseISO(values.startDateTime))}
                          timeIntervals={10}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          customInput={
                            <Input
                              fluid
                              value={values.endDateTime}
                              icon={
                                touched.endDateTime ? (
                                  <Icon
                                    name="x"
                                    link
                                    onClick={() => {
                                      setFieldValue('endDateTime', '')
                                      setFieldValue('duration', 0)
                                    }}
                                  />
                                ) : (
                                  undefined
                                )
                              }
                            />
                          }
                          onChange={date => {
                            if (date) {
                              const newDate = dateISO(date)
                              setFieldValue('endDateTime', newDate)
                              setFieldTouched('endDateTime')
                              setFieldValue('duration', duration)
                              setFieldTouched('duration')
                            }
                          }}
                        />
                      </div>
                      <TextInput
                        type="number"
                        title="Duration (in minutes)"
                        name="duration"
                        value={values.duration}
                      />
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      fluid={isMobile}
                      type="button"
                      onClick={() => {
                        Object.keys(touched).length ? setShowConfirm(true) : toggleModal(undefined)
                      }}
                      content="Cancel"
                    />
                    <Button
                      type="submit"
                      fluid={isMobile}
                      width={3}
                      primary
                      loading={fetchNotificationsStatus === LoadingStatus.pending}
                      onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                      disabled={!Object.keys(touched).length}
                      content="Save"
                    />
                  </Modal.Actions>
                </Modal>
                <Confirm
                  size="mini"
                  open={showConfirm}
                  onCancel={event => setShowConfirm(false)}
                  onConfirm={() => {
                    handleConfirm()
                    resetForm()
                  }}
                  content="Discard changes?"
                />
              </>
            )
          }}
        />
        <Menu.Item position="right" style={{ paddingRight: 13 }}>
          <Checkbox toggle checked={showFilters} onChange={handleFilters} label="Filters" />
        </Menu.Item>
      </Menu>
    </Sticky>
  )

  return (
    <div className="notifications" ref={ref}>
      {tabs()}
      <Table size="small" sortable basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortBy === 'Subject' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('Subject')}
              width={5}
            >
              Subject
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Message' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('Message')}
              width={8}
            >
              Message
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortBy === 'Status' ? sortDirection : undefined}
              onClick={(event: any) => handleSort('Status')}
              width={3}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
          {showFilters ? (
            <Table.Row>
              <Table.Cell>
                <Input
                  fluid
                  icon={
                    subjectValue.length ? (
                      <Icon
                        name="close"
                        bordered
                        circular
                        link
                        onClick={() => handleClear('subject')}
                        style={{ boxShadow: 'none' }}
                      />
                    ) : null
                  }
                  name="subject"
                  value={subjectValue}
                  placeholder="Subject"
                  onChange={handleInput}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  fluid
                  icon={
                    messageValue.length ? (
                      <Icon
                        name="close"
                        bordered
                        circular
                        link
                        onClick={() => handleClear('message')}
                        style={{ boxShadow: 'none' }}
                      />
                    ) : null
                  }
                  name="message"
                  value={messageValue}
                  placeholder="Message"
                  onChange={handleInput}
                />
              </Table.Cell>
              <Table.Cell>
                <Select
                  fluid
                  search
                  name="status"
                  clearable
                  placeholder="Status"
                  options={statusOptions}
                  onChange={handleSelect}
                />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          ) : null}
        </Table.Header>
        <Table.Body>
          {notifications.length > 0
            ? notifications.map(item => renderItem(item))
            : EmptyResult(fetchNotificationsStatus === LoadingStatus.pending)}
        </Table.Body>
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={6} textAlign="center">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={false}
                  lastItem={false}
                  prevItem={false}
                  nextItem={false}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  )
}

export default observer(() => {
  const {
    fetchNotifications,
    fetchNotificationsStatus,
    updateNotification,
    deleteNotification,
    notifications,
    totalPages,
  } = useRootData(store => store.notificationsStore)
  return (
    <Notifications
      {...{
        deleteNotification,
        fetchNotifications,
        fetchNotificationsStatus,
        notifications,
        totalPages,
        updateNotification,
      }}
    />
  )
})
