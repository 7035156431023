import { TextArea, TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button, Checkbox, Divider, Form, Grid, Select } from 'semantic-ui-react'
import { ISponsorFloor, ISponsorItem, IUpdateSponsorFloors } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'

interface IEditFormProps {
  actionStatus: LoadingStatus
  item: ISponsorItem
  sponsorFloors: ISponsorFloor[]
  saveSponsor: (payload: any) => any
  updateSponsorFloor: (payload: IUpdateSponsorFloors) => any
}

const floorsMap = (floors: ISponsorFloor[]) =>
  floors.map(item => ({
    key: item.id,
    text: item.title,
    value: item.id,
  }))

export const EditVirtual = ({
  actionStatus,
  item,
  sponsorFloors,
  saveSponsor,
  updateSponsorFloor,
}: IEditFormProps) => {
  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const { isMobile } = useWindowDimensions()

  const handleSubmit = useCallback(
    (values: ISponsorItem, { resetForm }: Partial<FormikActions<ISponsorItem>>) => {
      saveSponsor(values)
      if (resetForm) {
        resetForm(values)
      }
    },
    [saveSponsor]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        saveSponsor(values)
      }}
      render={props => {
        const { values, touched, setFieldTouched, setFieldValue, resetForm } = props
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <FloorSelection
                sponsorId={item.id}
                sponsorFloors={sponsorFloors}
                updateSponsorFloor={updateSponsorFloor}
              />
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Notifications</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Checkbox
                    label="Allow event noticitaions"
                    name="allowEventsNotifications"
                    defaultChecked={values.allowEventsNotifications}
                    onChange={(event, { checked }) => {
                      setFieldValue('allowEventsNotifications', checked)
                      setFieldTouched('allowEventsNotifications')
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput title="Event notifications email" name="eventNotificaionsEmail" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Demo booth page</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput title="Page" name="boothNumber" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Divider horizontal>Metadata</Divider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <TextInput title="Keywords" name="metadata.keywords" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <TextArea title="Custom code" name="metadata.customCode" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => resetForm(initialValues)}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

const FloorSelection = ({
  sponsorFloors,
  updateSponsorFloor,
  sponsorId,
}: {
  sponsorFloors: ISponsorFloor[]
  sponsorId: string
  updateSponsorFloor: (payload: IUpdateSponsorFloors) => any
}) => {
  const savedFloors = useMemo(() => {
    const usedFloors = sponsorFloors.filter(floor => {
      const includesId = floor.sponsorsOrders
        .filter(order => order.id.includes(sponsorId))
        .filter(Boolean)
      return includesId.length ? true : false
    })
    return usedFloors.map(floor => floor.id)
  }, [sponsorFloors])

  const [floors, setFloors] = useState(savedFloors)
  const floorOptions = useMemo(() => floorsMap(sponsorFloors), [sponsorFloors])

  useEffect(() => {
    setFloors(savedFloors)
  }, [savedFloors])

  const handleUpdateFloor = async () => {
    const payload = {
      ids: floors,
      sponsorId,
    }
    await updateSponsorFloor(payload)
  }
  return (
    <Grid.Row>
      <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
        <Select
          multiple
          selection
          value={floors}
          title="Floor"
          options={floorOptions}
          onChange={(e, { value }) => setFloors(value as string[])}
        />
      </Grid.Column>
      <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
        <Button
          primary
          style={{ flexGrow: 0, width: 150 }}
          content="Update floors"
          onClick={handleUpdateFloor}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default EditVirtual
