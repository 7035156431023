import _ from 'lodash'
import React, { memo } from 'react'

export interface ITextRowProps {
  title: string
  value: any
  [key: string]: any
}

export const TextRow = ({ title, value, ...props }: ITextRowProps) => (
  <div className="field" {...props}>
    <label>{title}</label>
    <span>{_.isNil(value) ? <i>None</i> : value}</span>
  </div>
)

export default memo(TextRow)
