import SelectImageModal from 'components/select-image-modal/SelectImageModal'
import SelectImagePreview from 'components/select-image-preview/SelectImagePreview'
import { FastField, FieldProps } from 'formik'
import React, { memo, useCallback, useState } from 'react'

import './SelectImageInput.css'

interface IError {
  content: string
  pointing: 'below' | 'above'
}

interface ISelectImageInputPublicProps {
  disabled?: boolean
  error?: IError
  fluid?: boolean
  showGallery?: boolean
  height?: number
  name: string
  onChange?: (image: string) => any
  required?: boolean
  title?: string
  width?: number
}

type ISelectImageInputProps = FieldProps & ISelectImageInputPublicProps

export const SelectImageInput = ({
  disabled,
  error,
  field,
  fluid,
  form,
  showGallery = true,
  height = 200,
  onChange,
  required,
  title,
  width = 200,
}: ISelectImageInputProps) => {
  const [showImages, setShowImages] = useState(false)

  const hideImagesPopup = useCallback(() => {
    field.onBlur(field.name)
    setShowImages(false)
  }, [field])

  const onImageSelect = useCallback(
    (image: string) => {
      form.setFieldValue(field.name, image)
      if (onChange) {
        onChange(image)
      }
      hideImagesPopup()
    },
    [field, onChange, form, hideImagesPopup]
  )

  const onShowClick = useCallback(() => setShowImages(true), [setShowImages])
  const onHideClick = useCallback(() => setShowImages(false), [setShowImages])

  const onResetClick = useCallback(() => onImageSelect(''), [onImageSelect])

  return (
    <div
      className={`field ${error && error.content ? 'error' : ''}
        ${required ? 'required' : ''}
        ${disabled ? 'disabled' : ''}
        select-image-input`}
    >
      <label>{title}</label>
      {error && error.content ? (
        <div
          className={`ui pointing ${error.pointing} prompt label`}
          role="alert"
          aria-atomic="true"
        >
          {error.content}
        </div>
      ) : null}
      <SelectImagePreview
        fluid={fluid}
        width={width}
        height={height}
        image={field.value}
        onShowClick={onShowClick}
        onResetClick={onResetClick}
      />
      <SelectImageModal
        width={width}
        height={height}
        show={showImages}
        showGallery={showGallery}
        onHideClick={onHideClick}
        onImageSelect={onImageSelect}
      />
    </div>
  )
}

export const FormikSelectImageInput = (props: ISelectImageInputPublicProps) => (
  <FastField component={SelectImageInput} {...props} />
)

export default memo(FormikSelectImageInput)
