import { AxiosResponse } from 'axios'
import {
  IAbstractionsContractsQueriesForbiddenEmailDomainsForbiddenEmailDomainsResponse,
  IAbstractionsContractsQueriesForbiddenEmailDomainsForbiddenEmailDomainsResponseForbiddenEmailDomain,
  IApiControllersBackofficeMasterModelsForbiddenEmailDomainsCreateForbiddenEmailDomainModel,
  IApiControllersBackofficeMasterModelsForbiddenEmailDomainsCreateForbiddenEmailPrefixNameModel,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { buildQuery, IFilterConfig } from 'utils/filter'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type IForbiddenEmailsItem = IAbstractionsContractsQueriesForbiddenEmailDomainsForbiddenEmailDomainsResponseForbiddenEmailDomain

export type IForbiddenEmailDomain = IApiControllersBackofficeMasterModelsForbiddenEmailDomainsCreateForbiddenEmailDomainModel

export type IForbiddenEmailPrefix = IApiControllersBackofficeMasterModelsForbiddenEmailDomainsCreateForbiddenEmailPrefixNameModel

export default types
  .model('ForbiddenEmailsStore', {
    actionStatus: LoadingStatusType,
    fetchEmailsStatus: LoadingStatusType,
    forbiddenEmails: types.frozen<IForbiddenEmailsItem[]>([]),
    list: types.frozen<IForbiddenEmailsItem[]>([]),
    totalPages: types.optional(types.number, 0),
  })

  .actions(self => ({
    fetchForbiddenEmails: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/master/forbidden-email-domains?${buildQuery(filter)}`
        )) as AxiosResponse<
          IAbstractionsContractsQueriesForbiddenEmailDomainsForbiddenEmailDomainsResponse
        >
        self.forbiddenEmails = data.forbiddenEmailDomains
        self.totalPages = data.totalPages
      }),
      s => (self.fetchEmailsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch forbidden emails')
    ),
  }))

  .actions(self => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      addForbiddenEmailDomain: createAction((payload: IForbiddenEmailDomain) => {
        return axios.post('/backoffice/master/forbidden-email-domains', payload)
      }, 'Failed to add forbidden email (domain)'),

      addForbiddenEmailPrefix: createAction((payload: IForbiddenEmailPrefix) => {
        return axios.post('/backoffice/master/forbidden-email-domains/prefix-name', payload)
      }, 'Failed to add forbidden email (prefix)'),

      deleteForbiddenEmail: createAction((id: string) => {
        return axios.delete(`/backoffice/master/forbidden-email-domains/${id}`)
      }, 'Failed to remove forbidden email'),
    }
  })
