import ActionButtonWithPopup from 'components/action-button-with-popup/ActionButtonWithPopup'
import EmailActionButton from 'components/email-action-button/EmailActionButton'
import React, { memo } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'
import {
  IRegistrationItem,
  RegistrationStatuses,
  registrationStatusOptions,
} from 'stores/registrations'

export interface IAttendeeCardActionsList {
  approveAttendee: (id: string, onDone: () => any) => Promise<any>
  rejectAttendee: (id: string, onDone: () => any) => Promise<any>
  requestForPayment: (id: string, template: string, onDone: () => any) => Promise<any>
  requestForPhoto: (id: string, template: string) => Promise<any>
  sendAttendeeReminder: (id: string) => Promise<any>
  sendFeaturedAttendeeInvitation: (id: string, template: string) => Promise<any>
  unblockAttendee: (id: string, onDone: () => any) => Promise<any>
  updateIsInTop: (ids: string[], isInTop: boolean, onDone: () => any) => Promise<any>
  addToBlackList: (ids: string[], onDone: () => any) => Promise<any>
  changeStatus: (id: string, status: RegistrationStatuses, onDone: () => any) => Promise<any>
}

interface IAttendeeCardActionsProps extends IAttendeeCardActionsList {
  item: IRegistrationItem
  reloadBlackList: () => Promise<any>
  reloadList: () => Promise<any>
}

export const AttendeeCardActions = ({
  item,
  reloadList,
  reloadBlackList,
  sendFeaturedAttendeeInvitation,
  requestForPhoto,
  requestForPayment,
  sendAttendeeReminder,
  approveAttendee,
  rejectAttendee,
  unblockAttendee,
  updateIsInTop,
  addToBlackList,
  changeStatus,
}: IAttendeeCardActionsProps) => {
  const sendFeaturedInvitation = (template: string) =>
    sendFeaturedAttendeeInvitation(item.id, template)

  const requestPhoto = (template: string) => requestForPhoto(item.id, template)

  const requestPayment = (template: string) => requestForPayment(item.id, template, reloadList)

  const sendReminder = () => sendAttendeeReminder(item.id)

  const approve = () => approveAttendee(item.id, reloadList)

  const reject = () => rejectAttendee(item.id, reloadList)

  const unblock = () => unblockAttendee(item.id, reloadList)

  const addToTop = () => updateIsInTop([item.id], true, reloadList)

  const removeFromTop = () => updateIsInTop([item.id], false, reloadList)

  const addToBlackListClick = () =>
    addToBlackList([item.id], () => Promise.all([reloadList(), reloadBlackList()]))

  const setStatus = (status: RegistrationStatuses) => changeStatus(item.id, status, reloadList)

  const canSendRequestForPayment =
    item.registrationStatus !== RegistrationStatuses.PendingPackageSelection && !item.isVip

  return (
    <Button.Group>
      <Dropdown floating className="button" icon="bars" basic trigger={<></>} closeOnChange={true}>
        <Dropdown.Menu>
          <Dropdown.Item
            as={EmailActionButton}
            emailTemplatesCategory="FeaturedAttendeeInvitation"
            popupTitle="Select featured invite template"
            text="Send featured invitation"
            title="Send featured invitation"
            action={sendFeaturedInvitation}
          />
          <Dropdown.Item text="Ask for more info" onClick={sendReminder} />
          {item.registrationStatus === RegistrationStatuses.PendingApprove && (
            <Dropdown.Item text="Approve" onClick={approve} />
          )}
          {item.isInTop && <Dropdown.Item text="Remove from top" onClick={removeFromTop} />}
          {!item.isInTop && <Dropdown.Item text="Add to top" onClick={addToTop} />}
          {canSendRequestForPayment && (
            <Dropdown.Item
              as={EmailActionButton}
              emailTemplatesCategory="RequestForPayment"
              popupTitle="Select featured invite template"
              text="Select request for payment template"
              title="Select request for payment template"
              action={requestPayment}
            />
          )}
          <Dropdown.Item
            as={EmailActionButton}
            emailTemplatesCategory="AttendeeSubmitImage"
            popupTitle="Select request for payment template"
            text="Request for photo"
            title="Request for photo"
            action={requestPayment}
          />
          <Dropdown.Item
            as={EmailActionButton}
            emailTemplatesCategory="AttendeeSubmitImage"
            popupTitle="Select request for payment template"
            text="Request for photo"
            title="Request for photo"
            action={requestPhoto}
          />
          {item.registrationStatus !== RegistrationStatuses.Rejected && (
            <Dropdown.Item text="Reject user" onClick={reject} />
          )}
          {item.isBlocked && <Dropdown.Item text="Unblock user" onClick={unblock} />}
          {!item.blacklisted && (
            <Dropdown.Item text="Add to blacklist" onClick={addToBlackListClick} />
          )}
          <ActionButtonWithPopup
            color="blue"
            icon="cog"
            options={registrationStatusOptions}
            value={item.registrationStatus}
            title="Set status"
            popupTitle="Select status"
            selectPlaceholder="Status"
            action={v => setStatus(v as RegistrationStatuses)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  )
}

export default memo(AttendeeCardActions)
