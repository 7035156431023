import { ISelectOption } from 'components/inputs'
import PopupWithSelect from 'components/popup-with-select/PopupWithSelect'
import React, { useCallback, useState } from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'
import { LoadingStatus } from 'utils/store'

interface IEmailActionButtonProps {
  color: SemanticCOLORS
  icon: string
  popupTitle: string
  selectPlaceholder: string
  title: string

  loadingStatus?: LoadingStatus
  action: (value: string) => Promise<any>
  options: ISelectOption[]
  value: string
}

export const EmailActionButton = ({
  icon,
  title,
  options,
  color,
  selectPlaceholder,
  value,
  popupTitle,
  action,
  loadingStatus,
}: IEmailActionButtonProps) => {
  const [visiblePopup, setVisiblePopup] = useState(false)

  const hidePopup = useCallback(() => {
    setVisiblePopup(false)
  }, [setVisiblePopup])

  const showPopup = useCallback(() => {
    setVisiblePopup(true)
  }, [setVisiblePopup])

  const callAction = useCallback(
    async (newV: string) => {
      await action(newV)
      hidePopup()
    },
    [action, hidePopup]
  )

  return (
    <>
      <div className="item" onClick={showPopup}>
        {title}
      </div>
      <PopupWithSelect
        loadingStatus={loadingStatus}
        visible={visiblePopup}
        options={options}
        value={value}
        title={popupTitle}
        placeholder={selectPlaceholder}
        onHide={hidePopup}
        onSubmit={callAction}
      />
    </>
  )
}

export default EmailActionButton
