import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  Accordion,
  Button,
  Divider,
  DropdownProps,
  Grid,
  Header,
  Icon,
  Select,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ISettingsSponsorItem, ISponsorSection } from 'stores/settings'
import { ISponsorItem, ISponsorType } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'

interface ISectionItem {
  activeSection: number
  appendSponsors: (values: string[]) => any
  availableSponsors: (values: ISettingsSponsorItem[]) => any
  dragDisabled?: boolean
  onDragEnd: (result: any, index: number) => any
  sectionIndex: number
  sectionTitle: string
  setActiveSession: (index: number) => any
  sponsorById: (id: string) => any
  sponsors: ISettingsSponsorItem[]
  showManage: boolean
  setShowManage: (value: boolean) => any
  onSponsorRemove: (sectionIndex: number, id: string) => any
}

const SectionItem = ({
  activeSection,
  appendSponsors,
  availableSponsors,
  dragDisabled,
  onDragEnd,
  sectionIndex,
  sectionTitle,
  setActiveSession,
  sponsorById,
  sponsors,
  showManage,
  setShowManage,
  onSponsorRemove,
}: ISectionItem) => {
  const [itemsToAdd, setItemsToAdd] = useState<string[]>([])

  const sponsorsDetailed = useMemo(
    () => sponsors.map(entry => sponsorById(entry.id)).filter(Boolean),
    // eslint-disable-next-line
    [sponsors]
  )

  const handleDragEnd = (result: any) => {
    onDragEnd(result, sectionIndex)
  }

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    setItemsToAdd(data.value as string[])

  const handleAppend = () => {
    appendSponsors(itemsToAdd)
    setItemsToAdd([])
  }

  const isActive = activeSection === sectionIndex

  const availableItems = availableSponsors(sponsors)

  const availableOptions = useMemo(
    () =>
      availableItems.map((item: any) => ({
        key: item.id,
        text: item.company,
        value: item.id,
      })),
    [availableItems]
  )

  const { isMobile } = useWindowDimensions()

  if (dragDisabled) {
    return (
      <Accordion className="ui segment vertical">
        <Accordion.Title
          index={sectionIndex}
          active={isActive}
          onClick={() => setActiveSession(isActive ? -1 : sectionIndex)}
        >
          <Header as="h5">
            <Icon name="dropdown" />
            {sectionTitle}
            <Icon
              name="arrows alternate vertical"
              className="drag"
              size="tiny"
              color="grey"
              style={{ cursor: 'grab' }}
            />
          </Header>
        </Accordion.Title>
        <Accordion.Content active={isActive} content="" />
      </Accordion>
    )
  }

  return (
    <div className="ui segment vertical">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="dropable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Accordion>
                <Accordion.Title index={sectionIndex} active={isActive}>
                  <Header as="h5" onClick={() => setActiveSession(isActive ? -1 : sectionIndex)}>
                    <Icon name="dropdown" />
                    {sectionTitle}
                  </Header>
                  {isActive && (
                    <Button
                      active={showManage}
                      basic={!showManage}
                      color={showManage ? 'blue' : undefined}
                      icon="delete"
                      size="tiny"
                      onClick={() => setShowManage(!showManage)}
                      style={{ boxShadow: 'none' }}
                    />
                  )}
                </Accordion.Title>
                <Accordion.Content active={isActive}>
                  {sponsorsDetailed.length > 0
                    ? sponsorsDetailed.map((sponsor, index) => (
                        <Draggable key={sponsor.id} draggableId={sponsor.id} index={index}>
                          {(itemProvided, itemSnapshot) => (
                            <div
                              className="draggable-item ui vertical segment"
                              ref={itemProvided.innerRef}
                              {...itemProvided.draggableProps}
                              {...itemProvided.dragHandleProps}
                            >
                              <Header as="h5">
                                {sponsor.company}
                                {showManage ? (
                                  <Icon
                                    name="delete"
                                    size="tiny"
                                    color="red"
                                    onClick={() => onSponsorRemove(sectionIndex, sponsor.id)}
                                  />
                                ) : (
                                  <Icon name="arrows alternate vertical" size="tiny" color="grey" />
                                )}
                              </Header>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  <Divider hidden />
                  {provided.placeholder}
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column mobile={8} tablet={8} desktop={11} verticalAlign="middle">
                        <Select
                          fluid
                          search
                          multiple
                          placeholder="Select sponsors to add..."
                          options={availableOptions}
                          onChange={handleSelect}
                        />
                      </Grid.Column>
                      <Grid.Column
                        mobile={8}
                        tablet={8}
                        desktop={5}
                        textAlign="right"
                        verticalAlign="middle"
                      >
                        <Button
                          primary
                          fluid={isMobile}
                          basic
                          content="Add to section"
                          onClick={handleAppend}
                          disabled={!itemsToAdd.length}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Divider hidden />
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

interface IEditSponsorDisplay {
  availableSponsors: (values: ISettingsSponsorItem[]) => any
  fetchAllSponsors: () => Promise<any>
  fetchSettings: () => Promise<any>
  fetchSponsorsTypes: () => Promise<any>
  allSponsors: ISponsorItem[]
  sponsorsSections: () => ISponsorSection[]
  loading: LoadingStatus
  sponsorTypes: ISponsorType[]
  sponsorById: (id: string) => ISponsorItem | null
  sponsorTypeById: (id: string) => ISponsorType | null
  saveSettings: (sections: ISponsorSection[], onDone: () => any) => Promise<any>
}

interface ISection {
  id: string
  title: string
  order: number
  sponsors: ISettingsSponsorItem[]
}

export const EditSponsorDisplay = ({
  allSponsors,
  availableSponsors,
  fetchAllSponsors,
  fetchSettings,
  fetchSponsorsTypes,
  loading,
  sponsorTypes,
  sponsorById,
  sponsorsSections,
  sponsorTypeById,
  saveSettings,
}: IEditSponsorDisplay) => {
  const [sections, setSections] = useState<ISection[]>() || []
  const [activeSection, setActiveSession] = useState(-1)
  const [touched, setTouched] = useState(false)
  const [showManage, setShowManage] = useState(false)

  const sectionDragEnabled = activeSection === -1

  const fetchAll = () => {
    fetchSettings()
    fetchAllSponsors()
    fetchSponsorsTypes()
  }

  useEffect(() => {
    fetchAll()
    // eslint-disable-next-line
  }, [])

  const displaySections = (sponsorSections: ISponsorSection[]) => {
    const newSections = [] as ISection[]
    // eslint-disable-next-line
    sponsorTypes.map(type => {
      const section =
        sponsorSections &&
        (sponsorSections.find(section => section.id === type.id) as ISponsorSection)
      const newSection = {
        id: type.id,
        order: section ? section.order : 0,
        sponsors: section ? _.sortBy(section.sponsors, 'order') : [],
        title: type.title,
      } as ISection
      newSections.push(newSection)
    })
    return _.sortBy(newSections, 'order')
  }

  useEffect(() => {
    if (loading === LoadingStatus.success) {
      setSections(displaySections(sponsorsSections()))
    }
    // eslint-disable-next-line
  }, [loading])

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleSectionDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    let newSections = reorder(
      sections,
      result.source.index,
      result.destination.index
    ) as ISection[]
    newSections = newSections.map((section, index) => ({ ...section, order: index }))
    setSections(newSections)
    setTouched(true)
  }

  const handleAppendSponsors = (values: any) => {
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[activeSection].sponsors
      const newItems = values.map((value: string, index: number) => ({
        id: value,
        order: 0,
      }))
      const newSection = _.uniqBy([...targetSection, ...newItems], 'id').map(
        (item: ISponsorSection, index) => ({
          id: item.id,
          order: index,
        })
      )
      newSections[activeSection].sponsors = newSection
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleRemoveSponsors = (sectionIndex: number, id: string) => {
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[activeSection].sponsors
      let newSection = targetSection.filter(item => id !== item.id)
      newSection = newSection.map((item: any, index) => ({
        id: item.id,
        order: index,
      }))
      newSections[activeSection].sponsors = newSection
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleSponsorDragEnd = (result: any, index: number) => {
    if (!result.destination) {
      return
    }
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[index].sponsors
      let newSponsors = reorder(
        targetSection,
        result.source.index,
        result.destination.index
      ) as any
      newSponsors = newSponsors.map((sponsor: ISettingsSponsorItem, index: number) => ({
        ...sponsor,
        order: index,
      }))
      newSections[index].sponsors = newSponsors
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleSave = async () => {
    setShowManage(false)
    if (sections) {
      await saveSettings(sections, () => fetchSettings())
      await fetchSponsorsTypes()
      setTouched(false)
    }
  }

  const sectionTitle = (id: string) => {
    const result = sponsorTypeById(id)
    return result?.title || ''
  }

  if (!sections || !allSponsors) {
    return null
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {sectionDragEnabled && (
            <DragDropContext onDragEnd={handleSectionDragEnd}>
              <Droppable droppableId="dropable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.map((section, index) => (
                      <Draggable key={section.id} draggableId={section.id} index={index}>
                        {(itemProvided, itemSnapshot) => (
                          <div
                            ref={itemProvided.innerRef}
                            {...itemProvided.draggableProps}
                            {...itemProvided.dragHandleProps}
                          >
                            <SectionItem
                              availableSponsors={availableSponsors}
                              appendSponsors={handleAppendSponsors}
                              dragDisabled={true}
                              activeSection={activeSection}
                              sectionIndex={index}
                              setActiveSession={setActiveSession}
                              sponsors={section.sponsors}
                              sectionTitle={sectionTitle(section.id)}
                              onDragEnd={handleSponsorDragEnd}
                              sponsorById={sponsorById}
                              showManage={showManage}
                              setShowManage={setShowManage}
                              onSponsorRemove={handleRemoveSponsors}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {!sectionDragEnabled &&
            sections.map((section, index) => (
              <SectionItem
                availableSponsors={availableSponsors}
                appendSponsors={handleAppendSponsors}
                key={index}
                activeSection={activeSection}
                sectionIndex={index}
                setActiveSession={setActiveSession}
                sponsors={section.sponsors}
                sectionTitle={sectionTitle(section.id)}
                onDragEnd={handleSponsorDragEnd}
                sponsorById={sponsorById}
                showManage={showManage}
                setShowManage={setShowManage}
                onSponsorRemove={handleRemoveSponsors}
              />
            ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {/* <Button content="Cancel" onClick={fetchAll}/> */}
          <Button
            primary
            width={3}
            content="Save"
            onClick={handleSave}
            disabled={!touched}
            loading={loading === LoadingStatus.pending}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const EditSponsorDisplayForm = observer(() => {
  const { fetchSettings, sponsorsSections, fetchSettingsStatus, saveSettings } = useRootData(
    store => store.settingsStore
  )
  const {
    allSponsors,
    availableSponsors,
    fetchAllSponsors,
    fetchSponsorsStatus,
    fetchSponsorsTypes,
    fetchTypesStatus,
    sponsorTypes,
    sponsorById,
    sponsorTypeById,
  } = useRootData(store => store.sponsorsStore)
  const loading = combineLoadingStatus([fetchSettingsStatus, fetchTypesStatus, fetchSponsorsStatus])
  return (
    <EditSponsorDisplay
      {...{
        allSponsors,
        availableSponsors,
        fetchAllSponsors,
        fetchSettings,
        fetchSponsorsTypes,
        loading,
        saveSettings,
        sponsorById,
        sponsorsSections,
        sponsorTypeById,
        sponsorTypes,
      }}
    />
  )
})

export default EditSponsorDisplayForm
