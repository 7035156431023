import { Field, FieldProps } from 'formik'
import React, { memo } from 'react'
import { Form, FormSelectProps } from 'semantic-ui-react'

export interface ISelectOption {
  key: string
  text: string
  value: string
}

export interface ISelectInputPublicProps extends FormSelectProps {
  name: string
  title: string
  options: ISelectOption[]
  required?: boolean
  onChange?: (value: any) => any
}

type ISelectInputProps = FieldProps & ISelectInputPublicProps

export const SelectInput = ({
  field,
  form,
  title,
  onChange,
  required,
  ...props
}: ISelectInputProps) => (
  <Form.Select
    {...field}
    {...props}
    fluid
    required={required}
    label={title}
    onChange={(e, data) => {
      form.setFieldValue(field.name, data.value)
      form.setFieldTouched(field.name)
      if (onChange) {
        onChange(data.value)
      }
    }}
    onBlur={() => field.onBlur(field.name)}
    placeholder={title}
  />
)

export default memo((props: ISelectInputPublicProps) => (
  <Field component={SelectInput} {...props} />
))
