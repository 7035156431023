import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootData } from 'stores'
import { SAML_URL, AUTH_TYPE } from 'utils/config'

export default observer(() => {
  const { accessToken } = useRootData(store => store.appStore)
  const history = useHistory()

  useEffect(() => {
    if (accessToken) {
      history.replace('/')
    }
    // eslint-disable-next-line
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) {
      if (AUTH_TYPE === 'saml_no_request') {
        window.location.href = SAML_URL
      } else {
        console.error('Saml request not supported, use "saml_no_request"')
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="saml-wrapper">
      <div className="saml-message">We are redirecting you to the auth provider</div>
    </div>
  )
})
