import { IFormValues } from 'components/edit-sponsor-layout/EditSponsorLayout'
import { SelectInput, TextInput } from 'components/inputs'
import { ILayout, sizes } from 'components/scene-preview/ScenePreview'
import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { FormikActions, FormikProps } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { Button, Checkbox, Divider, Grid, Header, Segment } from 'semantic-ui-react'
import {
  bannerTypes,
  IAttachContentToBanner,
  IAttachStyles,
  IBoothType,
  IFullDocumentsItem,
} from 'stores/sponsors'
import tinyColor from 'tinycolor2'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import { REACT_APP_ROLE } from 'utils/config'

interface IEditSponsorBannerProps extends FormikProps<IFormValues> {
  actionStatus: LoadingStatus
  boothTypes: IBoothType[]
  content: IFullDocumentsItem[]
  currentLayout: ILayout
  contentById: (id: string) => IFullDocumentsItem | null
  initialValues: IFormValues
  attachContentToBanner: (payload: IAttachContentToBanner[]) => Promise<any>
  attachStyles: (payload: IAttachStyles[]) => Promise<any>
  saveSponsor: (payload: any) => any
}

const bannerKeys = ['contentId', 'id', 'imageUrl', 'sponsorId', 'title']

export const EditSponsorBanner = ({
  actionStatus,
  content,
  contentById,
  values,
  touched,
  resetForm,
  boothTypes,
  currentLayout,
  initialValues,
  setFieldValue,
  setFieldTouched,
  setSubmitting,
  attachContentToBanner,
  attachStyles,
  saveSponsor,
}: IEditSponsorBannerProps) => {
  const { isMobile } = useWindowDimensions()
  const contentOptions = useMemo(
    () =>
      content.map(entry => ({
        key: entry.id,
        text: entry.title,
        type: entry.fullContentType,
        value: entry.id,
      })),
    [content]
  )

  const handleSubmit = useCallback(
    (values: IFormValues, { resetForm }: Partial<FormikActions<IFormValues>>) => {
      if (values.banners) {
        attachContentToBanner(
          values.banners.map(banner => ({
            bannerId: banner.bannerId,
            id: banner.contentId,
            imageUrl: banner.imageUrl,
            showBannerPlaceholder: banner.showBannerPlaceholder,
            sponsorId: values.sponsor.id,
            title: banner.title,
          }))
        )
      }
      if (values.layers) {
        attachStyles(
          values.layers.map(layer => ({
            color: tinyColor(layer.color).toHex(),
            layerId: layer.layerId,
            opacity: layer.opacity,
            sponsorId: values.sponsor.id,
          }))
        )
      }
      saveSponsor({
        ...values.sponsor,
        boothLayout: values.layout.name
      })
      if (resetForm) {
        resetForm({ ...initialValues, banners: values.banners })
      }
    },
    [attachContentToBanner, attachStyles, initialValues, saveSponsor]
  )

  const filteredContentOptions = (filter: string | null) =>
    filter ? contentOptions.filter(option => option.type === filter) : contentOptions

  return (
    <>
      <Segment vertical clearing className="banners-list">
        {values.banners.map((banner, index) => {
          return (
            <Grid stackable key={index}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Header as="h4" dividing>
                    <Header.Content>Banner #{banner.bannerId}</Header.Content>
                    <Header.Subheader>
                      Image size: {sizes.banners[banner.bannerId].width}×{sizes.banners[banner.bannerId].height}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={5} tablet={5} computer={5} verticalAlign="top">
                  <SelectImageInput
                    name={`banners[${index}].imageUrl`}
                    width={300}
                    showGallery={REACT_APP_ROLE === 'regular'}
                    height={160}
                    onChange={event => {
                      setFieldValue(`banners[${index}].imageUrl`, event)
                      setFieldTouched(`banners[${index}].imageUrl`)
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={11} verticalAlign="middle">
                  <TextInput title="Caption" name={`banners[${index}].title`} fluid={true} />
                  <Divider hidden />
                  <Grid stretched stackable>
                    <Grid.Row>
                      <Grid.Column mobile={8} tablet={8} computer={6} verticalAlign="middle">
                        <SelectInput
                          name={`filters[${index}]`}
                          title="Type"
                          options={bannerTypes}
                          clearable
                          selection
                          onChange={event => {
                            setFieldValue(`filters[${index}]`, event)
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={8} computer={10} verticalAlign="middle">
                        <SelectInput
                          name={`banners[${index}].contentId`}
                          title="Attach Documents"
                          clearable
                          selection
                          options={filteredContentOptions(values.filters[index] || null)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column mobile={8} tablet={8} computer={6} verticalAlign="middle">
                        <Checkbox
                          label="Show banner placeholder"
                          name={`banners[${index}].showBannerPlaceholder`}
                          checked={banner.showBannerPlaceholder}
                          onChange={(event, { checked }) => {
                            setFieldValue(`banners[${index}].showBannerPlaceholder`, checked)
                            setFieldTouched(`banners[${index}].showBannerPlaceholder`)
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        mobile={8}
                        tablet={8}
                        computer={10}
                        verticalAlign="middle"
                        textAlign="right"
                      >
                        <Button
                          content="Clear banner"
                          basic
                          color="blue"
                          style={{ marginLeft: 'auto', width: 100 }}
                          size="mini"
                          disabled={banner && !banner.contentId}
                          onClick={() => {
                            bannerKeys.forEach(key => {
                              setFieldValue(`banners[${index}]${key}`, '')
                              setFieldTouched(`banners[${index}]${key}`)
                            })
                            setFieldValue(`banners[${index}].showBannerPlaceholder`, false)
                            setFieldTouched(`banners[${index}].showBannerPlaceholder`)
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )
        })}
      </Segment>
      <Divider hidden />
      <Grid stretched stackable>
        <Grid.Row>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Button
              type="submit"
              fluid={isMobile}
              width={3}
              primary
              onClick={() => handleSubmit(values, { setSubmitting, resetForm })}
              loading={actionStatus === LoadingStatus.pending}
              content="Save"
              size="large"
              disabled={!Object.keys(touched).length}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Button
              secondary
              basic
              fluid={isMobile}
              type="button"
              onClick={() => resetForm(initialValues)}
              content="Reset"
              size="large"
              disabled={!Object.keys(touched).length}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
