import React, { useState, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Menu, Dropdown, Divider, Header, Sticky } from 'semantic-ui-react'
import { useRootData } from 'stores'
import useWindowDimensions from 'utils/dimensions'
import { ISponsorTag } from 'stores/sponsors'
import { IExhibitorItem, IUpdateExhibitorItem } from 'stores/exhibitor'
import { LoadingStatus } from 'utils/store'
import EditSponsorLayout from 'components/edit-sponsor-layout/EditSponsorLayout'
import EditSponsorDocuments from 'components/edit-sponsor-documents/EditSponsorDocuments'
import EditSponsorLinks from 'components/edit-sponsor-links/EditSponsorLinks'
import EditSponsorLogos from 'components/edit-sponsor-logos/EditSponsorLogos'
import EditExhibitorBasic from 'components/edit-exhibitor-basic/EditExhibitorBasic'
import './EditExhibitorForm.css'

const tabKeys = ['Basic', 'Wizard', 'Logos', 'Links', 'Documents']

interface IExhibitorFormProps {
  actionStatus: LoadingStatus
  item: IExhibitorItem
  sponsorTags: ISponsorTag[]
  fetchExhibitor: () => Promise<any>
  saveExhibitor: (payload: IUpdateExhibitorItem, onDone: () => any) => Promise<any>
}

const ExhibitorForm = ({
  actionStatus,
  item,
  fetchExhibitor,
  saveExhibitor,
  sponsorTags,
}: IExhibitorFormProps) => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search.slice(1))
  const defaultTab = queryParams.get('tab')?.toString()
  const [activeTab, setActiveTab] = useState(defaultTab || 'Basic')
  const handleMenu = (value: string) => {
    setActiveTab(value)
    history.push(`${location.pathname}?${value}`)
  }
  const { isMobile } = useWindowDimensions()

  const tabs = () => (
    <Sticky context={ref} offset={20}>
      <Menu tabular style={{ backgroundColor: '#fff' }}>
        <Menu.Item className="menu-header">
          <Header as="h3">{item.company}</Header>
        </Menu.Item>
        {tabKeys.map((tab, index) => (
          <Menu.Item
            as={Link}
            to={`${location.pathname}?tab=${tab}`}
            name={tab}
            key={index}
            onClick={event => handleMenu(tab)}
            active={activeTab === tab}
          >
            {tab}
          </Menu.Item>
        ))}
      </Menu>
    </Sticky>
  )

  const menu = () => (
    <Menu tabular borderless style={{ backgroundColor: '#fff' }}>
      <Menu.Item className="menu-header">
        <Header as="h3">{item.company}</Header>
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown item trigger={activeTab} simple size="big">
          <Dropdown.Menu>
            {tabKeys.map((tab, index) => (
              <Dropdown.Item
                name={tab}
                key={index}
                onClick={() => handleMenu(tab)}
                active={activeTab === tab}
              >
                {tab}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  )

  const updateExhibitor = (payload: IUpdateExhibitorItem) => {
    saveExhibitor(payload, fetchExhibitor)
  }

  const renderItem = (activeTab?: string) => {
    switch (activeTab) {
      case 'Basic':
        return (
          <EditExhibitorBasic
            item={item}
            sponsorTags={sponsorTags}
            saveExhibitor={updateExhibitor}
            actionStatus={actionStatus}
          />
        )
      case 'Wizard':
        return (
          <EditSponsorLayout
            item={item}
            saveSponsor={updateExhibitor}
            fetchSponsor={fetchExhibitor}
          />
        )
      case 'Logos':
        return (
          <EditSponsorLogos
            item={item}
            saveSponsor={updateExhibitor}
            actionStatus={actionStatus}
          />
        )
      case 'Documents':
        return <EditSponsorDocuments item={item} />
      case 'Links':
        return <EditSponsorLinks item={item} />
    }
  }

  return (
    <div className="sponsor" ref={ref}>
      {isMobile ? menu() : tabs()}
      <Divider hidden />
      <>{renderItem(activeTab)}</>
    </div>
  )
}

interface IEditExhibitorFormProps extends IEditExhibitorFormInjectedProps {
  actionStatus: LoadingStatus
  item: IExhibitorItem
  saveExhibitor: (payload: IUpdateExhibitorItem, onDone: () => any) => Promise<any>
  fetchExhibitor: () => Promise<any>
  sponsorTags: ISponsorTag[]
}

export const EditExhibitorForm = ({
  actionStatus,
  item,
  fetchExhibitor,
  saveExhibitor,
  sponsorTags,
}: IEditExhibitorFormProps) => {
  return (
    <ExhibitorForm
      actionStatus={actionStatus}
      item={item}
      sponsorTags={sponsorTags}
      saveExhibitor={saveExhibitor}
      fetchExhibitor={fetchExhibitor}
    />
  )
}

interface IEditExhibitorFormInjectedProps {
  fetchExhibitor: () => Promise<any>
  item: IExhibitorItem
}

export const EditExhibitorFormInjected = observer((props: IEditExhibitorFormInjectedProps) => {
  const { actionStatus, fetchExhibitor, fetchExhibitorStatus, saveExhibitor } = useRootData(
    store => store.exhibitorStore
  )
  const { sponsorTags } = useRootData(store => store.sponsorsStore)
  return (
    <EditExhibitorForm
      {...props}
      {...{
        actionStatus,
        fetchExhibitor,
        fetchExhibitorStatus,
        saveExhibitor,
        sponsorTags,
      }}
    />
  )
})

export default EditExhibitorFormInjected
