import _ from 'lodash'
import {
  IAbstractionsContractsQueriesSettingsResponse,
  IAbstractionsContractsCommandsSponsorsSettingsUpsertSponsorsSettingCommand,
} from 'api-types'
import { AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  createLoadingStatusType,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type IUpdateSettings = IAbstractionsContractsCommandsSponsorsSettingsUpsertSponsorsSettingCommand

export interface ISponsorSection {
  id: string
  order: number
  sponsors: ISettingsSponsorItem[]
}

export interface ISettingsSponsorItem {
  id: string
  order: number
}

export interface ISettings {
  sponsorsSetting: {
    displaySettingsObject: {
      sections: ISponsorSection[]
    }
    displaySettings: {
      sections: ISponsorSection[]
    }
  }
  [key: string]: any
}

export default types
  .model('SettingsStore', {
    actionStatus: createLoadingStatusType(LoadingStatus.success),
    fetchSettingsStatus: LoadingStatusType,
    settings: types.frozen<ISettings>({} as ISettings),
  })

  .views(self => ({
    sponsorsSections: () => self.settings.sponsorsSetting.displaySettingsObject.sections,
  }))

  .actions(self => ({
    fetchSettings: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get('/public/settings')) as AxiosResponse<
          IAbstractionsContractsQueriesSettingsResponse
        >
        self.settings = data.setting as ISettings
      }),
      s => (self.fetchSettingsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch settings')
    ),
  }))

  .actions(self => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      saveSettings: createAction((sections: ISponsorSection[]) => {
        const payload = _.cloneDeep(self.settings.sponsorsSetting)
        // eslint-disable-next-line
        payload['displaySettings'] = {
          sections,
        }
        return axios.put('/backoffice/media-partners/settings', payload)
      }, 'Failed to save display sponsors'),
    }
  })
