import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

interface IMenuLinkProps {
  name: string | null
  url?: string | null
  route: string
  onClick?: () => void
}

export const MenuLink = ({ name, url = null, route, onClick }: IMenuLinkProps) => {
  const { pathname } = useLocation()
  const isHomeRoute = pathname === url
  const isExternalUrl = url && /^https?:\/\//.test(url as string)
  const matchedRoute = url !== null && url.length > 1 ? pathname.includes(url) : false
  if (!name) {
    return null
  }
  if (isExternalUrl) {
    return (
      <Menu.Item
        as='a'
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </Menu.Item>
    )
  }
  return (
    <Menu.Item
      as={Link}
      to={url ? url : route}
      active={isHomeRoute || matchedRoute}
      onClick={onClick}
    >
      {name}
    </Menu.Item>
  )
}

export default MenuLink
