import {
  IAbstractionsContractsCommandsAttendeeTypesCreateAttendeeTypeCommand,
  IAbstractionsContractsCommandsAttendeeTypesUpdateAttendeeTypeCommand,
  IAbstractionsContractsCommandsSponsorsAttachAvatarCommand,
  IAbstractionsContractsCommandsSponsorsAttachSponsorContentToBannerContentCommand,
  IAbstractionsContractsCommandsSponsorsCreateSponsorCommand,
  IAbstractionsContractsCommandsSponsorsDraftSponsorsCommand,
  IAbstractionsContractsCommandsSponsorsLayerStyleCommand,
  IAbstractionsContractsCommandsSponsorsPublishSponsorsCommand,
  IAbstractionsContractsCommandsSponsorsUpdateSponsorCommand,
  IAbstractionsContractsCommandsSponsorTypesCreateSponsorTypeCommand,
  IAbstractionsContractsCommandsSponsorTypesUpdateSponsorTypeCommand,
  IAbstractionsContractsCommandsTagsCreateTagCommand,
  IAbstractionsContractsCommandsTagsUpdateTagCommand,
  IAbstractionsContractsModelsAvatar,
  IAbstractionsContractsModelsBanner,
  IAbstractionsContractsModelsLayer,
  IAbstractionsContractsModelsLayerStyle,
  IAbstractionsContractsModelsSponsorContent,
  IAbstractionsContractsModelsSponsorLink,
  IAbstractionsContractsModelsSponsorOrder,
  IAbstractionsContractsModelsVirtualAvatar,
  IAbstractionsContractsModelsVirtualBanner,
  IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponse,
  IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponseAttendeeType,
  IAbstractionsContractsQueriesBoothBoothAvatarsResponse,
  IAbstractionsContractsQueriesBoothFilteredBoothTypesResponse,
  IAbstractionsContractsQueriesBoothFilteredBoothTypesResponseBoothType,
  IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponse,
  IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponseSponsorFloor,
  IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse,
  IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponseSponsor,
  IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponse,
  IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponseSponsorType,
  IAbstractionsContractsQueriesTagsTagsWithPaginationResponse,
  IAbstractionsContractsQueriesTagsTagsWithPaginationResponseTag,
  IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponse,
  IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponseVipAttendee,
  IAbstractionsContractsQueriesVipInvitationsVipInvitationResponse,
  IAbstractionsServicesSponsorsImportParsedSponsorModel,
  IApiControllersBackofficeSponsorsModelsAddSponsorsInvitationsModel,
  IApiControllersBackofficeSponsorsModelsCreateSponsorContentLinkModel,
  IApiControllersBackofficeSponsorsModelsCreateSponsorContentModel,
  IApiControllersBackofficeSponsorsModelsUpdateFloorManySponsorOrderModel,
  IApiControllersBackofficeSponsorsModelsUpdateSponsorContentLinkModel,
  IApiControllersBackofficeSponsorsModelsUpdateSponsorContentModel,
  IApiControllersBackofficeSponsorsModelsUpdateSponsorToFloorModel,
  IApiControllersBackofficeSponsorsModelsCreateVipAttendeeAsRepresentativeModel,
} from 'api-types'
import { AxiosError, AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery, buildQuery, IFilterConfig } from 'utils/filter'
import { isValidEmail } from 'utils/is-email'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  createLoadingStatusType,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

import { REACT_APP_ROLE } from 'utils/config'

import { ISettingsSponsorItem } from './settings'

// eslint-disable-next-line
import { ISelectOption } from 'components/inputs'

export type IAddContentItem = IApiControllersBackofficeSponsorsModelsCreateSponsorContentModel
export type IAddLinkItem = IApiControllersBackofficeSponsorsModelsCreateSponsorContentLinkModel
export type IAddSponsorItem = IAbstractionsContractsCommandsSponsorsCreateSponsorCommand
export type IAttachAvatar = IAbstractionsContractsCommandsSponsorsAttachAvatarCommand
export type IAttachStyles = IAbstractionsContractsCommandsSponsorsLayerStyleCommand
export type IAvatarItem = IAbstractionsContractsQueriesBoothBoothAvatarsResponse
export type ISponsorAvatarItem = IAbstractionsContractsModelsVirtualAvatar
export type IBannerItem = IAbstractionsContractsModelsVirtualBanner
export type IBoothType = IAbstractionsContractsQueriesBoothFilteredBoothTypesResponseBoothType
export type IBoothTypesResponse = IAbstractionsContractsQueriesBoothFilteredBoothTypesResponse
export type IBoothAvatars = IAbstractionsContractsModelsAvatar
export type IBoothLayer = IAbstractionsContractsModelsLayer
export type IContentItem = IAbstractionsContractsModelsSponsorContent
export type IDraftStatusSponsors = IAbstractionsContractsCommandsSponsorsDraftSponsorsCommand
export type ILinkItem = IAbstractionsContractsModelsSponsorLink
export type IPublishStatusSponsors = IAbstractionsContractsCommandsSponsorsPublishSponsorsCommand
export type ISaveLinkItem = IUpdateLinkItem
export type ISaveSponsorItem = IUpdateSponsorItem
export type ISponsorItem = IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponseSponsor
export type ISponsorTag = IAbstractionsContractsQueriesTagsTagsWithPaginationResponseTag
export type ISponsorTier = IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponseAttendeeType
export type ISponsorType = IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponseSponsorType
export type ITemplateBanner = IAbstractionsContractsModelsBanner
export type IUpdateContentItem = IApiControllersBackofficeSponsorsModelsUpdateSponsorContentModel
export type IUpdateLinkItem = IApiControllersBackofficeSponsorsModelsUpdateSponsorContentLinkModel
export type IUpdateSponsorItem = IAbstractionsContractsCommandsSponsorsUpdateSponsorCommand
export type IImportSponsorItem = IAbstractionsServicesSponsorsImportParsedSponsorModel
export type IPreviewSponsorItem = IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponseSponsor
export type ICreateSponsorTypeItem = IAbstractionsContractsCommandsSponsorTypesCreateSponsorTypeCommand
export type IUpdateSponsorTypeItem = IAbstractionsContractsCommandsSponsorTypesUpdateSponsorTypeCommand
export type ICreateSponsorTierItem = IAbstractionsContractsCommandsAttendeeTypesCreateAttendeeTypeCommand
export type IUpdateSponsorTierItem = IAbstractionsContractsCommandsAttendeeTypesUpdateAttendeeTypeCommand
export type IInvitaionItem = IApiControllersBackofficeSponsorsModelsAddSponsorsInvitationsModel
export type IInvitationStatusItem = IAbstractionsContractsQueriesVipInvitationsVipInvitationResponse
export type ISponsorFloor = IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponseSponsorFloor
export type IUpdateFloorItem = IApiControllersBackofficeSponsorsModelsUpdateFloorManySponsorOrderModel
export type IUpdateSponsorFloors = IApiControllersBackofficeSponsorsModelsUpdateSponsorToFloorModel
export type ISponsorFloorOrder = IAbstractionsContractsModelsSponsorOrder
export type IResponseAttendee = IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponseVipAttendee
export type ISponsorAttendee = IApiControllersBackofficeSponsorsModelsCreateVipAttendeeAsRepresentativeModel
export type ICreateSponsorTagItem = IAbstractionsContractsCommandsTagsCreateTagCommand
export type IUpdateSponsorTagItem = IAbstractionsContractsCommandsTagsUpdateTagCommand

export interface IWithFullTypeContent {
  fullContentType: string
}

export type IFullContentItem = IContentItem & IWithFullTypeContent
export type IFullLinkItem = ILinkItem & IWithFullTypeContent
export type IFullDocumentsItem = IFullContentItem | IFullLinkItem

export type IFullBanner = ITemplateBanner & IBannerItem

// eslint-disable-next-line
const hackWithInterface: IFullDocumentsItem = {} as IFullDocumentsItem
export type IBannerDocumentType = typeof hackWithInterface.fullContentType
export type ILayerType = IAbstractionsContractsModelsLayerStyle

export type IAttachContentToBanner = IAbstractionsContractsCommandsSponsorsAttachSponsorContentToBannerContentCommand

export interface IAllSponsorsResponse {
  sponsorTypes: ISponsorType[]
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
}

export interface IAttachLayoutItem {
  id: string
  boothLayout: string
}

export interface IBannerTypeOption extends ISelectOption {
  value: string
}

const isRegularUser = REACT_APP_ROLE === 'regular'
const API_PATH = isRegularUser ? 'backoffice' : 'vip-admin'

export const bannerTypes = [
  {
    key: 'document',
    text: 'Document',
    value: 'Content_Document',
  },
  {
    key: 'video',
    text: 'Video',
    value: 'Content_Video',
  },
  {
    key: 'link',
    text: 'Link',
    value: 'Link_External',
  },
  {
    key: 'email',
    text: 'Email',
    value: 'Link_Email',
  },
  {
    key: 'facebook',
    text: 'Facebook',
    value: 'Link_Facebook',
  },
  {
    key: 'Twitter',
    text: 'Twitter',
    value: 'Link_Twitter',
  },
  {
    key: 'instagram',
    text: 'Instagram',
    value: 'Link_Instagram',
  },
] as IBannerTypeOption[]

export default types
  .model('SponsorsStore', {
    actionSponsorStatus: LoadingStatusType,
    actionStatus: createLoadingStatusType(LoadingStatus.success),
    allSponsors: types.frozen<ISponsorItem[]>([]),
    attachContentToBannerStatus: LoadingStatusType,
    attachContentToTabStatus: LoadingStatusType,
    attachLinkToBannerStatus: LoadingStatusType,
    attachLinkToTabStatus: LoadingStatusType,
    attendees: types.frozen<ISponsorAttendee[]>([]),
    avatarImages: types.frozen<string[]>([]),
    boothTypes: types.frozen<IBoothType[]>([]),
    content: types.frozen<IFullContentItem[]>([]),
    currentSponsor: types.frozen(),
    emailExists: types.optional(types.boolean, false),
    fetchAllContentStatus: LoadingStatusType,
    fetchAttendeesStatus: LoadingStatusType,
    fetchAvatarsStatus: LoadingStatusType,
    fetchBoothTypesStatus: LoadingStatusType,
    fetchContentStatus: LoadingStatusType,
    fetchFloorsStatus: LoadingStatusType,
    fetchInvitationsStatus: LoadingStatusType,
    fetchLinksStatus: LoadingStatusType,
    fetchSponsorTypesStatus: LoadingStatusType,
    fetchSponsorsStatus: LoadingStatusType,
    fetchTagsStatus: LoadingStatusType,
    fetchTiersStatus: LoadingStatusType,
    fetchTypesStatus: LoadingStatusType,
    importErrors: types.frozen(),
    importSponsorsStatus: LoadingStatusType,
    invitationStatus: types.frozen(),
    lastEmailToCheck: types.optional(types.string, ''),
    links: types.frozen<IFullLinkItem[]>([]),
    saveContentStatus: LoadingStatusType,
    saveLinkStatus: LoadingStatusType,
    saveSponsorStatus: LoadingStatusType,
    sponsorFloors: types.frozen<ISponsorFloor[]>([]),
    sponsorTags: types.frozen<ISponsorTag[]>([]),
    sponsorTagsByPage: types.frozen<IUpdateSponsorTagItem[]>([]),
    sponsorTagsPages: types.optional(types.number, 0),
    sponsorTiers: types.frozen<ISponsorTier[]>([]),
    sponsorTiersPages: types.optional(types.number, 0),
    sponsorTypes: types.frozen<ISponsorType[]>([]),
    sponsorsByPage: types.frozen<ISponsorItem[]>([]),
    totalPages: types.optional(types.number, 0),
  })

  .views(self => ({
    availableSponsors: (values: ISettingsSponsorItem[]) => {
      const exludeIds = values.map(item => item.id)
      return self.allSponsors.filter(item => !exludeIds.includes(item.id))
    },
    contentById: (id: string) =>
      self.content.find(c => c.id === id) || self.links.find(c => c.id === id) || null,
    sponsorById: (id: string) => self.allSponsors.find(c => c.id === id) || null,
    sponsorTypeById: (id: string) => self.sponsorTypes.find(c => c.id === id) || null,
  }))

  .actions(self => {
    const checkEmailExists = flow(function*(email: string) {
      let match
      if (email && isValidEmail(email) && email !== self.lastEmailToCheck) {
        try {
          yield axios.get(`/public/email/check-exist?email=${email}`) as Promise<any>
          match = true
        } catch (error) {
          match = false
        }
        return match
      }
      self.lastEmailToCheck = email
    })
    return { checkEmailExists }
  })

  .actions(self => ({
    fetchSponsorsTypes: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(`/backoffice/media-partners/types?${allQuery}`)) as {
          data: IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponse
        }
        self.sponsorTypes = data.sponsorTypes
      }),
      s => (self.fetchTypesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsors types')
    ),

    fetchSponsorsTiers: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          `/backoffice/attendee-type?${allQuery}`
        )) as AxiosResponse<IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponse>
        self.sponsorTiers = data.attendeeTypes
        self.sponsorTiersPages = data.totalPages
      }),
      s => (self.fetchTiersStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsor types')
    ),

    fetchSponsorsTiersByPage: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/attendee-type?${buildQuery(filter)}`
        )) as AxiosResponse<IAbstractionsContractsQueriesAttendeeTypesFilteredAttendeeTypesResponse>
        self.sponsorTiers = data.attendeeTypes
        self.sponsorTiersPages = data.totalPages
      }),
      s => (self.fetchTiersStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch attendee types')
    ),

    fetchSponsorsTags: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(`/public/tag?${allQuery}`)) as AxiosResponse<
          IAbstractionsContractsQueriesTagsTagsWithPaginationResponse
        >
        self.sponsorTags = data.tags
        self.sponsorTagsPages = data.totalPages
      }),
      s => (self.fetchTagsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsor tags')
    ),

    fetchSponsorsTagsByPage: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(`/public/tag?${buildQuery(filter)}`)) as AxiosResponse<
          IAbstractionsContractsQueriesTagsTagsWithPaginationResponse
        >
        self.sponsorTagsByPage = data.tags.map((item: ISponsorTag) => ({
          displayName: item.displayName,
          firstDescriptionCollection: [],
          galleryId: item.gallery.id,
          id: item.id,
          secondDescriptionCollection: [],
          thirdDescriptionCollection: [],
        })) as IUpdateSponsorTagItem[]
        self.sponsorTagsPages = data.totalPages
      }),
      s => (self.fetchTagsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsor tags')
    ),

    fetchSponsorsFloors: createAxiosAction(
      flow(function*(filter?: IFilterConfig) {
        const params = filter ? buildQuery(filter) : allQuery
        const { data } = (yield axios.get(
          `/public/media-partners-floors?${params}`
        )) as AxiosResponse<IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponse>
        self.sponsorFloors = data.sponsorFloors
      }),
      s => (self.fetchFloorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsor floors')
    ),

    fetchBoothTypes: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          `/public/media-partners/booth-type?${allQuery}`
        )) as AxiosResponse<IBoothTypesResponse>
        self.boothTypes = data.boothTypes
      }),
      s => (self.fetchBoothTypesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch booth types')
    ),

    fetchContent: createAxiosAction(
      flow(function*(id: string) {
        const { data } = (yield axios.get(
          `/${API_PATH}/media-partners/content?sponsorId=${id}`
        )) as AxiosResponse<IContentItem[]>
        self.content = data.map((item: IContentItem) => ({
          ...item,
          fullContentType: `${item.contentType}_${item.documentType}`,
        })) as IFullContentItem[]
      }),
      s => (self.fetchContentStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch content')
    ),

    fetchLinks: createAxiosAction(
      flow(function*(id: string) {
        const { data } = (yield axios.get(
          `/${API_PATH}/media-partners/content/link?sponsorId=${id}`
        )) as AxiosResponse<IAbstractionsContractsModelsSponsorLink[]>
        self.links = data.map((item: ILinkItem) => ({
          ...item,
          fullContentType: `${item.contentType}_${item.linkType}`,
        })) as IFullLinkItem[]
      }),
      s => (self.fetchLinksStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch links')
    ),

    fetchAttendees: createAxiosAction(
      flow(function*(id: string) {
        const { data } = (yield axios.get(
          `/${API_PATH}/media-partners/representative?vipInvitationId=${id}`
        )) as AxiosResponse<IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponse>
        self.attendees = data.vipAttendees.map((item: IResponseAttendee) => ({
          chatRole: item.chatRole,
          country: item.country,
          countryCode: item.countryCode,
          email: item.email,
          firstName: item.firstName,
          id: item.id,
          imageUrl: item.imageUrl,
          lastName: item.lastName,
          localizations: item.localizations,
          phoneNumber: item.phoneNumber,
          position: item.position,
          vipInvitationId: item.vipInvitationId,
        })) as ISponsorAttendee[]
        // self.attendees = data.vipAttendees
      }),
      s => (self.fetchAttendeesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch attendees')
    ),

    fetchAvatars: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          `/public/media-partners/booth/avatar?${allQuery}`
        )) as AxiosResponse<IAbstractionsContractsQueriesBoothBoothAvatarsResponse>
        self.avatarImages = data.avatarsUrl
      }),
      s => (self.fetchAvatarsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch avatars')
    ),
  }))

  .actions(self => ({
    fetchAllContent: createAxiosAction(
      flow(function*(id: string) {
        // eslint-disable-next-line
        yield Promise.all([
          self.fetchLinks(id),
          self.fetchContent(id),
          self.fetchBoothTypes(),
          self.fetchAvatars(),
        ])
      }),
      s => (self.fetchAllContentStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch all content')
    ),
    fetchAllSponsorsTypes: createAxiosAction(
      flow(function*() {
        // eslint-disable-next-line
        yield Promise.all([
          self.fetchSponsorsTypes(),
          self.fetchSponsorsTiers(),
          self.fetchSponsorsTags(),
          self.fetchSponsorsFloors(),
        ])
      }),
      s => (self.fetchSponsorTypesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch all sponsors types')
    ),
    fetchExhibitorContent: createAxiosAction(
      flow(function*() {
        // eslint-disable-next-line
        yield Promise.all([self.fetchAvatars(), self.fetchBoothTypes(), self.fetchSponsorsTags()])
      }),
      s => (self.fetchAllContentStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch exhibitor content')
    ),

    resetSponsorsList() {
      self.sponsorsByPage = []
      self.fetchSponsorsStatus = LoadingStatus.not_loaded
    },

    resetImportErrors() {
      self.importErrors = undefined
    },

    resetCurrentSponsor() {
      self.currentSponsor = {}
    },

    fetchSponsors: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/media-partners?${buildQuery(filter)}`
        )) as AxiosResponse<IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse>
        self.sponsorsByPage = data.sponsors.map((item: ISponsorItem) => ({
          ...item,
        }))
        self.totalPages = data.totalPages
      }),
      s => (self.fetchSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsors')
    ),

    fetchAllSponsors: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          `/backoffice/media-partners?${allQuery}`
        )) as AxiosResponse<IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse>
        self.allSponsors = data.sponsors.map((item: ISponsorItem) => ({
          ...item,
        }))
      }),
      s => (self.fetchSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsors')
    ),

    fetchSponsor: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/backoffice/media-partners?${buildQuery(filter)}`
        )) as AxiosResponse<IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse>
        self.currentSponsor = data.sponsors[0]
      }),
      s => (self.fetchSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sponsor')
    ),

    createSponsor: createAxiosAction(
      flow(function*(payload: ISaveSponsorItem) {
        const { data } = (yield axios.post('/backoffice/media-partners', payload)) as AxiosResponse<
          IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse
        >
        self.currentSponsor = data
      }),
      s => (self.fetchSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to create sponsor')
    ),

    importSponsors: createAxiosAction(
      flow(function*(payload: IImportSponsorItem[]) {
        const { data } = (yield axios.post(
          '/backoffice/media-partners/import/excel',
          payload
        )) as AxiosResponse
        self.importErrors = data.Exceptions ? data.Exceptions : undefined
      }),
      s => (self.importSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to import sponsor')
    ),

    fetchInvitations: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          '/backoffice/registration/attendee/vip/getVipInvitations'
        )) as AxiosResponse<IAbstractionsContractsQueriesVipInvitationsVipInvitationResponse>
        self.invitationStatus = data
      }),
      s => (self.fetchInvitationsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch invitation status')
    ),
  }))

  .actions(self => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = (errorObject: AxiosError) => {
        const lines: string[] = [error]
        if (errorObject?.response?.data?.Message) {
          lines.push(`(${errorObject?.response?.data?.Message})`)
        }
        if (errorObject?.response?.data?.ValidationFailures) {
          ;(errorObject?.response?.data?.ValidationFailures as Array<{
            errorMessage?: string
          }>).forEach(obj => {
            if (obj.errorMessage) {
              lines.push(obj.errorMessage)
            }
          })
        }
        getParent<IRootStore>(self).showError(lines.join('\n'))
      }
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      saveSponsor: createAction((payload: ISaveSponsorItem) => {
        return axios.put('/backoffice/media-partners', payload)
      }, 'Failed to save sponsor'),

      deleteSponsor: createAction(
        (id: string) => axios.delete(`/backoffice/media-partners?command.SponsorsIds=${id}`),
        'Failed to delete sponsor'
      ),

      draftSponsor: createAction(
        (id: string) =>
          axios.put('/backoffice/media-partners/draft', { ids: [id] } as IDraftStatusSponsors),
        'Failed to draft sponsor'
      ),

      publishSponsor: createAction(
        (id: string) =>
          axios.put('/backoffice/media-partners/publish', {
            sponsorsIds: [id],
          } as IPublishStatusSponsors),
        'Failed to publish sponsor'
      ),

      saveContent: createAction((payload: IUpdateContentItem) => {
        const method = payload.id.length ? axios.put : axios.post
        return method(`/${API_PATH}/media-partners/content`, payload)
      }, 'Failed to save content'),

      updateContent: createAction(
        (payload: IUpdateContentItem) => axios.put(`/${API_PATH}/media-partners/content`, payload),
        'Failed to update content'
      ),

      deleteContent: createAction((payload: any) => {
        const { sponsorId, id } = payload
        return axios.delete(
          `/${API_PATH}/media-partners/content?command.SponsorId=${sponsorId}&command.Id=${id}`
        )
      }, 'Failed to delete content'),

      attachContentToBanner: createAction(
        (payload: IAttachContentToBanner[]) =>
          axios.post(`/${API_PATH}/media-partners/wizard/attach-to-banner`, payload),
        'Failed to attach content to banner'
      ),

      attachLayoutToSponsor: createAction(
        (payload: IAttachLayoutItem) => axios.put('/backoffice/media-partners', payload),
        'Failed to attach layout to sponsor'
      ),

      attachAvatar: createAction(
        (payload: IAttachAvatar[]) =>
          axios.post(`/${API_PATH}/media-partners/wizard/attach-avatar`, payload),
        'Failed to attach avatar to sponsor'
      ),

      attachStyles: createAction(
        (payload: IAttachStyles[]) =>
          axios.post(`/${API_PATH}/media-partners/wizard/layers-style`, payload),
        'Failed to attach layer style to sponsor'
      ),

      saveLink: createAction((payload: IUpdateLinkItem) => {
        const method = payload.id ? axios.put : axios.post
        return method(`/${API_PATH}/media-partners/content/link`, payload)
      }, 'Failed to save link'),

      updateLink: createAction(
        (payload: IUpdateLinkItem) =>
          axios.put(`/${API_PATH}/media-partners/content/link`, payload),
        'Failed to update link'
      ),

      deleteLink: createAction((payload: any) => {
        const { sponsorId, id } = payload
        return axios.delete(
          `/${API_PATH}/media-partners/content/link?command.Id=${id}&command.SponsorId=${sponsorId}`
        )
      }, 'Failed to delete link'),

      saveAttendee: createAction((payload: ISponsorAttendee) => {
        return axios.post('/backoffice/media-partners/representative', payload)
      }, 'Failed to save representative'),

      updateAttendee: createAction(
        (payload: ISponsorAttendee) =>
          axios.put('/backoffice/media-partners/representative', payload),
        'Failed to update representative'
      ),

      deleteAttendee: createAction((payload: any) => {
        const { id } = payload
        return axios.delete(`/backoffice/media-partners/representative/force?command.Ids=${id}`)
      }, 'Failed to delete content'),

      saveSponsorType: createAction((payload: IUpdateSponsorTypeItem) => {
        const method = payload.id ? axios.put : axios.post
        return method('/backoffice/media-partners/types', payload)
      }, 'Failed to save type'),

      deleteSponsorType: createAction((payload: any) => {
        const { id } = payload
        return axios.delete(`/backoffice/media-partners/types?command.Id=${id}`)
      }, 'Failed to delete type'),

      saveSponsorTag: createAction((payload: IUpdateSponsorTagItem) => {
        const method = payload.id ? axios.put : axios.post
        const { id, galleryId, ...rest } = payload
        const newPayload = {
          ...rest,
          ...(id && { id }),
        }
        return method('/backoffice/registration/tag', newPayload)
      }, 'Failed to save tag'),

      deleteSponsorTag: createAction((payload: any) => {
        const { id } = payload
        return axios.delete(`/backoffice/registration/tag?id=${id}`)
      }, 'Failed to delete tag'),

      saveSponsorTier: createAction((payload: IUpdateSponsorTierItem) => {
        const method = payload.id ? axios.put : axios.post
        return method('/backoffice/attendee-type', payload)
      }, 'Failed to save tier'),

      deleteSponsorTier: createAction((payload: any) => {
        const { id } = payload
        return axios.delete(`/backoffice/attendee-type?id=${id}`)
      }, 'Failed to delete tier'),

      updateFloors: createAction((payload: IUpdateFloorItem[]) => {
        return axios.patch('/backoffice/media-partners/floors', payload)
      }, 'Failed to save floors'),

      updateSponsorFloor: createAction((payload: IUpdateSponsorFloors) => {
        return axios.put('/backoffice/media-partners/floors/update-sponsor', payload)
      }, 'Failed to update sponsor floors'),

      createInvitation: createAction((payload: IInvitaionItem) => {
        return axios.post('/backoffice/media-partners/invitation', payload)
      }, 'Failed to create invitation'),

      resendPasswordLink: createAction((email: string) => {
        const payload = { email }
        return axios.post('/public/forgot-password/request', payload)
      }, 'Failed to resend password link'),
    }
  })
