import { useObserver } from 'mobx-react-lite'
import React from 'react'

import RootStore from './root'

export const createStore = () => RootStore.create({})

export const storeContext = React.createContext<typeof RootStore.Type | null>(null)

export const useStoreData = <Selection, ContextData, Store>(
  context: React.Context<ContextData>,
  storeSelector: (contextData: ContextData) => Store,
  dataSelector: (store: Store) => Selection
) => {
  const value = React.useContext(context)
  if (!value) {
    throw new Error('React context does not exits!')
  }
  const store = storeSelector(value)
  return useObserver(() => dataSelector(store))
}

export const useRootData = <Selection>(dataSelector: (store: typeof RootStore.Type) => Selection) =>
  useStoreData(storeContext, contextData => contextData!, dataSelector)
