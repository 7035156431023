import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Dropdown, Header, Menu, Sticky } from 'semantic-ui-react'
import { Button, Divider } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ICompanyCategoryItem } from 'stores/companies'
import { IForbiddenEmailsItem } from 'stores/forbiddenEmails'
import { IPackageItem } from 'stores/packages'
import {
  IAttendeeType,
  IPatchPackageForAttendee,
  IPutPackageForAttendee,
  IPutPackageForVipAttendee,
  IRegistrationItem,
  ISaveRegistrationItem,
  ISaveVipRegistrationItem,
  RegistrationStatuses,
} from 'stores/registrations'
import { countries } from 'utils/countries'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'

import EditAttendeeAdditional from 'components/edit-attendee-additional/EditAttendeeAdditional'
import EditAttendeeBasic from 'components/edit-attendee-basic/EditAttendeeBasic'
import EditAttendeePackage from 'components/edit-attendee-package/EditAttendeePackage'

const tabKeys = ['Basic', 'Package', 'Additional']

interface IEditAttendeeFormInjectedProps {
  item: IRegistrationItem
  fetchItem?: () => Promise<any>
  // reloadList: () => any
  // onHide: () => any
}

interface IEditAttendeeFormProps extends IEditAttendeeFormInjectedProps {
  attendeeTypes: IAttendeeType[]
  companyCategories: ICompanyCategoryItem[]
  loadingDetailsStatus: LoadingStatus
  packages: IPackageItem[]
  actionStatus: LoadingStatus
  assignPackageForAttendee: (patch: IPatchPackageForAttendee) => Promise<any>
  fetchAttendeeTypes: () => Promise<any>
  fetchCompanyCategories: () => Promise<any>
  fetchPackages: () => Promise<any>
  saveAttendee: (item: ISaveRegistrationItem) => Promise<any>
  saveVipAttendee: (item: ISaveVipRegistrationItem) => Promise<any>
  submitPackageForAttendee: (pack: IPutPackageForAttendee) => Promise<any>
  submitPackageForVipAttendee: (pack: IPutPackageForVipAttendee) => Promise<any>
  fetchForbiddenEmails: (filter: IFilterConfig) => Promise<any>
  forbiddenEmails: IForbiddenEmailsItem[]
}

interface IEditFormValues extends IRegistrationItem {
  selectedPackageId: string | null
  messagesLimit: number
}

const getCurrentPackage = (v: IEditFormValues, packages: IPackageItem[]) =>
  packages.find(p => p.id === v.selectedPackageId) || v.package || v.invitationPackage

export const EditAttendeeForm = ({
  actionStatus,
  assignPackageForAttendee,
  attendeeTypes,
  companyCategories,
  fetchAttendeeTypes,
  fetchCompanyCategories,
  fetchForbiddenEmails,
  fetchItem,
  fetchPackages,
  forbiddenEmails,
  item,
  loadingDetailsStatus,
  packages,
  saveAttendee,
  saveVipAttendee,
  submitPackageForAttendee,
  submitPackageForVipAttendee,
}: IEditAttendeeFormProps) => {
  const ref = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search.slice(1))
  const defaultTab = queryParams.get('tab')?.toString()
  const [activeTab, setActiveTab] = useState(defaultTab || 'Basic')

  const handleMenu = (value: string) => {
    setActiveTab(value)
    history.push(`${location.pathname}?${value}`)
  }

  const { isMobile } = useWindowDimensions()

  const initialPackage = getCurrentPackage(item as IEditFormValues, packages)

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
      messagesLimit: initialPackage ? initialPackage.messagesLimit : 0,
      selectedPackageId: null,
    }),
    [item, initialPackage]
  )

  useEffect(() => {
    fetchPackages()
    fetchAttendeeTypes()
    fetchCompanyCategories()
    fetchForbiddenEmails({
      itemsOnPage: 9999,
      page: 0,
    })
  }, [fetchPackages, fetchAttendeeTypes, fetchCompanyCategories, fetchForbiddenEmails])

  const handleSubmit = async (values: IEditFormValues) => {
    const payload = {
      ..._.cloneDeep(values),
      messageLimit: values.messagesLimit,
    } as ISaveRegistrationItem

    const foundCountry = countries.find(c => c.code === payload.countryCode)
    if (foundCountry) {
      payload.country = foundCountry.name
    }

    if (values.isVip) {
      await saveVipAttendee(payload as ISaveVipRegistrationItem)
    } else {
      await saveAttendee(payload as ISaveRegistrationItem)
    }

    const selectedPackage = getCurrentPackage(values, packages)
    if (selectedPackage && values.selectedPackageId) {
      if (values.registrationStatus === RegistrationStatuses.PendingPackageSelection) {
        await assignPackageForAttendee({
          attendeeId: values.id,
          packageId: selectedPackage.id,
          promoCode: '',
          ticketsCount: 0,
        })
      }
    }

    if (
      selectedPackage &&
      (selectedPackage.messagesLimit !== values.messagesLimit || values.selectedPackageId)
    ) {
      const newPack = {
        ...selectedPackage,
        messagesLimit: values.messagesLimit,
      }
      if (values.isVip) {
        await submitPackageForVipAttendee({
          ...newPack,
          vipAttendeeId: values.id,
        })
      } else {
        await submitPackageForAttendee({
          ...newPack,
          attendeeId: values.id,
          packageId: newPack.id,
        })
      }
    }
    if (fetchItem) {
      fetchItem()
    }
  }

  const tabs = () => (
    <Sticky context={ref} offset={20}>
      <Menu tabular style={{ backgroundColor: '#fff' }}>
        <Menu.Item as={Link} className="back-button" to="/registrations/" icon="left chevron" />
        {tabKeys.map((tab, index) => (
          <Menu.Item
            as={Link}
            to={`${location.pathname}?tab=${tab}`}
            name={tab}
            key={index}
            onClick={event => handleMenu(tab)}
            active={activeTab === tab}
          >
            {tab}
          </Menu.Item>
        ))}
      </Menu>
    </Sticky>
  )

  const menu = () => (
    <Menu tabular borderless style={{ backgroundColor: '#fff' }}>
      <Menu.Item>
        <Button as={Link} className="back-button" to="/registrations/" icon="left chevron" basic />
      </Menu.Item>
      <Menu.Item className="menu-header">
        <Header as="h3">
          {item.firstName} {item.lastName}
        </Header>
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown item trigger={activeTab} simple size="big">
          <Dropdown.Menu>
            {tabKeys.map((tab, index) => (
              <Dropdown.Item
                name={tab}
                key={index}
                onClick={() => handleMenu(tab)}
                active={activeTab === tab}
              >
                {tab}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  )

  const renderItem = (tab?: string) => {
    switch (tab) {
      case 'Basic':
        return (
          <EditAttendeeBasic
            actionStatus={actionStatus}
            item={initialValues}
            onSubmit={handleSubmit}
            companyCategories={companyCategories}
            forbiddenEmails={forbiddenEmails}
          />
        )
      case 'Package':
        return (
          <EditAttendeePackage
            actionStatus={actionStatus}
            item={initialValues}
            packages={packages}
            onSubmit={handleSubmit}
          />
        )
      case 'Additional':
        return (
          <EditAttendeeAdditional
            actionStatus={actionStatus}
            item={initialValues}
            attendeeTypes={attendeeTypes}
            onSubmit={handleSubmit}
          />
        )
    }
  }

  return (
    <div className="sponsor" ref={ref}>
      {isMobile ? menu() : tabs()}
      <Divider hidden />
      {renderItem(activeTab)}
    </div>
  )
}

export const EditAttendeeFormInjected = observer((props: IEditAttendeeFormInjectedProps) => {
  const {
    actionStatus,
    attendeeTypes,
    fetchAttendeeTypes,
    fetchTypesStatus,
    submitPackageForAttendee,
    submitPackageForVipAttendee,
    assignPackageForAttendee,
    saveAttendee,
    saveVipAttendee,
  } = useRootData(store => store.registrationsStore)

  const { fetchForbiddenEmails, forbiddenEmails } = useRootData(store => store.forbiddenEmailsStore)

  const { enabledCategories, fetchCategoriesStatus, fetchCompanyCategories } = useRootData(
    store => store.companiesStore
  )

  const { fetchPackages, fetchPackagesStatus, packages } = useRootData(store => store.packagesStore)

  const loadingDetailsStatus = combineLoadingStatus([
    fetchTypesStatus,
    fetchCategoriesStatus,
    fetchPackagesStatus,
  ])
  return (
    <EditAttendeeForm
      {...props}
      {...{
        actionStatus,
        assignPackageForAttendee,
        attendeeTypes,
        fetchAttendeeTypes,
        fetchCompanyCategories,
        fetchForbiddenEmails,
        fetchPackages,
        forbiddenEmails,
        loadingDetailsStatus,
        packages,
        saveAttendee,
        saveVipAttendee,
        submitPackageForAttendee,
        submitPackageForVipAttendee,
      }}
      companyCategories={enabledCategories()}
    />
  )
})

export default EditAttendeeFormInjected
