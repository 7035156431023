import ActionButtonWithPopup from 'components/action-button-with-popup/ActionButtonWithPopup'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { emailTemplatesToOptions } from 'stores/emailTemplates'

interface IEmailActionButtonProps {
  color: SemanticCOLORS
  emailTemplatesCategory: string
  icon: string
  popupTitle: string
  title: string

  action: (template: string) => Promise<any>
}

export const EmailActionButton = observer(
  ({ icon, title, color, popupTitle, emailTemplatesCategory, action }: IEmailActionButtonProps) => {
    const {
      status: emailTemplatesStatus,
      getEmailTemplatesByCategory,
      fetchEmailTemplates,
    } = useRootData(store => store.emailTemplatesStore)
    const emailTemplates = getEmailTemplatesByCategory(emailTemplatesCategory)

    useEffect(() => {
      fetchEmailTemplates()
      // eslint-disable-next-line
    }, [])

    return (
      <ActionButtonWithPopup
        loadingStatus={emailTemplatesStatus}
        color={color}
        icon={icon}
        options={emailTemplatesToOptions(emailTemplates)}
        value={emailTemplates.length > 0 ? emailTemplates[0].templateKey : ''}
        title={title}
        popupTitle={popupTitle}
        selectPlaceholder="Email template"
        action={action}
      />
    )
  }
)

export default EmailActionButton
