import { TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import {
  Button,
  Confirm,
  Form,
  Header,
  Input,
  Menu,
  Modal,
  Pagination,
  Sticky,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IBlackListItem } from 'stores/blacklist'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

interface IFormProps {
  tag: string
}

const initialValues = {
  tag: '',
} as IFormProps

interface IBlacklistProps {
  addTag: (tag: string, onDone: () => any) => Promise<any>
  deleteTag: (id: string, onDone: () => any) => Promise<any>
  fetchBlackList: (filter: IFilterConfig) => Promise<any>
  fetchBlackListStatus: LoadingStatus
  blackList: IBlackListItem[]
  actionStatus: LoadingStatus
  totalPages: number
}

export const Blacklist = ({
  fetchBlackList,
  fetchBlackListStatus,
  addTag,
  blackList,
  deleteTag,
  actionStatus,
  totalPages,
}: IBlacklistProps) => {
  const ref = useRef(null)
  const { addToast } = useToasts()
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [actionType, setActionType] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const { isMobile } = useWindowDimensions()

  const loadedTypes = fetchBlackListStatus === LoadingStatus.success

  const filters = useMemo(
    () => ({
      itemsOnPage: 20,
      page: page - 1,
      sort: {
        desc: true,
        field: 'Title',
      },
    }),
    // eslint-disable-next-line
    [page]
  )

  useEffect(() => {
    fetchBlackList(filters)
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (actionStatus === 'success' && actionType !== '') {
      addToast(`Ip ${actionType === 'add' ? 'added to' : 'removed from'} blacklist`, {
        appearance: 'info',
        autoDismiss: true,
      })
      setActionType('')
    }
  }, [actionType, actionStatus, setActionType, addToast])

  const handleModal = async () => {
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    handleModal()
  }

  const handleSubmit = async (
    values: IFormProps,
    { resetForm }: Partial<FormikActions<IFormProps>>
  ) => {
    setActionType('add')
    await addTag(values.tag, () => fetchBlackList(filters))
    if (resetForm) {
      resetForm()
    }
    handleModal()
  }

  const handleDelete = async (id: string) => {
    setActionType('delete')
    await deleteTag(id, () => fetchBlackList(filters))
  }

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedTypes
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedTypes, setPage]
  )

  const filteredList = blackList.filter(item => item.tag && item.tag.indexOf(search) >= 0)

  if (fetchBlackListStatus === LoadingStatus.not_loaded) {
    return null
  }

  return (
    <div ref={ref}>
      <Sticky context={ref} offset={isMobile ? 0 : 20}>
        <Menu tabular style={{ backgroundColor: '#fff', paddingBottom: 20 }} borderless>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            render={props => {
              const { values, touched, setSubmitting, resetForm } = props
              return (
                <Modal
                  centered={false}
                  dimmer="inverted"
                  size="tiny"
                  trigger={
                    <Button primary onClick={() => handleModal()} size="tiny">
                      Add name
                    </Button>
                  }
                  open={showModal}
                  onClose={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : handleModal()
                  }}
                  closeOnDimmerClick={true}
                >
                  <Modal.Header>Add name</Modal.Header>
                  <Modal.Content>
                    <Form as={FormikForm} autoComplete="off">
                      <TextInput title="Name" name="tag" />
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      fluid={isMobile}
                      type="button"
                      onClick={() => {
                        Object.keys(touched).length ? setShowConfirm(true) : handleModal()
                      }}
                      content="Cancel"
                    />
                    <Button
                      type="submit"
                      fluid={isMobile}
                      width={3}
                      primary
                      disabled={!values.tag.length}
                      loading={actionStatus === LoadingStatus.pending}
                      onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                      content="Save"
                    />
                  </Modal.Actions>
                </Modal>
              )
            }}
          />
          <Menu.Item position="right" style={{ position: 'relative', paddingRight: 0 }}>
            <Input
              value={search}
              onChange={event => setSearch(event.target.value)}
              placeholder="Search"
            />
            {search.length ? (
              <Button basic className="clear" icon="delete" onClick={() => setSearch('')} />
            ) : null}
          </Menu.Item>
        </Menu>
      </Sticky>

      <Table size="small" basic="very">
        <Table.Body>
          {filteredList.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h5">{entry.tag}</Header>
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                <Button basic color="red" icon="delete" onClick={() => handleDelete(entry.id)} />
              </Table.Cell>
            </Table.Row>
          ))}
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={2} textAlign="center">
                  <Pagination
                    defaultActivePage={page}
                    onPageChange={handlePaginationChange}
                    totalPages={totalPages}
                    firstItem={false}
                    lastItem={false}
                    prevItem={false}
                    nextItem={false}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table.Body>
      </Table>

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </div>
  )
}

export default observer(() => {
  const {
    fetchBlackList,
    blackList,
    fetchBlackListStatus,
    addTag,
    deleteTag,
    actionStatus,
    totalPages,
  } = useRootData(store => store.blacklistStore)
  return (
    <Blacklist
      {...{
        actionStatus,
        addTag,
        blackList,
        deleteTag,
        fetchBlackList,
        fetchBlackListStatus,
        totalPages,
      }}
    />
  )
})
