import { ISelectOption } from 'components/inputs/SelectInput'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Loader, Modal, Transition } from 'semantic-ui-react'
import { LoadingStatus } from 'utils/store'

interface IPopupWithSelectProps {
  title: string
  placeholder: string
  visible: boolean
  options: ISelectOption[]
  loadingStatus?: LoadingStatus
  value: string

  onHide: () => any
  onSubmit: (template: string) => any
}

export const PopupWithSelect = ({
  visible,
  title,
  onHide,
  placeholder,
  onSubmit,
  value,
  loadingStatus,
  options,
}: IPopupWithSelectProps) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <Transition unmountOnHide={true} duration={300} animation="fade down" visible={visible}>
      <Modal dimmer="inverted" size="tiny" open={visible}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          {(!loadingStatus || loadingStatus === LoadingStatus.success) && (
            <Dropdown
              value={currentValue}
              onChange={(e, data) => setCurrentValue((data.value || '').toString())}
              selection
              fluid
              placeholder={placeholder}
              options={options}
            />
          )}
          {loadingStatus && loadingStatus !== LoadingStatus.success && (
            <Loader inline="centered" active>
              Loading
            </Loader>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onHide}>Cancel</Button>
          <Button disabled={!currentValue} primary onClick={() => onSubmit(currentValue)}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </Transition>
  )
}

export default PopupWithSelect
