import { RadioGroup, TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import {
  Button,
  Confirm,
  Form,
  Header,
  Input,
  Menu,
  Modal,
  Pagination,
  Sticky,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import {
  IForbiddenEmailDomain,
  IForbiddenEmailPrefix,
  IForbiddenEmailsItem,
} from 'stores/forbiddenEmails'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

interface IForbiddenEmailsProps {
  addForbiddenEmailDomain: (payload: IForbiddenEmailDomain, onDone: () => any) => Promise<any>
  addForbiddenEmailPrefix: (payload: IForbiddenEmailPrefix, onDone: () => any) => Promise<any>
  deleteForbiddenEmail: (id: string, onDone: () => any) => Promise<any>
  fetchForbiddenEmails: (filter: IFilterConfig) => Promise<any>
  forbiddenEmails: IForbiddenEmailsItem[]
  fetchEmailsStatus: LoadingStatus
  actionStatus: LoadingStatus
  totalPages: number
}

interface IEmailFormProps {
  email: string
  type: 'Domain' | 'Prefix'
}

const initialValues = {
  email: '',
  type: 'Domain',
} as IEmailFormProps

export const ForbiddenEmails = ({
  forbiddenEmails,
  deleteForbiddenEmail,
  fetchEmailsStatus,
  fetchForbiddenEmails,
  addForbiddenEmailDomain,
  addForbiddenEmailPrefix,
  actionStatus,
  totalPages,
}: IForbiddenEmailsProps) => {
  const ref = useRef(null)
  const { addToast } = useToasts()
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [actionType, setActionType] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const description = 'From mobile BO'

  const loadedTypes = fetchEmailsStatus === LoadingStatus.success

  const filters = useMemo(
    () => ({
      itemsOnPage: 20,
      page: page - 1,
      sort: {
        desc: true,
        field: 'Title',
      },
    }),
    [page]
  )

  useEffect(() => {
    fetchForbiddenEmails(filters)
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (actionStatus === 'success' && actionType !== '') {
      addToast(
        `Entry ${actionType === 'add' ? 'added to' : 'removed from'} forbidden emails list`,
        {
          appearance: 'info',
          autoDismiss: true,
        }
      )
      setActionType('')
    }
  }, [actionType, actionStatus, setActionType, addToast])

  const handleModal = async () => {
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    handleModal()
  }

  const { isMobile } = useWindowDimensions()

  const handleSubmit = async (
    values: IEmailFormProps,
    { resetForm }: Partial<FormikActions<IEmailFormProps>>
  ) => {
    setActionType('add')
    if (values.type === 'Domain') {
      await addForbiddenEmailDomain(
        {
          description,
          domain: values.email,
        },
        () => fetchForbiddenEmails(filters)
      )
    }
    if (values.type === 'Prefix') {
      await addForbiddenEmailPrefix(
        {
          description,
          prefixName: values.email,
        },
        () => fetchForbiddenEmails(filters)
      )
    }
    if (resetForm) {
      resetForm()
    }
    handleModal()
  }

  const handleDelete = async (id: string) => {
    setActionType('delete')
    await deleteForbiddenEmail(id, () => fetchForbiddenEmails(filters))
  }

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedTypes
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedTypes, setPage]
  )

  const filteredList = forbiddenEmails.filter(item => {
    const target = item.prefixName || item.domain
    const result = target.indexOf(search) >= 0
    return result
  })

  if (fetchEmailsStatus === LoadingStatus.not_loaded) {
    return null
  }

  return (
    <div ref={ref}>
      <Sticky context={ref} offset={isMobile ? 0 : 20}>
        <Menu tabular style={{ backgroundColor: '#fff', paddingBottom: 20 }} borderless>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            render={props => {
              const {
                values,
                touched,
                setFieldValue,
                setFieldTouched,
                setSubmitting,
                resetForm,
              } = props
              return (
                <Modal
                  centered={false}
                  dimmer="inverted"
                  size="tiny"
                  trigger={
                    <Button primary onClick={() => handleModal()} size="tiny">
                      Add Email
                    </Button>
                  }
                  open={showModal}
                  onClose={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : handleModal()
                  }}
                  closeOnDimmerClick={true}
                >
                  <Modal.Header>Add email</Modal.Header>
                  <Modal.Content>
                    <Form as={FormikForm} autoComplete="off">
                      <RadioGroup
                        inline
                        name="type"
                        title="Type"
                        value={values.type}
                        options={[{ value: 'Domain' }, { value: 'Prefix' }]}
                        onChange={(event, data) => {
                          setFieldValue('type', data.value)
                          setFieldTouched('type')
                        }}
                      />
                      <TextInput title={values.type} name="email" />
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      fluid={isMobile}
                      type="button"
                      onClick={() => {
                        Object.keys(touched).length ? setShowConfirm(true) : handleModal()
                      }}
                      content="Cancel"
                    />
                    <Button
                      type="submit"
                      fluid={isMobile}
                      width={3}
                      primary
                      loading={actionStatus === LoadingStatus.pending}
                      onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                      content="Save"
                    />
                  </Modal.Actions>
                </Modal>
              )
            }}
          />
          <Menu.Item position="right" style={{ position: 'relative', paddingRight: 0 }}>
            <Input
              value={search}
              onChange={event => setSearch(event.target.value)}
              placeholder="Search"
            />
            {search.length ? (
              <Button basic className="clear" icon="delete" onClick={() => setSearch('')} />
            ) : null}
          </Menu.Item>
        </Menu>
      </Sticky>

      <Table size="small" basic="very">
        <Table.Body>
          {filteredList.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h5">
                  {entry.prefixName || ''}@{entry.domain}
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                <Button basic color="red" icon="delete" onClick={() => handleDelete(entry.id)} />
              </Table.Cell>
            </Table.Row>
          ))}
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={2} textAlign="center">
                  <Pagination
                    defaultActivePage={page}
                    onPageChange={handlePaginationChange}
                    totalPages={totalPages}
                    firstItem={false}
                    lastItem={false}
                    prevItem={false}
                    nextItem={false}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table.Body>
      </Table>

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </div>
  )
}

export default observer(() => {
  const {
    addForbiddenEmailDomain,
    addForbiddenEmailPrefix,
    actionStatus,
    deleteForbiddenEmail,
    fetchForbiddenEmails,
    fetchEmailsStatus,
    forbiddenEmails,
    totalPages,
  } = useRootData(store => store.forbiddenEmailsStore)
  return (
    <ForbiddenEmails
      {...{
        actionStatus,
        addForbiddenEmailDomain,
        addForbiddenEmailPrefix,
        deleteForbiddenEmail,
        fetchEmailsStatus,
        fetchForbiddenEmails,
        forbiddenEmails,
        totalPages,
      }}
    />
  )
})
