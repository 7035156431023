import { AvatarsPreview } from 'components/avatars-preview/AvatarsPreview'
import { EditSponsorBanner } from 'components/edit-sponsor-banner/EditSponsorBanner'
import { EditSponsorColor } from 'components/edit-sponsor-color/EditSponsorColor'
import { LayoutsPreview } from 'components/layouts-preview/LayoutsPreview'
import { ILayout, layouts, ScenePreview, sizes } from 'components/scene-preview/ScenePreview'
import { FieldArray, Form as FormikForm, Formik } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Divider, Form, Input } from 'semantic-ui-react'
import { useRootData } from 'stores'
import {
  IAttachAvatar,
  IAttachContentToBanner,
  IAttachLayoutItem,
  IAttachStyles,
  IBannerDocumentType,
  IBoothAvatars,
  IBoothType,
  IFullBanner,
  IFullContentItem,
  IFullDocumentsItem,
  IFullLinkItem,
  ILayerType,
  ISponsorItem,
} from 'stores/sponsors'
import { SITE } from 'utils/config'
import { mergeArrays } from 'utils/merge-arrays'
import { LoadingStatus } from 'utils/store'
import './EditSponsorLayout.css'

interface IColorItemProps {
  layer: ILayerType
  index: number
  setColor: (values: any) => Promise<any>
}

export interface IFormValues {
  avatar: string
  layout: ILayout
  banners: IFullBanner[]
  filters: Array<IBannerDocumentType | undefined>
  layers: ILayerType[]
  sponsor: ISponsorItem
}

interface IEditSponsorLayoutProps extends IEditSponsorLayoutFormInjectedProps {
  actionStatus: LoadingStatus
  attachAvatar: (payload: IAttachAvatar[]) => Promise<any>
  attachContentToBanner: (payload: IAttachContentToBanner[]) => Promise<any>
  attachLayoutToSponsor: (payload: IAttachLayoutItem) => Promise<any>
  attachStyles: (payload: IAttachStyles[]) => Promise<any>
  avatarImages: string[]
  boothTypes: IBoothType[]
  content: IFullContentItem[]
  contentById: (id: string) => IFullDocumentsItem | null
  fetchAvatars: () => Promise<any>
  fetchContent: (id: string) => Promise<any>
  fetchLinks: (id: string) => Promise<any>
  item: ISponsorItem
  links: IFullLinkItem[]
  fetchSponsor: () => Promise<any>
  saveSponsor: (payload: any) => any
}

const findLayout = (name: string) => layouts.find(item => item.name === name) || layouts[0]

const EditSponsorLayout = ({
  actionStatus,
  attachAvatar,
  attachContentToBanner,
  attachLayoutToSponsor,
  attachStyles,
  avatarImages,
  boothTypes,
  content,
  contentById,
  fetchAvatars,
  fetchContent,
  fetchLinks,
  item,
  links,
  fetchSponsor,
  saveSponsor,
}: IEditSponsorLayoutProps) => {
  useEffect(() => {
    if (item) {
      fetchSponsor()
      fetchLinks(item.id)
      fetchContent(item.id)
    }
    // eslint-disable-next-line
  }, [])

  const initialAvatarsValues: IBoothAvatars[] = useMemo(
    () =>
      mergeArrays(
        'avatarId',
        boothTypes.length ? _.map(boothTypes[0].avatars, _.clone) : [],
        item ? _.map(item.virtualAvatars, _.clone) : []
      ),
    [boothTypes, item]
  )

  const fullContent: IFullDocumentsItem[] = useMemo(() => [...content, ...links], [content, links])

  const initialValues: IFormValues = useMemo(
    () => ({
      avatar:
        Array.isArray(item.virtualAvatars) && item.virtualAvatars.length > 0
          ? item.virtualAvatars[0].imageUrl
          : avatarImages[0],
      banners: mergeArrays(
        'bannerId',
        _.map(sizes.images[findLayout(item.boothLayout).name], _.clone),
        _.map(_.sortBy(item.virtualBanners, 'bannerId'), _.clone)
      ),
      filters: item.virtualBanners
        ? _.sortBy(item.virtualBanners, 'bannerId').map(
            b => (contentById(b.contentId) || {}).fullContentType
          )
        : [],
      layers: mergeArrays(
        'layerId',
        boothTypes.length ? _.map(boothTypes[0].layers, _.clone) : [],
        _.map(item.layersStyles, _.clone)
      ).filter(layer => ['Front', 'Back'].includes(layer.title)),
      layout: findLayout(item.boothLayout),
      sponsor: _.cloneDeep(item),
    }),
    [avatarImages, boothTypes, item, contentById]
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={() => {}}
        render={props => {
          const { values } = props
          return (
            <Form as={FormikForm} autoComplete="off">
              <LayoutsPreview
                {...props}
                currentLayout={values.layout}
              />
              <ScenePreview
                avatar={values.avatar}
                currentLayout={values.layout}
                form={values.banners}
                layers={values.layers}
              />
              <FieldArray
                name="layers"
                render={arrayHelpers => (
                  <EditSponsorColor {...props} initialValues={initialValues} />
                )}
              />
              {values.avatar && (
                <AvatarsPreview
                  {...props}
                  actionStatus={actionStatus}
                  attachAvatar={attachAvatar}
                  avatarImages={avatarImages}
                  avatars={initialAvatarsValues}
                  currentAvatar={values.avatar}
                  sponsorId={values.sponsor.id}
                />
              )}
              <Divider hidden />
              <div className="field">
                <label>Public preview URL</label>
                <Input readOnly fluid value={`${SITE}/booth/${values.sponsor.wizardCode}`} action>
                  <input />
                  <CopyToClipboard text={`${SITE}/booth/${values.sponsor.wizardCode}`}>
                    <Button content="Copy" />
                  </CopyToClipboard>
                  <Button
                    as="a"
                    basic
                    href={`/booth/${values.sponsor.wizardCode}`}
                    target="_blank"
                    content="Open"
                  />
                </Input>
              </div>
              <Divider hidden />
              <FieldArray
                name="banners"
                render={arrayHelpers => (
                  <EditSponsorBanner
                    {...props}
                    actionStatus={actionStatus}
                    content={fullContent}
                    currentLayout={values.layout}
                    contentById={contentById}
                    boothTypes={boothTypes}
                    attachContentToBanner={attachContentToBanner}
                    attachStyles={attachStyles}
                    initialValues={initialValues}
                    saveSponsor={saveSponsor}
                  />
                )}
              />
            </Form>
          )
        }}
      />
    </>
  )
}

interface IEditSponsorLayoutFormInjectedProps {
  item: ISponsorItem
  fetchSponsor: () => Promise<any>
  saveSponsor: (payload: any) => any
}

export default observer((props: IEditSponsorLayoutFormInjectedProps) => {
  const {
    actionStatus,
    attachAvatar,
    attachContentToBanner,
    attachLayoutToSponsor,
    attachStyles,
    avatarImages,
    boothTypes,
    content,
    contentById,
    fetchAvatars,
    fetchContent,
    fetchLinks,
    links,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditSponsorLayout
      {...props}
      {...{
        actionStatus,
        attachAvatar,
        attachContentToBanner,
        attachLayoutToSponsor,
        attachStyles,
        avatarImages,
        boothTypes,
        content,
        contentById,
        fetchAvatars,
        fetchContent,
        fetchLinks,
        links,
      }}
    />
  )
})
