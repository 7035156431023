import { ISelectOption } from 'components/inputs'

export interface IFilterItem {
  operator?: 'Equals' | 'Like' | 'QueryParam'
  options?: ISelectOption[]
  title?: string
  value: string
}

export interface IFilter {
  [key: string]: IFilterItem
}

export interface ISort {
  field: string
  desc?: boolean
}

export interface IFilterConfig {
  filter?: IFilter
  sort?: ISort
  page: number
  itemsOnPage?: number
}

export const encodeKey = (key: string) => key.replace(/\./, '_dot_')
export const decodeKey = (key: string) => key.replace(/_dot_/, '.')

export const buildQuery = (cfg: IFilterConfig) => {
  const { filter, page, itemsOnPage, sort } = cfg

  const sorter = sort ? `&sort.${sort.desc ? 'Desc' : 'Asc'}=${sort.field}` : ''
  let filters = ''
  if (filter && Object.keys(filter).length > 0) {
    filters = Object.keys(filter)
      .filter(k => filter[k].value)
      .map(k => {
        const operator = filter[k].operator
        const v = operator === 'Like' ? `/${filter[k].value}/i` : filter[k].value
        if (operator === 'QueryParam') {
          return `${decodeKey(k)}=${encodeURIComponent(v)}`
        }
        return `filter.${decodeKey(k)}.${operator || 'Equals'}=${encodeURIComponent(v)}`
      })
      .join('&')
    filters = filters ? '&' + filters : ''
  }
  return `page=${page}&itemsOnPage=${itemsOnPage || 9999}${sorter}${filters}`
}

export const allQuery = buildQuery({ page: 0 })
