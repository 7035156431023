import {
  IAbstractionsServicesLoginModelsLoginResponseModel,
  IApiControllersPublicLoginModelsResetPasswordRequestModel,
} from 'api-types'
import { flow, types } from 'mobx-state-tree'
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from 'utils/auth'
import axios from 'utils/axios'
import { AxiosResponse } from 'axios'
import { createAxiosAction, LoadingStatusType } from 'utils/store'
import { REACT_APP_ROLE, TOKEN_REFRESH_PATH } from 'utils/config'

const isRegularUser = REACT_APP_ROLE === 'regular'
const API_PATH = isRegularUser ? 'backoffice' : 'vip-admin'

export default types
  .model('AppStore', {
    accessToken: types.optional(types.string, getAccessToken() || ''),
    activeRoute: types.optional(types.string, '/'),
    errorMessage: types.optional(types.string, ''),
    infoMessage: types.optional(types.string, ''),
    loginError: types.optional(types.string, ''),
    loginState: LoadingStatusType,
    refreshToken: types.optional(types.string, getRefreshToken() || ''),
    resetError: types.optional(types.string, ''),
    resetSend: types.optional(types.boolean, false),
    resetState: LoadingStatusType,
    samlState: LoadingStatusType,
    visibleError: types.optional(types.boolean, false),
    visibleInfo: types.optional(types.boolean, false),
    visibleSideMenu: types.optional(types.boolean, false),
  })

  .actions(self => ({
    setErrorMessage: (message: string) => (self.errorMessage = message),
    setErrorVisibility: (visibility: boolean) => (self.visibleError = visibility),
    setInfoMessage: (message: string) => (self.infoMessage = message),
    setInfoVisibility: (visibility: boolean) => (self.visibleInfo = visibility),
  }))

  .actions(self => ({
    hideError: () => {
      self.visibleError = false
      setTimeout(() => self.setErrorMessage(''), 200)
    },
    hideInfo: () => {
      self.visibleError = false
      setTimeout(() => self.setInfoMessage(''), 200)
    },
  }))

  .actions(self => ({
    showSideMenu() {
      self.visibleSideMenu = true
    },

    hideSideMenu() {
      self.visibleSideMenu = false
    },

    toggleSideMenu() {
      self.visibleSideMenu ? this.hideSideMenu() : this.showSideMenu()
    },

    setActiveRoute(route: string) {
      self.activeRoute = route
    },

    showError(errorMessage: string, delay: number) {
      self.errorMessage = errorMessage
      setTimeout(() => self.setErrorVisibility(true), 0)
      setTimeout(self.hideError, delay)
    },

    showInfo(infoMessage: string, delay: number) {
      self.infoMessage = infoMessage
      setTimeout(() => self.setInfoVisibility(true), 0)
      setTimeout(self.hideInfo, delay)
    },

    updateTokens() {
      self.accessToken = getAccessToken() || ''
      self.refreshToken = getRefreshToken() || ''
    },
  }))

  .actions(self => ({
    logout() {
      removeAccessToken()
      removeRefreshToken()
      self.updateTokens()
    },
  }))

  .actions(self => ({
    getNewToken: createAxiosAction(
      flow(function*() {
        const w = window as any
        if (w.currentRefreshTokenRequest) {
          return
        }

        try {
          const refreshFn = async () => {
            const {
              data: { accessToken, refreshToken },
            } = (await axios.post(TOKEN_REFRESH_PATH, {
              refreshToken: self.refreshToken,
            })) as {
              data: IAbstractionsServicesLoginModelsLoginResponseModel
            }

            setAccessToken(accessToken)
            setRefreshToken(refreshToken)
          }

          self.updateTokens()
          const promise = refreshFn()
          w.currentRefreshTokenRequest = promise
          yield promise
          self.updateTokens()
        } catch (e) {
          console.error('Error on refresh token', e)
        } finally {
          w.currentRefreshTokenRequest = null
        }
      }),
      s => (self.loginState = s),
      () => {
        self.loginError = 'Session expired'
        self.logout()
      }
    ),

    exhibitorLogin: createAxiosAction(
      flow(function*(username: string, password: string) {
        const {
          data: { accessToken, refreshToken },
        } = (yield axios.post('/vip-admin/login', {
          email: username,
          password,
        })) as { data: IAbstractionsServicesLoginModelsLoginResponseModel }

        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        self.updateTokens()
      }),
      s => (self.loginState = s),
      () => (self.loginError = 'Failed to login as this exhibitor')
    ),
    exhibitorReset: createAxiosAction(
      flow(function*(email: string) {
        // eslint-disable-next-line
        const { data } = (yield axios.post('/vip-admin/forgot-password/request', {
          email,
        })) as { data: IApiControllersPublicLoginModelsResetPasswordRequestModel }
        self.resetSend = true
      }),
      s => (self.resetState = s),
      () => (self.resetError = 'Failed to reset login request')
    ),
    login: createAxiosAction(
      flow(function*(username: string, password: string) {
        const {
          data: { accessToken, refreshToken },
        } = (yield axios.post('/backoffice/login', {
          password,
          userNameOrEmail: username,
        })) as { data: IAbstractionsServicesLoginModelsLoginResponseModel }

        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        self.updateTokens()
      }),
      s => (self.loginState = s),
      () => (self.loginError = 'Failed to login as this user')
    ),
    completeLoginSaml: createAxiosAction(
      flow(function*(token: string) {
        const {
          data: { accessToken, refreshToken },
        } = (yield axios.post('/vip-admin/login/saml/auth', { token })) as AxiosResponse<
          IAbstractionsServicesLoginModelsLoginResponseModel
        >
        localStorage.SuccessSamlLogin = true
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        self.updateTokens()
      }),
      s => (self.samlState = s),
      () => {
        delete localStorage.SuccessSamlLogin
        self.loginError = 'Failed to login with Saml'
      }
    ),
    reset: createAxiosAction(
      flow(function*(email: string) {
        // eslint-disable-next-line
        const { data } = (yield axios.post(`/${API_PATH}/forgot-password/request`, {
          email,
        })) as { data: IApiControllersPublicLoginModelsResetPasswordRequestModel }
        self.resetSend = true
      }),
      s => (self.resetState = s),
      () => (self.resetError = 'Failed to reset login request')
    ),
  }))
