import React, { memo, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Icon, Popup, Segment } from 'semantic-ui-react'

import './SelectImagePreview.css'

interface ISelectImagePreviewProps {
  image: string
  width?: number
  height?: number
  onShowClick: () => any
  onResetClick: () => any
  fluid?: boolean
}

export const SelectImagePreview = ({
  image,
  width = 200,
  height = 200,
  onShowClick,
  onResetClick,
  fluid,
}: ISelectImagePreviewProps) => {
  const { addToast } = useToasts()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      addToast('URL copied to clipboard', {
        appearance: 'info',
        autoDismiss: true,
      })
      setCopied(false)
    }
    // eslint-disable-next-line
  }, [copied])

  const imageWidth = fluid ? '100%' : `${width}px`

  return (
    <Segment placeholder textAlign="center" style={{ minHeight: height, cursor: 'pointer' }}>
      {!image && (
        <Segment.Inline onClick={onShowClick}>
          <Button type="button" basic content="Select image" />
        </Segment.Inline>
      )}
      {image && (
        <>
          <img
            className="image-preview-img"
            alt="avatar"
            width={imageWidth}
            height={height}
            src={fluid ? image : image.replace('upload', `upload/w_${width},h_${height},c_fit`)}
          />
          <Popup
            content="Copy image URL to clipboard"
            size="mini"
            trigger={
              <CopyToClipboard
                text={image}
                onCopy={() => setCopied(true)}
              >
                <Button
                  type="button"
                  className="image-preview-copy"
                  circular
                  size="small"
                  icon="copy outline"
                />
              </CopyToClipboard>
            }
          />
          <Icon
            className="image-preview-delete"
            inverted
            circular
            color="red"
            name="cancel"
            onClick={onResetClick}
          />
        </>
      )}
    </Segment>
  )
}

export default memo(SelectImagePreview)
