import makeInspectable from 'mobx-devtools-mst'
import { useLocalStore } from 'mobx-react-lite'
import React, { ReactElement } from 'react'
import { createStore, storeContext } from '.'

const StoreProvider = ({ children }: { children: ReactElement }) => {
  const store = useLocalStore(createStore)
  makeInspectable(store)
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export default StoreProvider
