import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import List from './List'
import Sponsor from './Sponsor'
import './Sponsors.css'
import { LoadingStatus } from 'utils/store'

interface ISponsorsIndexProps {
  fetchAllSponsorsTypes: () => Promise<any>
  fetchSponsorTypesStatus: LoadingStatus
}

export const SponsorsIndex = ({
  fetchAllSponsorsTypes,
  fetchSponsorTypesStatus,
}: ISponsorsIndexProps) => {
  const { slug } = useParams()

  useEffect(() => {
    fetchAllSponsorsTypes()
    // eslint-disable-next-line
  }, [])

  if (fetchSponsorTypesStatus !== LoadingStatus.success) {
    return <p>Loading...</p>
  }

  if (slug !== undefined && typeof slug === 'string') {
    return <Sponsor />
  }
  return <List />
}

export default observer(() => {
  const { fetchAllSponsorsTypes, fetchSponsorTypesStatus } = useRootData(
    store => store.sponsorsStore
  )
  return (
    <SponsorsIndex
      {...{
        fetchAllSponsorsTypes,
        fetchSponsorTypesStatus,
      }}
    />
  )
})
