import { observer } from 'mobx-react-lite'
import React from 'react'
import { Menu, Sidebar } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { useToasts } from 'react-toast-notifications'
import useWindowDimensions from 'utils/dimensions'
import { REACT_APP_ROLE } from 'utils/config'
import SideMenuRegular from './SideMenuRegular'
import SideMenuExhibitor from './SideMenuExhibitor'
import MenuLink from './SideMenuLink'
import './SideMenu.css'

interface ISideMenuProps {
  visibleSideMenu: boolean
  activeRoute: string
  hideSideMenu: () => void
  logout: () => void
}

export const SideMenu = ({
  hideSideMenu,
  activeRoute,
  visibleSideMenu,
  logout,
}: ISideMenuProps) => {
  const { removeAllToasts } = useToasts()
  const { isMobile } = useWindowDimensions()
  const isVisible = !isMobile || visibleSideMenu

  return (
    <Sidebar.Pushable
      as="div"
      className={`side-menu-wrapper ${isMobile ? '' : 'not-mobile'} ${
        isVisible ? '' : 'not-visible'
      }`}
    >
      <Sidebar as={Menu} visible={isVisible} animation="overlay" width="thin" vertical inverted>
        {REACT_APP_ROLE === 'regular' ? <SideMenuRegular /> : <SideMenuExhibitor />}
        <MenuLink
          name="Logout"
          onClick={() => {
            logout()
            removeAllToasts()
          }}
          route="."
        />
        <MenuLink name="Reload" onClick={() => window.location.reload(true)} route="." />
      </Sidebar>

      {isMobile && <Sidebar.Pusher className="side-menu-dimmer" onClick={hideSideMenu} />}
    </Sidebar.Pushable>
  )
}

export default observer(() => {
  const { visibleSideMenu, hideSideMenu, activeRoute, logout } = useRootData(
    store => store.appStore
  )
  return <SideMenu {...{ activeRoute, hideSideMenu, visibleSideMenu, logout }} />
})
