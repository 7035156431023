import Pagination from 'components/pagination/Pagination'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import { Button, Card, Divider, Header, Icon, Modal, Segment } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IImageItem, uploadImage } from 'stores/images'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

import './SelectImageModal.css'

interface ISelectImageModalInjectedProps {
  width?: number
  height?: number
  show?: boolean
  showGallery?: boolean
  onHideClick?: () => any
  onImageSelect: (img: string) => any
}

interface ISelectImageModalProps extends ISelectImageModalInjectedProps {
  fetchImages: (filter: IFilterConfig) => Promise<any>
  pagesCount: number
  images: IImageItem[]
  fetchImagesStatus: LoadingStatus
}

export const SelectImageModal = ({
  show: showImages,
  showGallery,
  onHideClick,
  onImageSelect,
  fetchImages,
  images,
  fetchImagesStatus,
  pagesCount,
  width = 100,
  height = 200,
}: ISelectImageModalProps) => {
  const [page, setPage] = useState(1)
  const [loading, setLoader] = useState(false)
  const [currentFile, setCurrentFile] = useState<File | null>(null)

  useEffect(() => {
    if (showGallery && showImages) {
      // setLoader(true)
      fetchImages({
        itemsOnPage: 20,
        page: page - 1,
        sort: {
          desc: true,
          field: 'Id',
        },
      }).then(() => {
        setLoader(false)
      })
    }
  }, [fetchImages, showImages, showGallery, page])

  const hideImagesPopup = useCallback(() => {
    if (onHideClick) {
      onHideClick()
    }
    setCurrentFile(null)
  }, [onHideClick])

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const [file] = Array.from(event.target.files || [])
    if (file) {
      setCurrentFile(file)
    }
  }, [])

  const onUploadClick = useCallback(async () => {
    if (!currentFile) {
      return
    }
    setLoader(true)
    const newImage = await uploadImage(currentFile)
    setLoader(false)
    if (newImage) {
      onImageSelect(newImage)
      setCurrentFile(null)
    } else {
      // image not uploaded
      setCurrentFile(null)
    }
  }, [currentFile, onImageSelect])

  const onResetCurrentFileClick = useCallback(() => {
    setCurrentFile(null)
  }, [setCurrentFile])

  return (
    <>
      <Modal open={showImages} size="large">
        <Modal.Actions>
          <Segment placeholder textAlign="center" className="image-preview" loading={loading}>
            <>
              {!currentFile && (
                <Segment.Inline>
                  <input
                    type="file"
                    className="upload-input"
                    onChange={onFileChange}
                    accept="image/jpeg,image/png,image/gif,image/svg+xml"
                  />
                  <Button type="button" basic content="Drag or select image" />
                </Segment.Inline>
              )}
              {currentFile && (
                <>
                  <Segment.Inline>
                    <img
                      className="image-preview-img"
                      src={URL.createObjectURL(currentFile)}
                      alt=""
                    />
                    <Header>{currentFile.name}</Header>
                    <Icon
                      inverted
                      circular
                      color="red"
                      name="cancel"
                      className="file-preview-delete"
                      onClick={onResetCurrentFileClick}
                    />
                  </Segment.Inline>
                  <Segment.Inline>
                    <Button type="button" basic onClick={onUploadClick}>
                      <Icon name="cloud upload" color="blue" />
                      <b>Upload</b>
                    </Button>
                  </Segment.Inline>
                </>
              )}
            </>
          </Segment>
        </Modal.Actions>

        {showGallery && (
          <Modal.Content className="images-gallery">
            <Card.Group itemsPerRow={4} centered>
              {images.map(image => {
                const imgUrl = image.imagesUrls.originalImageUrl
                const imgPreview = imgUrl ? imgUrl.replace('upload', `upload/c_fit`) : null
                return (
                  <Card
                    key={image.id}
                    onClick={() => onImageSelect(imgUrl)}
                    image={imgPreview ? imgPreview : null}
                    extra={
                      <>
                        <b>{image.imagesUrls.fileName}</b>
                        {image.imagesUrls.width && image.imagesUrls.height && (
                          <i>
                            {image.imagesUrls.width}×{image.imagesUrls.height}
                          </i>
                        )}
                      </>
                    }
                  />
                )
              })}
            </Card.Group>
            <Divider hidden />
            {images && pagesCount ? (
              <Pagination currentPage={page} onPageSelect={setPage} pagesCount={pagesCount} />
            ) : null}
          </Modal.Content>
        )}

        <Modal.Actions>
          <Button type="button" basic onClick={hideImagesPopup}>
            <Icon name="cancel" color="red" />
            <b>Cancel</b>
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export const SelectImageModalInjected = observer((props: ISelectImageModalInjectedProps) => {
  const { fetchImagesStatus, fetchImages, images, pagesCount } = useRootData(
    store => store.imagesStore
  )
  return (
    <SelectImageModal
      {...props}
      {...{
        fetchImages,
        fetchImagesStatus,
        images,
        pagesCount,
      }}
    />
  )
})

export default memo(SelectImageModalInjected)
