import {
  IAbstractionsContractsQueriesSpeakersSpeakersResponse,
  IAbstractionsContractsQueriesSpeakersSpeakersResponseSpeaker,
  IApiControllersBackofficeSpeakersModelsChangeSpeakersStatusModel,
  IApiControllersBackofficeSpeakersModelsSpeakerModel,
  IApiControllersBackofficeSpeakersModelsUpdateManySpeakersOrderModelUpdateSpeakerOrderModel,
  IApiControllersBackofficeSpeakersModelsUpdateSpeakerModel,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export type ISpeakerItem = IAbstractionsContractsQueriesSpeakersSpeakersResponseSpeaker
export type IAddSpeakerItem = IApiControllersBackofficeSpeakersModelsSpeakerModel
export type IUpdateSpeakerItem = IApiControllersBackofficeSpeakersModelsUpdateSpeakerModel
export type ISaveSpeakerItem = IUpdateSpeakerItem
export type IPatchSpeakers = IApiControllersBackofficeSpeakersModelsUpdateManySpeakersOrderModelUpdateSpeakerOrderModel[]

export type IChangeStatusSpeakers = IApiControllersBackofficeSpeakersModelsChangeSpeakersStatusModel

export default types
  .model('SpeakersStore', {
    actionSpeakerStatus: LoadingStatusType,
    fetchSpeakersStatus: LoadingStatusType,
    saveSpeakerStatus: LoadingStatusType,
    speakers: types.frozen<ISpeakerItem[]>([]),
  })

  .actions(self => ({
    fetchSpeakers: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(`/backoffice/speakers?${allQuery}`)) as {
          data: IAbstractionsContractsQueriesSpeakersSpeakersResponse
        }
        self.speakers = data.speakers
      }),
      s => (self.fetchSpeakersStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch speakers')
    ),

    saveSpeaker: createAxiosAction(
      async (payload: ISaveSpeakerItem) => {
        const method = payload.id ? axios.put : axios.post
        await method('/backoffice/speakers', payload)
      },
      s => (self.saveSpeakerStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to save speaker')
    ),

    saveOrder: createAxiosAction(
      async (order: IPatchSpeakers) =>
        await axios.patch('/backoffice/speakers', { speakers: order }),
      s => (self.saveSpeakerStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to save speakers order')
    ),

    deleteSpeaker: createAxiosAction(
      async (id: string) => {
        await axios.delete('/backoffice/speakers?statusModel.speakersIds=' + id)
      },
      s => (self.actionSpeakerStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to delete speaker')
    ),

    draftSpeaker: createAxiosAction(
      async (id: string) => {
        await axios.patch('/backoffice/speakers/draft', {
          speakersIds: [id],
        } as IChangeStatusSpeakers)
      },
      s => (self.actionSpeakerStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to draft speaker')
    ),

    publishSpeaker: createAxiosAction(
      async (id: string) => {
        await axios.patch('/backoffice/speakers/publish', {
          speakersIds: [id],
        } as IChangeStatusSpeakers)
      },
      s => (self.actionSpeakerStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to publish speaker')
    ),
  }))
