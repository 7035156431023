import {
  IAbstractionsContractsQueriesBlockedIpAllBlockedIpResponseBlockedIpInfo,
  IAbstractionsContractsQueriesBlockedIpBlockedIpResponse,
  IApiControllersBackofficeSuperAdminModelsCreateBlockedIpModel,
} from 'api-types'
import { flow, getParent, types } from 'mobx-state-tree'
import { AxiosResponse } from 'axios'
import axios from 'utils/axios'
import { buildQuery, IFilterConfig } from 'utils/filter'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type IBlockedIpItem = IAbstractionsContractsQueriesBlockedIpAllBlockedIpResponseBlockedIpInfo
export type ICreateBlockedIp = IApiControllersBackofficeSuperAdminModelsCreateBlockedIpModel

export default types
  .model('BlockedIpsStore', {
    actionStatus: LoadingStatusType,
    fetchBlockedIpsStatus: LoadingStatusType,
    blockedIps: types.frozen<IBlockedIpItem[]>([]),
    totalPages: types.optional(types.number, 0),
  })

  .actions((self) => ({
    fetchBlockedIps: createAxiosAction(
      flow(function* (filter: IFilterConfig) {
        const { data } = (yield axios.get(`/backoffice/super-admin/blockedIp?${buildQuery(filter)}`)) as AxiosResponse<IAbstractionsContractsQueriesBlockedIpBlockedIpResponse>
        self.blockedIps = data.blockedIps
        self.totalPages = data.totalPages
      }),
      (s) => (self.fetchBlockedIpsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch blocked ips list')
    ),
  }))

  .actions((self) => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      addIp: createAction((payload: ICreateBlockedIp) => {
        return axios.post('/backoffice/super-admin/blockedIp', payload)
      }, 'Failed to add ip to blocked list'),

      deleteIp: createAction((id: string) => {
        return axios.delete(`/backoffice/super-admin/blockedIp/${id}`)
      }, 'Failed to remove ip from blocked list'),
    }
  })
