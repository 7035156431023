import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Loader, Table } from 'semantic-ui-react'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'
import { useRootData } from 'stores'
import { IFullDocumentsItem } from 'stores/sponsors'
import { LoadingStatus } from 'utils/store'
import { IAttendeeForStatistics } from '../../stores/registrations'
import '../sponsors/Sponsors.css'
import { CustomChart } from './StatisticsChart'
import { IStatItem, ItemRow } from './StatisticsItemRow'

type SortType = 'ascending' | 'descending' | undefined
type SortBy = 'name' | 'type' | 'views' | 'users'

const EmptyResult = (loader: boolean) => (
  <Table.Row>
    <Table.Cell colSpan={6} textAlign="center">
      {loader ? <Loader /> : 'Nothing found'}
    </Table.Cell>
  </Table.Row>
)

const Exhibitor = observer(() => {
  const { currentExhibitor, fetchExhibitor, fetchExhibitorStatus } = useRootData(
    store => store.exhibitorStore
  )
  const { content, fetchLinks, fetchContent, links } = useRootData(store => store.sponsorsStore)
  const { tryfetchAttendiesForStatistics, attendiesForStatisticsById } = useRootData(
    store => store.registrationsStore
  )

  const [sortBy, setSortBy] = useState<SortBy>('name')
  const [sortDirection, setSortDirection] = useState<SortType>('ascending')

  useEffect(() => {
    fetchExhibitor()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentExhibitor) {
      fetchContent(currentExhibitor.id)
      fetchLinks(currentExhibitor.id)
    }
    // eslint-disable-next-line
  }, [currentExhibitor])

  const fullContent: IFullDocumentsItem[] = useMemo(() => [...content, ...links], [content, links])

  useEffect(() => {
    const ids = _.uniq(
      _.flatten(
        fullContent.filter(item => item.statistics).map(item => Object.keys(item.statistics))
      )
    )
    if (ids.length > 0) {
      tryfetchAttendiesForStatistics(ids)
    }
    // tryfetchAttendiesForStatistics(['5f686484f04bf121c002ff35', '5f5fab20f04bf31b20fd66b1'])
  }, [tryfetchAttendiesForStatistics, fullContent])

  const attendies: { [key: string]: IAttendeeForStatistics } = {}

  fullContent.forEach(item => {
    if (item.statistics) {
      Object.keys(item.statistics).forEach(id => {
        if (attendiesForStatisticsById.has(id)) {
          attendies[id] = attendiesForStatisticsById.get(id)!
        }
      })
    }
  })

  const report = fullContent.map(item => ({
    name: item.title,
    statistics: item.statistics,
    type: item.fullContentType.split('_')[1],
    users: item.statistics ? Object.keys(item.statistics).length : 0,
    views: item.statistics
      ? Object.values(item.statistics).reduce(
          (prev, cur) => prev + Object.values(cur).reduce((val, i) => val + i, 0),
          0
        )
      : 0,
  }))

  const data = report.slice().sort((a, b) => b.views - a.views)
  const summViews = report.reduce((prev, i) => prev + i.views, 0)
  const summUsers = report.reduce((prev, i) => prev + i.users, 0)

  const handleSort = (newSort: SortBy) => {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
    setSortBy(newSort)
  }

  const displayContent = (values: IStatItem[]) => {
    return _.orderBy(values, sortBy, sortDirection === 'ascending' ? 'asc' : 'desc')
  }

  const headers = useMemo(
    () => [
      { name: 'name', width: 6, title: 'Name' },
      { name: 'type', width: 5, title: 'Type' },
      { name: 'users', width: 2, title: 'Users' },
      { name: 'views', width: 2, title: 'Views' },
    ],
    []
  )

  return (
    <>
      <Grid divided>
        <Grid.Row columns={2} stretched>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <CustomChart dataArray={data} field="views" title="Views" summ={summViews} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <CustomChart dataArray={data} field="users" title="Users" summ={summUsers} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      <Table sortable basic="very">
        <Table.Header>
          <Table.Row>
            <Table.Cell width={1} className="expandCell" />
            {headers.map(header => (
              <Table.HeaderCell
                sorted={sortBy === header.name ? sortDirection : undefined}
                onClick={() => handleSort(header.name as SortBy)}
                width={header.width as SemanticWIDTHS}
                key={header.name}
              >
                {header.title}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.length > 0 && Object.keys(attendies).length > 0
            ? displayContent(data).map(item => (
                <ItemRow key={item.name} item={item} attendies={attendies} />
              ))
            : EmptyResult(fetchExhibitorStatus === LoadingStatus.pending)}
        </Table.Body>
      </Table>
    </>
  )
})

export default Exhibitor
