import { TextArea, TextInput } from 'components/inputs'
import { Form as FormikForm, Formik, FormikActions } from 'formik'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  Confirm,
  Form,
  Header,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ISponsorTier, IUpdateSponsorTierItem } from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

const initialValues = {
  description: '',
  fontColor: '#000',
  fontFamily: 'Arial',
  fontSize: 14,
  fontWeight: 800,
  id: '',
  isDefault: false,
  title: '',
} as ISponsorTier

interface IEditSponsorTiers {
  fetchSponsorsTiersByPage: (filter: IFilterConfig) => Promise<any>
  fetchTiersStatus: LoadingStatus
  sponsorTiers: ISponsorTier[]
  sponsorTiersPages: number
  saveSponsorTier: (payload: IUpdateSponsorTierItem, onDone: () => any) => Promise<any>
  deleteSponsorTier: (payload: { [key: string]: string }, onDone: () => any) => Promise<any>
}

export const EditSponsorTiers = ({
  fetchSponsorsTiersByPage,
  fetchTiersStatus,
  sponsorTiersPages,
  sponsorTiers,
  saveSponsorTier,
  deleteSponsorTier,
}: IEditSponsorTiers) => {
  const [entryToEdit, setEntryToEdit] = useState<ISponsorTier>()
  const [showManage, setShowManage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)

  const filters = useMemo(
    () => ({
      itemsOnPage: 20,
      page: page - 1,
      sort: {
        desc: true,
        field: 'Title',
      },
    }),
    [page]
  )

  const fetchTiers = () => fetchSponsorsTiersByPage(filters)

  useEffect(() => {
    fetchTiers()
    // eslint-disable-next-line
  }, [page])

  const fullValues = useMemo(
    () => ({
      ..._.cloneDeep(initialValues),
      ..._.cloneDeep(entryToEdit),
    }),
    [entryToEdit]
  )

  const toggleModal = async (entry?: ISponsorTier) => {
    await setEntryToEdit(entry)
    setShowModal(!showModal)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    toggleModal()
  }

  const handleDelete = async (entry: any) => {
    await deleteSponsorTier({ id: entry.id }, () => fetchTiers())
  }

  const handleSubmit = async (
    values: IUpdateSponsorTierItem,
    { resetForm }: Partial<FormikActions<IUpdateSponsorTierItem>>
  ) => {
    await saveSponsorTier(values, () => fetchTiers())
    if (resetForm) {
      resetForm()
    }
    toggleModal()
  }

  const handleManage = useCallback(() => {
    setShowManage(!showManage)
  }, [setShowManage, showManage])

  const { isMobile } = useWindowDimensions()

  const loadedTypes = fetchTiersStatus === LoadingStatus.success

  const handlePaginationChange = useCallback(
    async (e, { activePage }) => {
      setPage(activePage)
      await loadedTypes
      setTimeout(() => {
        const active = document.querySelector('.page-scroller') as HTMLDivElement
        active.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 600)
    },
    [loadedTypes, setPage]
  )

  return (
    <>
      <Table size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={15} />
            <Table.HeaderCell width={1}>
              <Button
                active={showManage}
                basic={!showManage}
                color={showManage ? 'blue' : undefined}
                icon={showManage ? 'pencil' : 'delete'}
                size="tiny"
                onClick={handleManage}
                style={{ boxShadow: 'none' }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sponsorTiers.map((entry, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Header as="h5">{entry.title}</Header>
              </Table.Cell>
              <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
                {showManage ? (
                  <Button basic color="red" icon="delete" onClick={() => handleDelete(entry)} />
                ) : (
                  <Button
                    basic
                    icon="pencil alternate"
                    onClick={() => toggleModal(entry)}
                    style={{ boxShadow: 'none' }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {sponsorTiersPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2} textAlign="center">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={handlePaginationChange}
                  totalPages={sponsorTiersPages}
                  firstItem={false}
                  lastItem={false}
                  prevItem={false}
                  nextItem={false}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      <Formik
        initialValues={fullValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        render={props => {
          const {
            values,
            touched,
            setFieldValue,
            setFieldTouched,
            setSubmitting,
            resetForm,
          } = props
          return (
            <Modal
              centered={false}
              dimmer="inverted"
              size="tiny"
              trigger={
                <Button primary onClick={() => toggleModal()}>
                  Add Sponsor Type
                </Button>
              }
              open={showModal}
              onClose={() => {
                Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
              }}
              closeOnDimmerClick={true}
            >
              <Modal.Header>{`${fullValues.id.length ? 'Edit' : 'Add'}`} Sponsor Type</Modal.Header>
              <Modal.Content>
                <Form as={FormikForm} autoComplete="off">
                  <TextInput title="Title" name="title" />
                  <TextArea title="Description" name="description" />
                  <Checkbox
                    label="Is Default"
                    name="isDefault"
                    defaultChecked={fullValues.isDefault}
                    onChange={(event, { checked }) => {
                      setFieldValue('isDefault', checked)
                      setFieldTouched('isDefault')
                    }}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  fluid={isMobile}
                  type="button"
                  onClick={() => {
                    Object.keys(touched).length ? setShowConfirm(true) : toggleModal()
                  }}
                  content="Cancel"
                />
                <Button
                  type="submit"
                  fluid={isMobile}
                  width={3}
                  primary
                  loading={fetchTiersStatus === LoadingStatus.pending}
                  onClick={() => handleSubmit(values, { resetForm, setSubmitting })}
                  content="Save"
                />
              </Modal.Actions>
            </Modal>
          )
        }}
      />

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content="Discard changes?"
      />
    </>
  )
}

export const EditSponsorsTiersForm = observer(() => {
  const {
    fetchSponsorsTiersByPage,
    fetchTiersStatus,
    sponsorTiers,
    sponsorTiersPages,
    saveSponsorTier,
    deleteSponsorTier,
  } = useRootData(store => store.sponsorsStore)
  return (
    <EditSponsorTiers
      {...{
        deleteSponsorTier,
        fetchSponsorsTiersByPage,
        fetchTiersStatus,
        saveSponsorTier,
        sponsorTiers,
        sponsorTiersPages,
      }}
    />
  )
})

export default EditSponsorsTiersForm
