import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, memo, useCallback, useState } from 'react'
import { Button, Header, Icon, Modal, Segment } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { IFileItem, uploadFile } from 'stores/images'
import { IFilterConfig } from 'utils/filter'
import { LoadingStatus } from 'utils/store'

import './SelectFileModal.css'

interface ISelectFileModalInjectedProps {
  width?: number
  height?: number
  show?: boolean
  onHideClick?: () => any
  onFileSelect: (file: string) => any
}

interface ISelectFileModalProps extends ISelectFileModalInjectedProps {
  fetchFiles: (filter: IFilterConfig) => Promise<any>
  pagesCount: number
  files: IFileItem[]
  fetchFilesStatus: LoadingStatus
}

export const SelectFileModal = ({
  show: showFiles,
  onHideClick,
  onFileSelect,
  fetchFiles,
  files,
  fetchFilesStatus,
  pagesCount,
  width = 100,
  height = 100,
}: ISelectFileModalProps) => {
  const [loading, setLoader] = useState(false)
  const [currentFile, setCurrentFile] = useState<File | null>(null)

  const hideFilesPopup = useCallback(() => {
    if (onHideClick) {
      onHideClick()
    }
    setCurrentFile(null)
  }, [onHideClick])

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const [file] = Array.from(event.target.files || [])
    if (file) {
      setCurrentFile(file)
    }
  }, [])

  const onUploadClick = useCallback(async () => {
    if (!currentFile) {
      return
    }
    setLoader(true)
    const newFile = await uploadFile(currentFile)
    setLoader(false)
    if (newFile) {
      onFileSelect(newFile)
      setCurrentFile(null)
    } else {
      // file not uploaded
      setCurrentFile(null)
    }
  }, [currentFile, onFileSelect])

  const onResetCurrentFileClick = useCallback(() => {
    setCurrentFile(null)
  }, [setCurrentFile])

  return (
    <>
      <Modal open={showFiles} size="small">
        <Modal.Actions>
          <Segment placeholder textAlign="center" className="file-preview" loading={loading}>
            <>
              {!currentFile && (
                <Segment.Inline>
                  <input type="file" className="upload-input" onChange={onFileChange} accept="*" />
                  <Button type="button" basic content="Drag or select file" />
                </Segment.Inline>
              )}
              {currentFile && (
                <>
                  <Segment.Inline>
                    <Header icon>
                      <Icon name="file outline" size="small" />
                      {currentFile.name}
                    </Header>
                    <Icon
                      inverted
                      circular
                      color="red"
                      name="cancel"
                      className="file-preview-delete"
                      onClick={onResetCurrentFileClick}
                    />
                  </Segment.Inline>
                  <Segment.Inline>
                    <Button type="button" basic onClick={onUploadClick}>
                      <Icon name="cloud upload" color="blue" />
                      <b>Upload</b>
                    </Button>
                  </Segment.Inline>
                </>
              )}
            </>
          </Segment>
        </Modal.Actions>

        <Modal.Actions>
          <Button type="button" basic onClick={hideFilesPopup}>
            <Icon name="cancel" color="red" />
            <b>Cancel</b>
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export const SelectFileModalInjected = observer((props: ISelectFileModalInjectedProps) => {
  const { fetchFilesStatus, fetchFiles, files, pagesCount } = useRootData(
    store => store.imagesStore
  )
  return (
    <SelectFileModal
      {...props}
      {...{
        fetchFiles,
        fetchFilesStatus,
        files,
        pagesCount,
      }}
    />
  )
})

export default memo(SelectFileModalInjected)
