import { types } from 'mobx-state-tree'

import agendaStore from './agenda'
import appStore from './app'
import blacklistStore from './blacklist'
import blockedIpsStore from './blockedIps'
import companiesStore from './companies'
import emailTemplatesStore from './emailTemplates'
import forbiddenEmailsStore from './forbiddenEmails'
import homeStore from './home'
import imagesStore from './images'
import notificationsStore from './notifications'
import packagesStore from './packages'
import registrationsStore from './registrations'
import settingsStore from './settings'
import speakersStore from './speakers'
import sponsorsStore from './sponsors'
import exhibitorStore from './exhibitor'
// store import

export default types
  .model('RootStore', {
    agendaStore: types.optional(agendaStore, {}),
    appStore: types.optional(appStore, {}),
    blacklistStore: types.optional(blacklistStore, {}),
    blockedIpsStore: types.optional(blockedIpsStore, {}),
    companiesStore: types.optional(companiesStore, {}),
    emailTemplatesStore: types.optional(emailTemplatesStore, {}),
    forbiddenEmailsStore: types.optional(forbiddenEmailsStore, {}),
    homeStore: types.optional(homeStore, {}),
    imagesStore: types.optional(imagesStore, {}),
    notificationsStore: types.optional(notificationsStore, {}),
    packagesStore: types.optional(packagesStore, {}),
    registrationsStore: types.optional(registrationsStore, {}),
    settingsStore: types.optional(settingsStore, {}),
    speakersStore: types.optional(speakersStore, {}),
    sponsorsStore: types.optional(sponsorsStore, {}),
    exhibitorStore: types.optional(exhibitorStore, {}),
    // store tree
  })
  .actions(self => ({
    showError: (message: string, delay?: number) => {
      delay = delay ? delay : 2000
      self.appStore.showError(message, delay)
    },
  }))
