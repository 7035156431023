import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  Accordion,
  Button,
  Divider,
  DropdownProps,
  Grid,
  Header,
  Icon,
  Select,
} from 'semantic-ui-react'
import { useRootData } from 'stores'
import { ISettingsSponsorItem } from 'stores/settings'
import {
  ISponsorFloor,
  ISponsorFloorOrder,
  ISponsorItem,
  ISponsorType,
  IUpdateFloorItem,
} from 'stores/sponsors'
import useWindowDimensions from 'utils/dimensions'
import { IFilterConfig } from 'utils/filter'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'

interface ISectionItem {
  activeSection: number
  appendSponsors: (values: string[]) => any
  availableSponsors: (values: ISettingsSponsorItem[]) => any
  dragDisabled?: boolean
  onDragEnd: (result: any, index: number) => any
  sectionIndex: number
  sectionTitle: string
  setActiveSession: (index: number) => any
  sponsorById: (id: string) => any
  sponsors: ISettingsSponsorItem[]
  showManage: boolean
  setShowManage: (value: boolean) => any
  onSponsorRemove: (sectionIndex: number, id: string) => any
}

const SectionItem = ({
  activeSection,
  appendSponsors,
  availableSponsors,
  dragDisabled,
  onDragEnd,
  sectionIndex,
  sectionTitle,
  setActiveSession,
  sponsorById,
  sponsors,
  showManage,
  setShowManage,
  onSponsorRemove,
}: ISectionItem) => {
  const [itemsToAdd, setItemsToAdd] = useState<string[]>([])

  const sponsorsDetailed = useMemo(
    () => sponsors.map(entry => sponsorById(entry.id)).filter(Boolean),
    // eslint-disable-next-line
    [sponsors]
  )

  const handleDragEnd = (result: any) => {
    onDragEnd(result, sectionIndex)
  }

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    setItemsToAdd(data.value as string[])

  const handleAppend = () => {
    appendSponsors(itemsToAdd)
    setItemsToAdd([])
  }

  const isActive = activeSection === sectionIndex

  const availableItems = availableSponsors(sponsors)

  const availableOptions = useMemo(
    () =>
      availableItems.map((item: any) => ({
        key: item.id,
        text: item.company,
        value: item.id,
      })),
    [availableItems]
  )

  const { isMobile } = useWindowDimensions()

  return (
    <div className="ui segment vertical">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="dropable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Accordion>
                <Accordion.Title index={sectionIndex} active={isActive}>
                  <Header as="h5" onClick={() => setActiveSession(isActive ? -1 : sectionIndex)}>
                    <Icon name="dropdown" />
                    {sectionTitle}
                  </Header>
                  {isActive && (
                    <Button
                      active={showManage}
                      basic={!showManage}
                      color={showManage ? 'blue' : undefined}
                      icon="delete"
                      size="tiny"
                      onClick={() => setShowManage(!showManage)}
                      style={{ boxShadow: 'none' }}
                    />
                  )}
                </Accordion.Title>
                <Accordion.Content active={isActive}>
                  {sponsorsDetailed.length > 0
                    ? sponsorsDetailed.map((sponsor, index) => (
                        <Draggable key={sponsor.id} draggableId={sponsor.id} index={index}>
                          {(itemProvided, itemSnapshot) => (
                            <div
                              className="draggable-item ui vertical segment"
                              ref={itemProvided.innerRef}
                              {...itemProvided.draggableProps}
                              {...itemProvided.dragHandleProps}
                            >
                              <Header as="h5">
                                {sponsor.company}
                                {showManage ? (
                                  <Icon
                                    name="delete"
                                    size="tiny"
                                    color="red"
                                    onClick={() => onSponsorRemove(sectionIndex, sponsor.id)}
                                  />
                                ) : (
                                  <Icon name="arrows alternate vertical" size="tiny" color="grey" />
                                )}
                              </Header>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  <Divider hidden />
                  {provided.placeholder}
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column mobile={8} tablet={8} desktop={11} verticalAlign="middle">
                        <Select
                          fluid
                          search
                          multiple
                          placeholder="Select sponsors to add..."
                          options={availableOptions}
                          onChange={handleSelect}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={8} desktop={5} verticalAlign="middle">
                        <Button
                          primary
                          fluid={isMobile}
                          basic
                          content="Add"
                          onClick={handleAppend}
                          disabled={!itemsToAdd.length}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Divider hidden />
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

interface IEditSponsorFloors {
  fetchSponsorsFloors: (filter: IFilterConfig) => Promise<any>
  fetchAllSponsors: () => Promise<any>
  loading: LoadingStatus
  actionStatus: LoadingStatus
  sponsorFloors: ISponsorFloor[]
  availableSponsors: (values: ISettingsSponsorItem[]) => any
  allSponsors: ISponsorItem[]
  sponsorById: (id: string) => ISponsorItem | null
  sponsorTypeById: (id: string) => ISponsorType | null
  updateFloors: (payload: IUpdateFloorItem[], onDone: () => any) => Promise<any>
}

export const EditSponsorFloors = ({
  fetchSponsorsFloors,
  fetchAllSponsors,
  loading,
  sponsorFloors,
  allSponsors,
  actionStatus,
  availableSponsors,
  sponsorById,
  sponsorTypeById,
  updateFloors,
}: IEditSponsorFloors) => {
  const [sections, setSections] = useState<ISponsorFloor[]>() || []
  const [activeSection, setActiveSession] = useState(-1)
  const [touched, setTouched] = useState(false)
  const [showManage, setShowManage] = useState(false)

  const filters = useMemo(
    () =>
      ({
        itemsOnPage: 9999,
        page: 0,
        sort: {
          desc: false,
          field: 'Title',
        },
      } as IFilterConfig),
    []
  )

  const fetchAll = () => {
    fetchSponsorsFloors(filters)
    fetchAllSponsors()
  }

  useEffect(() => {
    fetchAll()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (loading === LoadingStatus.success) {
      setSections(sponsorFloors)
    }
  }, [loading, setSections, sponsorFloors])

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleAppendSponsors = (values: any) => {
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[activeSection].sponsorsOrders
      const newItems = values.map((value: string, index: number) => ({
        id: value,
        order: 0,
      }))
      const newSection = _.uniqBy([...targetSection, ...newItems], 'id').map(
        (item: ISponsorFloorOrder, index) => ({
          id: item.id,
          order: index,
        })
      )
      newSections[activeSection].sponsorsOrders = newSection
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleRemoveSponsors = (sectionIndex: number, id: string) => {
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[activeSection].sponsorsOrders
      let newSection = targetSection.filter(item => id !== item.id)
      newSection = newSection.map((item: ISponsorFloorOrder, index) => ({
        id: item.id,
        order: index,
      }))
      newSections[activeSection].sponsorsOrders = newSection
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleSponsorDragEnd = (result: any, index: number) => {
    if (!result.destination) {
      return
    }
    if (sections) {
      const newSections = _.cloneDeep(sections)
      const targetSection = newSections[index].sponsorsOrders
      const newSponsors = reorder(
        targetSection,
        result.source.index,
        result.destination.index
      ) as any
      newSections[index].sponsorsOrders = newSponsors
      setSections(newSections)
      setTouched(true)
    }
  }

  const handleSave = async () => {
    if (sections) {
      setShowManage(false)
      const payload = sections.map(section => ({
        id: section.id,
        sponsor: section.sponsorsOrders,
      }))
      await updateFloors(payload, () => fetchSponsorsFloors(filters))
      setTouched(false)
    }
  }

  if (!sections || !sponsorFloors) {
    return null
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {sections.map((section, index) => (
            <SectionItem
              availableSponsors={availableSponsors}
              appendSponsors={handleAppendSponsors}
              key={index}
              activeSection={activeSection}
              sectionIndex={index}
              dragDisabled={false}
              setActiveSession={setActiveSession}
              sponsors={section.sponsorsOrders}
              sectionTitle={section.title}
              onDragEnd={handleSponsorDragEnd}
              sponsorById={sponsorById}
              showManage={showManage}
              setShowManage={setShowManage}
              onSponsorRemove={handleRemoveSponsors}
            />
          ))}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {/* <Button content="Cancel" onClick={fetchAll}/> */}
          <Button
            primary
            width={3}
            content="Save"
            onClick={handleSave}
            disabled={!touched}
            loading={actionStatus === LoadingStatus.pending}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const EditSponsorFloorsForm = observer(() => {
  const {
    fetchSponsorsFloors,
    fetchAllSponsors,
    fetchFloorsStatus,
    fetchSponsorsStatus,
    actionStatus,
    allSponsors,
    availableSponsors,
    sponsorFloors,
    sponsorById,
    sponsorTypeById,
    updateFloors,
  } = useRootData(store => store.sponsorsStore)
  const loading = combineLoadingStatus([fetchFloorsStatus, fetchSponsorsStatus])
  return (
    <EditSponsorFloors
      {...{
        actionStatus,
        allSponsors,
        availableSponsors,
        fetchAllSponsors,
        fetchSponsorsFloors,
        loading,
        sponsorById,
        sponsorFloors,
        sponsorTypeById,
        updateFloors,
      }}
    />
  )
})

export default EditSponsorFloorsForm
