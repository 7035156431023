import AttendeeCardActions, {
  IAttendeeCardActionsList,
} from 'components/attendee-card-actions/AttendeeCardActions'
import { observer } from 'mobx-react-lite'
import React, { memo, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Confirm, Dropdown, Header, Table } from 'semantic-ui-react'
import { useRootData } from 'stores'
import {
  IRegistrationItem,
  RegistrationStatuses,
  registrationStatusOptions,
} from 'stores/registrations'
import './AttendeeCard.css'

interface IAttendeeCardInjectedProps {
  item: IRegistrationItem
  visibleActions: boolean | undefined
  manage?: boolean
  onShowActions: (id: string) => any
  onHideActions: () => any
  reloadPage: () => Promise<any>
}

interface IAttendeeCardProps extends IAttendeeCardInjectedProps {
  inBlacklist: (item: IRegistrationItem) => boolean
  fetchBlackList: () => Promise<any>
  actions: IAttendeeCardActionsList
  changeStatus: (id: string, status: RegistrationStatuses, onDone: () => any) => Promise<any>
  getCategoryById: (id: string) => any
  deleteAttendee: (id: string, onDone: () => any) => Promise<any>
}

const registrationColors = {
  Completed: '#fbfdef',
  PendingApprove: '#ffedde',
  PendingConfirmation: '#ffedde',
  PendingPackageSelection: '#dff0ff',
  PendingPayment: '#dff0ff',
  Refund: '#ffedde',
  Rejected: '#ffe8e6',
  RequestForPayment: '#ffedde',
  Trash: '#f1e2d3',
  SubmitStepOne: '#ffe3fb',
}

export const AttendeeCard = ({
  actions,
  item,
  visibleActions,
  inBlacklist,
  reloadPage,
  onHideActions,
  fetchBlackList,
  onShowActions,
  changeStatus,
  manage,
  getCategoryById,
  deleteAttendee,
}: IAttendeeCardProps) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const reloadList = useCallback(() => reloadPage(), [reloadPage])
  const setStatus = (status: RegistrationStatuses) => changeStatus(item.id, status, reloadList)

  const actionButtons = (
    <AttendeeCardActions
      item={item}
      reloadList={reloadList}
      reloadBlackList={fetchBlackList}
      {...actions}
    />
  )

  const handleDelete = () => setShowConfirm(true)

  const handleConfirm = () => {
    deleteAttendee(item.id, reloadList)
    setShowConfirm(false)
  }

  return (
    <>
      <Table.Row>
        <Table.Cell width={2}>
          <Header as="h4">
            {item.firstName} {item.lastName}
          </Header>
        </Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell
          style={{
            background: registrationColors[item.registrationStatus]
          }}
        >
          <Dropdown
            inline
            pointing="top left"
            options={registrationStatusOptions}
            trigger={item.registrationStatus}
            defaultValue={item.registrationStatus}
            disabled={manage}
            onChange={(event, { value }) => setStatus(value as RegistrationStatuses)}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          {item.package
            ? item.package.type
            : item.invitationPackage
            ? item.invitationPackage.type
            : null}
        </Table.Cell>
        <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
          {actionButtons}
        </Table.Cell>
        <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
          {manage ? (
            <Button basic icon="delete" color="red" onClick={handleDelete} />
          ) : (
            <Button
              basic
              as={Link}
              to={`/registrations/${item.id}`}
              icon="pencil alternate"
              style={{ boxShadow: 'none' }}
            />
          )}
        </Table.Cell>
      </Table.Row>

      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content={`Delete registration for ${item.firstName} ${item.lastName}?`}
      />
    </>
  )
}

export const AttendeeCardInjected = observer((props: IAttendeeCardInjectedProps) => {
  const {
    addToBlackList,
    approveAttendee,
    deleteAttendee,
    rejectAttendee,
    requestForPayment,
    requestForPhoto,
    sendAttendeeReminder,
    sendFeaturedAttendeeInvitation,
    unblockAttendee,
    updateIsInTop,
    changeStatus,
  } = useRootData(store => store.registrationsStore)

  const { inBlacklist, fetchBlackList } = useRootData(store => store.blacklistStore)

  const { getCategoryById } = useRootData(store => store.companiesStore)

  return (
    <AttendeeCard
      {...props}
      inBlacklist={inBlacklist}
      fetchBlackList={fetchBlackList}
      changeStatus={changeStatus}
      getCategoryById={getCategoryById}
      deleteAttendee={deleteAttendee}
      actions={{
        addToBlackList,
        approveAttendee,
        changeStatus,
        rejectAttendee,
        requestForPayment,
        requestForPhoto,
        sendAttendeeReminder,
        sendFeaturedAttendeeInvitation,
        unblockAttendee,
        updateIsInTop,
      }}
    />
  )
})

export default memo(AttendeeCardInjected)
