import React, { memo, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Confirm, Dropdown, Header, Icon, Image, Table } from 'semantic-ui-react'
import { ISponsorItem, ISponsorTag, ISponsorTier, ISponsorType } from 'stores/sponsors'
import './SponsorCard.css'

interface ISponsorCardProps {
  bulkSelected: string[]
  disabled?: boolean
  item: ISponsorItem
  manage?: boolean
  onDelete: (id: string) => any
  onDraft: (id: string) => any
  onPublish: (id: string) => any
  onToggle: (action: string, id: string) => any
  page: number
  sponsorTypes: ISponsorType[]
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
}

export const statusOptions = [
  {
    key: 'Draft',
    text: 'Draft',
    value: 'Draft',
  },
  {
    key: 'Deleted',
    text: 'Deleted',
    value: 'Deleted',
  },
  {
    key: 'Published',
    text: 'Published',
    value: 'Published',
  },
]

export const SponsorCard = ({
  bulkSelected,
  page,
  item,
  disabled,
  manage,
  onPublish,
  onDraft,
  onDelete,
  onToggle,
  sponsorTypes,
  sponsorTiers,
  sponsorTags,
}: ISponsorCardProps) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const handleStatus = (value: any) => {
    switch (value) {
      case 'Deleted':
        onDelete(item.id)
        break
      case 'Published':
        onPublish(item.id)
        break
      case 'Draft':
        onDraft(item.id)
        break
      default:
        break
    }
  }

  const isChecked = bulkSelected.includes(item.id)

  const itemTiers = useMemo(
    () =>
      item.sponsorTiersIds
        ? item.sponsorTiersIds
            .map(id => sponsorTiers.find(tier => tier.id === id)?.title)
            .join(', ')
        : '—',
    [item, sponsorTiers]
  )

  const itemTags = useMemo(
    () =>
      item.tags
        ? item.tags.map(id => sponsorTags.find(tag => tag.id === id)?.displayName).join(', ')
        : '—',
    [item, sponsorTags]
  )

  const handleSelect = () => {
    if (isChecked) {
      onToggle('remove', item.id)
    } else {
      onToggle('add', item.id)
    }
  }

  const handleDelete = () => setShowConfirm(true)

  const handleConfirm = () => {
    onDelete(item.id)
    setShowConfirm(false)
  }

  return (
    <>
      <Table.Row>
        <Table.Cell width={1}>
          <Checkbox checked={isChecked} onChange={handleSelect} />
        </Table.Cell>
        <Table.Cell width={2}>
          <Header as="h4">{item.company ? item.company : '—'}</Header>
        </Table.Cell>
        <Table.Cell textAlign="center">
          {item.logoUrl ? (
            <Image
              width={180}
              height={60}
              src={item.logoUrl.replace('upload', 'upload/w_180,h_60,c_fit')}
              verticalAlign="middle"
            />
          ) : (
            <Icon name="file image" />
          )}
        </Table.Cell>
        <Table.Cell
          positive={item.status === 'Published'}
          warning={item.status === 'Draft'}
          error={item.status === 'Deleted'}
        >
          <Dropdown
            inline
            pointing="top left"
            options={statusOptions}
            trigger={item.status}
            defaultValue={item.status}
            disabled={manage}
            onChange={(event, { value }) => handleStatus(value)}
          />
        </Table.Cell>
        <Table.Cell>{itemTiers}</Table.Cell>
        <Table.Cell>{itemTags}</Table.Cell>
        <Table.Cell textAlign="right" width={1} style={{ textOverflow: 'unset' }}>
          {manage ? (
            <Button basic icon="delete" color="red" onClick={handleDelete} />
          ) : (
            <Button
              basic
              as={Link}
              to={`/sponsors/list/${item.id}`}
              icon="pencil alternate"
              style={{ boxShadow: 'none' }}
              data-disabled={bulkSelected.length ? 'true' : 'false'}
            />
          )}
        </Table.Cell>
      </Table.Row>
      <Confirm
        size="mini"
        open={showConfirm}
        onCancel={event => setShowConfirm(false)}
        onConfirm={handleConfirm}
        content={`Delete registration for ${item.company}?`}
      />
    </>
  )
}

export default memo(SponsorCard)
