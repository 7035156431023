import { IFormValues } from 'components/edit-sponsor-layout/EditSponsorLayout'
import { ILayout, layouts } from 'components/scene-preview/ScenePreview'
import { FormikProps } from 'formik'
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import React, { useContext, useEffect, useState } from 'react'
import { Divider, Grid, Icon } from 'semantic-ui-react'
import useWindowDimensions from 'utils/dimensions'

import 'pure-react-carousel/dist/react-carousel.es.css'

interface ILayoutSliderProps extends FormikProps<IFormValues> {
  currentLayout: ILayout
}

export const LayoutsSlider = ({
  setFieldValue,
  setFieldTouched,
  currentLayout,
}: ILayoutSliderProps) => {
  const carouselContext = useContext(CarouselContext)
  const [, setCurrentSlide] = useState(carouselContext.state.currentSlide)
  // console.log('currentLayout', currentLayout)

  const { isMobile } = useWindowDimensions()

  useEffect(() => {
    function onSlideChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onSlideChange)
    return () => carouselContext.unsubscribe(onSlideChange)
  }, [carouselContext])

  return (
    <Grid stackable centered>
      <Grid.Row textAlign="center">
        <Grid.Column stretched className="layout">
          <Divider horizontal>Layout</Divider>
          <Divider hidden />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row textAlign="center">
        <Grid.Column stretched className="layout">
          {isMobile ? (
            <>
              <Slider>
                {layouts.map((layout, index) => (
                  <Slide
                    key={index}
                    index={index}
                    className={`${currentLayout.name === layout.name ? 'active' : ''}`}
                  >
                    <img
                      width={160}
                      height={90}
                      src={layout.preview.replace(
                        '/upload/',
                        '/upload/w_160,h_90,c_thumb,z_0.3/q_auto/'
                      )}
                      alt=""
                      onClick={(event: any) => {
                        setFieldValue('layout', layout)
                        setFieldTouched('layout')
                      }}
                    />
                  </Slide>
                ))}
              </Slider>
              <ButtonBack className="ui circular icon basic button button-prev">
                <Icon name="chevron left" />
              </ButtonBack>
              <ButtonNext className="ui circular icon basic button button-next">
                <Icon name="chevron right" />
              </ButtonNext>
            </>
          ) : (
            <Grid>
              <Grid.Row>
                {layouts.map((layout, index) => (
                  <Grid.Column
                    width={4}
                    key={index}
                    index={index}
                    className={`carousel__slide ${
                      currentLayout.name === layout.name ? 'active' : ''
                    }`}
                    textAlign="center"
                    align="middle"
                  >
                    <img
                      width={160}
                      height={90}
                      src={layout.preview.replace(
                        '/upload/',
                        '/upload/w_160,h_90,c_thumb,z_0.3/q_auto/'
                      )}
                      alt=""
                      onClick={(event: any) => {
                        setFieldValue('layout', layout)
                        setFieldTouched('layout')
                      }}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export const LayoutsPreview = (props: ILayoutSliderProps) => {
  const { isMobile } = useWindowDimensions()
  const currentSlide = layouts.findIndex(layout => props.currentLayout.name === layout.name)
  return (
    <CarouselProvider
      naturalSlideWidth={isMobile ? 140 : 220}
      naturalSlideHeight={130}
      isIntrinsicHeight={true}
      visibleSlides={2}
      currentSlide={currentSlide}
      totalSlides={layouts.length}
    >
      <LayoutsSlider {...props} />
    </CarouselProvider>
  )
}

export default LayoutsPreview
