import React from 'react'
import { regularRoutes as routes } from 'router'
import { Menu } from 'semantic-ui-react'
import MenuLink from './SideMenuLink'

const SideMenuRegular = () => {
  return (
    <>
      <MenuLink name={routes.home.name} url={routes.home.url} route={routes.home.path} />
      <Menu.Item>
        <div>Sponsors</div>
        <Menu.Menu>
          <MenuLink
            name={routes.sponsorsList.name}
            url={routes.sponsorsList.url}
            route={routes.sponsorsList.path}
          />
          <MenuLink
            name={routes.sponsorsSettings.name}
            url={routes.sponsorsSettings.url}
            route={routes.sponsorsSettings.path}
          />
        </Menu.Menu>
      </Menu.Item>
      <MenuLink
        name={routes.notifications.name}
        url={routes.notifications.url}
        route={routes.notifications.path}
      />
      <MenuLink
        name={routes.registrations.name}
        url={routes.registrations.url}
        route={routes.registrations.path}
      />
      <MenuLink
        name={routes.blacklists.name}
        url={routes.blacklists.url}
        route={routes.blacklists.path}
      />
      <MenuLink
        name={routes.blockedips.name}
        url={routes.blockedips.url}
        route={routes.blockedips.path}
      />
      <MenuLink
        name={routes.forbiddenemails.name}
        url={routes.forbiddenemails.url}
        route={routes.forbiddenemails.path}
      />
    </>
  )
}

export default SideMenuRegular
