import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'
import List from './List'
import Registration from './Registration'
import './Registrations.css'

export const RegistrationsIndex = () => {
  const { slug } = useParams()

  if (slug !== undefined && typeof slug === 'string') {
    return <Registration />
  }
  return <List />
}

export default observer(() => <RegistrationsIndex />)
