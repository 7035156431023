import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRootData } from 'stores'

export default observer(() => {
  const { completeLoginSaml, accessToken } = useRootData(store => store.appStore)
  const location = useLocation()
  const history = useHistory()
  const token = useMemo(() => (queryString.parse(location.search).token || '') as string, [
    location,
  ])

  useEffect(() => {
    if (accessToken) {
      history.replace('/')
    }
    // eslint-disable-next-line
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) {
      completeLoginSaml(token)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="saml-wrapper">
      <div className="saml-message">
        Success login
      </div>
    </div>
  )
})
