import { Field, FieldProps } from 'formik'
import React, { memo } from 'react'
import { Form, StrictFormRadioProps } from 'semantic-ui-react'

export interface IRadioGroupPublicProps extends StrictFormRadioProps {
  title: string
  name: string
  inline?: boolean
  options: Array<{
    title?: string
    value: string
  }>
  [key: string]: any
}

const RadioButton = ({ field, form, ...props }: FieldProps & StrictFormRadioProps) => (
  <Form.Radio
    {...field}
    {...props}
    onChange={() => {
      form.setFieldValue(field.name, props.value)
      form.setFieldTouched(field.name)
    }}
    checked={props.value === field.value}
  />
)

export const RadioGroup = ({ title, name, options, ...props }: IRadioGroupPublicProps) => (
  <Form.Group {...props} grouped={!props.inline}>
    <label>{title}</label>
    {options.map(({ title: optionTitle, value }, i) => (
      <Field
        key={i}
        component={RadioButton}
        name={name}
        value={value}
        label={optionTitle || value}
      />
    ))}
  </Form.Group>
)

export default memo(RadioGroup)
