import { observer } from 'mobx-react-lite'
import React from 'react'
import { Icon, Menu } from 'semantic-ui-react'
import { useRootData } from 'stores'
import { SITE } from 'utils/config'
import useWindowDimensions from 'utils/dimensions'

import './TopMenu.css'

interface ITopMenuProps {
  onToggle: () => void
}

export const TopMenu = ({ onToggle }: ITopMenuProps) => {
  const { isMobile } = useWindowDimensions()

  if (!isMobile) {
    return null
  }

  return (
    <Menu className="top-menu fixed">
      <Menu.Item onClick={onToggle} name="menu">
        <Icon name="bars" fitted />
      </Menu.Item>
      <Menu.Item fitted className="top-menu__title">
        {SITE?.split('/')[2]}
      </Menu.Item>
    </Menu>
  )
}

export default observer(() => {
  const { visibleSideMenu, hideSideMenu, showSideMenu } = useRootData(store => store.appStore)
  const onToggle = visibleSideMenu ? hideSideMenu : showSideMenu
  return <TopMenu onToggle={onToggle} />
})
