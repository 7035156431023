import {
  IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse,
  IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponseSponsor,
  IApiControllersBackofficeSponsorsModelsUpdateSponsorVipAdminModel,
} from 'api-types'
import { AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import {
  createAxiosAction,
  createAxiosActionWithCallback,
  createLoadingStatusType,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type IExhibitorItem = IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponseSponsor
export type IUpdateExhibitorItem = IApiControllersBackofficeSponsorsModelsUpdateSponsorVipAdminModel

export default types
  .model('ExhibitorStore', {
    actionExhibitorStatus: LoadingStatusType,
    actionStatus: createLoadingStatusType(LoadingStatus.success),
    currentExhibitor: types.frozen(),
    fetchExhibitorStatus: LoadingStatusType,
  })

  .actions(self => ({
    fetchExhibitor: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get('/vip-admin/media-partner')) as AxiosResponse<
          IAbstractionsContractsQueriesSponsorsSponsorsFilteredResponse
        >
        self.currentExhibitor = data.sponsors[0]
      }),
      s => (self.fetchExhibitorStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch exhibitor data')
    ),
  }))

  .actions(self => {
    const createAction = <T extends any[]>(
      fn: (...args: T) => Promise<any>,
      error: string
    ): ((...args: T) => Promise<any>) => {
      const onStatusChange = (s: LoadingStatus) => (self.actionStatus = s)
      const onError = () => getParent<IRootStore>(self).showError(error)
      return createAxiosActionWithCallback(fn, onStatusChange, onError)
    }

    return {
      saveExhibitor: createAction((payload: IUpdateExhibitorItem) => {
        return axios.put('/vip-admin/media-partner', payload)
      }, 'Failed to save exhibitor'),
    }
  })
