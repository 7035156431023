import { ISelectOption, SelectInput, TextInput } from 'components/inputs'
import SelectImageInput from 'components/select-image-input/SelectImageInput'
import { ErrorMessage, Form as FormikForm, Formik, FormikActions, FormikProps, getIn } from 'formik'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, Grid } from 'semantic-ui-react'
import { ISponsorItem, ISponsorTag, ISponsorTier, ISponsorType } from 'stores/sponsors'
import { countriesOptions } from 'utils/countries'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from 'utils/store'
import * as Yup from 'yup'

interface ICreateFormProps {
  actionStatus: LoadingStatus
  item: ISponsorItem
  sponsorTypes: ISponsorType[]
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
  createSponsor: (payload: any) => any
}

interface IError {
  [key: string]: string
}

export const EditSponsorNew = ({
  actionStatus,
  item,
  sponsorTypes,
  sponsorTiers,
  sponsorTags,
  createSponsor,
}: ICreateFormProps) => {
  const history = useHistory()

  const initialValues = useMemo(
    () => ({
      ..._.cloneDeep(item),
    }),
    [item]
  )

  const { isMobile } = useWindowDimensions()

  const sponsorTypesOptions = useMemo(
    () =>
      sponsorTypes.map(type => ({
        key: type.id,
        text: type.title,
        value: type.id,
      })),
    [sponsorTypes]
  )

  const sponsorTiersOptions = useMemo(
    () =>
      sponsorTiers.map(tier => ({
        key: tier.id,
        text: tier.title,
        value: tier.id,
      })),
    [sponsorTiers]
  )

  const sponsorTagsOptions = useMemo(
    () =>
      sponsorTags.map(tag => ({
        key: tag.id,
        text: tag.displayName,
        value: tag.id,
      })),
    [sponsorTags]
  )

  useEffect(() => {
    if (item.id) {
      history.push(`/sponsors/list/${item.id}`)
    }
  }, [history, item])

  const filteredOptions = (options: ISelectOption[], selectedOptions: any) => {
    if (selectedOptions && selectedOptions.length) {
      return options.filter((option: ISelectOption) => selectedOptions.includes(option.value))
    }
    return options
  }

  const handleSubmit = useCallback(
    async (
      values: ISponsorItem,
      { resetForm }: Partial<FormikActions<ISponsorItem>>,
      { isValid }: Partial<FormikProps<ISponsorItem>>
    ) => {
      if (isValid) {
        const { tags, ...rest } = values
        const payload = {
          tagsIds: tags,
          ...rest,
        }
        createSponsor(payload)
      }
    },
    [createSponsor]
  )

  const requiredMessage = 'Please fill this field'

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        createSponsor(values)
      }}
      validationSchema={Yup.object().shape({
        contactPerson: Yup.object().shape({
          email: Yup.string()
            .email()
            .required(requiredMessage),
          name: Yup.string().required(requiredMessage),
          phone: Yup.string()
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/, 'Phone number is not valid')
            .required(requiredMessage),
        }),
        company: Yup.string().required(requiredMessage),
        location: Yup.string().required(requiredMessage),
        logoUrl: Yup.string().required(requiredMessage),
        primarySponsorTag: Yup.string().required(requiredMessage),
        primarySponsorTier: Yup.string().required(requiredMessage),
        sponsorTiersIds: Yup.string().required(requiredMessage),
        sponsorTypesIds: Yup.string().required(requiredMessage),
        tags: Yup.string().required(requiredMessage),
      })}
      render={props => {
        const {
          errors,
          values,
          touched,
          setFieldTouched,
          setFieldValue,
          setSubmitting,
          isSubmitting,
          resetForm,
        } = props
        const isValid = !Object.keys(errors).length && Object.keys(touched).length > 0
        return (
          <Form as={FormikForm} autoComplete="off">
            <Grid stretched stackable>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <SelectImageInput
                    title="Logo"
                    fluid
                    height={200}
                    name="logoUrl"
                    onChange={event => {
                      setFieldValue('logoUrl', event)
                      setFieldTouched('logoUrl')
                    }}
                    error={{
                      content: errors.logoUrl as string,
                      pointing: 'above',
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8} verticalAlign="middle">
                  <TextInput
                    title="Name"
                    name="company"
                    disabled={isSubmitting}
                    error={
                      errors.company && {
                        content: errors.company as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    search
                    name="location"
                    title="Country"
                    options={countriesOptions}
                    disabled={isSubmitting}
                    error={
                      errors.location && {
                        content: errors.location as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    multiple
                    name="sponsorTypesIds"
                    value={values.sponsorTypesIds || []}
                    selection
                    title="Sponsorship"
                    options={sponsorTypesOptions}
                    disabled={isSubmitting}
                    error={
                      errors.sponsorTypesIds && {
                        content: errors.sponsorTypesIds as any,
                        pointing: 'above',
                      }
                    }
                  />
                  <ErrorMessage name="sponsorTypesIds" component="div" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    multiple
                    name="sponsorTiersIds"
                    value={values.sponsorTiersIds || []}
                    selection
                    title="Tiers"
                    options={sponsorTiersOptions}
                    disabled={isSubmitting}
                    error={
                      errors.sponsorTiersIds && {
                        content: errors.sponsorTiersIds as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    name="primarySponsorTier"
                    selection
                    title="Primary tier"
                    options={filteredOptions(sponsorTiersOptions, values.sponsorTiersIds)}
                    disabled={isSubmitting}
                    error={
                      errors.primarySponsorTier && {
                        content: errors.primarySponsorTier as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    multiple
                    name="tags"
                    value={values.tags || []}
                    selection
                    title="Tags"
                    options={sponsorTagsOptions}
                    disabled={isSubmitting}
                    error={
                      errors.tags && {
                        content: errors.tags as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="top">
                  <SelectInput
                    name="primarySponsorTag"
                    selection
                    title="Primary tag"
                    options={filteredOptions(sponsorTagsOptions, values.tags)}
                    disabled={isSubmitting}
                    error={
                      errors.primarySponsorTag && {
                        content: errors.primarySponsorTag as any,
                        pointing: 'above',
                      }
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column mobile={4} tablet={4} computer={4} verticalAlign="middle">
                  <SelectImageInput
                    width={200}
                    height={200}
                    title="Picture"
                    name="contactPerson.personPicture"
                    onChange={event => {
                      setFieldValue('contactPerson.personPicture', event)
                      setFieldTouched('contactPerson.personPicture')
                    }}
                  />
                </Grid.Column>
                <Grid.Column mobile={12} tablet={12} computer={12} verticalAlign="middle">
                  <Grid stretched stackable>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Name"
                          name="contactPerson.name"
                          error={
                            getIn(errors, 'contactPerson.name') && {
                              content: getIn(errors, 'contactPerson.name'),
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Email"
                          name="contactPerson.email"
                          error={
                            getIn(errors, 'contactPerson.email') && {
                              content: getIn(errors, 'contactPerson.email'),
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput
                          title="Phone"
                          name="contactPerson.phone"
                          error={
                            getIn(errors, 'contactPerson.phone') && {
                              content: getIn(errors, 'contactPerson.phone'),
                              pointing: 'above',
                            }
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8} verticalAlign="middle">
                        <TextInput title="Position" name="contactPerson.position" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    type="submit"
                    fluid={isMobile}
                    width={3}
                    primary
                    onClick={() => handleSubmit(values, { resetForm, setSubmitting }, { isValid })}
                    loading={actionStatus === LoadingStatus.pending}
                    content="Save"
                    size="large"
                    disabled={!isValid}
                  />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    secondary
                    basic
                    fluid={isMobile}
                    type="button"
                    onClick={() => resetForm()}
                    content="Reset"
                    size="large"
                    disabled={!Object.keys(touched).length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )
      }}
    />
  )
}

export default EditSponsorNew
